export function getFuelLevel(sensors, techSensors) {
  const fuelLevelSensor1 = findSensor("ДУТ1", techSensors);
  const fuelLevelSensor2 = findSensor("ДУТ2", techSensors);

  let fuelLevelValue = null;

  if (fuelLevelSensor1) {
    fuelLevelValue = getFuelLevelValue(fuelLevelSensor1, sensors);
  }
  if (fuelLevelSensor2) {
    fuelLevelValue += getFuelLevelValue(fuelLevelSensor2, sensors);
  }

  if (fuelLevelValue === null) {
    return false;
  }

  return fuelLevelValue.toFixed(2);
}

export function findSensor(sensorName, techSensors) {
  techSensors = Object.values(techSensors);
  for (let techSensor of techSensors) {
    if (techSensor.n === sensorName) {
      return techSensor;
    }
  }

  return false;
}

function getFuelLevelValue(sensor, sensors) {
  try {
    const sensorKey = findSensorKey(sensor.p, sensors);

    //template: sensorValues=30:0:79:20:184:25:346:30:495:35:653:40:804:45:934:50; sensor:fuelLevel:sensor:fuelLevel:sensor:fuelLevel...
    let sensorValues = sensor.d.split("|")[1];

    sensorValues = sensorValues.split(":").reduce((res, val, index) => {
      if (index % 2 === 0) {
        res.push([+val]);
      } else {
        res[res.length - 1][1] = +val;
      }
      return res;
    }, []);
    const sensorValue = sensors[sensorKey];
    return interpolate(sensorValue, sensorValues);
  } catch (e) {
    return false;
  }
}

function findSensorKey(sensorName, sensors) {
  if (sensorName.includes("|")) {
    const keys = sensorName.split("|");
    for (let i of keys) {
      if (i in sensors) {
        return i;
      }
    }
  }

  return sensorName;
}

function interpolate(sensorValue, sensorValues) {
  let sensorValueList = sensorValues.map((point) => point[0]);

  if (sensorValue < sensorValueList[0]) {
    return sensorValues[0][1];
  }

  if (sensorValue > sensorValueList[sensorValueList.length - 1]) {
    return sensorValues[sensorValues.length - 1][1];
  }

  for (let i = 0; i < sensorValues.length - 1; i++) {
    if (
      sensorValueList[i] <= sensorValue &&
      sensorValue <= sensorValueList[i + 1]
    ) {
      let [x0, y0] = sensorValues[i];
      let [x1, y1] = sensorValues[i + 1];
      return y0 + ((y1 - y0) * (sensorValue - x0)) / (x1 - x0);
    }
  }
}

export function getVoltage(techSens, sensors) {
  try {
    const sensor = findSensor("Напряжение", techSens);
    if (!sensor) {
      return false;
    }
    const sensorValue = sensors[sensor.p];
    const voltageValues = JSON.parse(sensor.c).ci;
    let min = -Infinity;
    for (let val in voltageValues) {
      if (+val < sensorValue && +val >= min) {
        min = +val;
      }
    }

    const resultText = voltageValues[min].t;

    return (
      <div>
        Напряжение: &nbsp;
        <span
          style={{ color: resultText.includes("Нормальное") ? "green" : "red" }}
        >
          {resultText} ({sensorValue} В)
        </span>
      </div>
    );
  } catch (e) {
    return false;
  }
}

export function getIgnitionStatus(sensors, techSensors) {
  try {
    const ignition = findSensor("Зажигание", techSensors);
    let min = { x: -Infinity };
    for (let val of ignition.tbl) {
      if (val.x < sensors[ignition.p] && val.x >= min.x) {
        min = val;
      }
    }

    return min.b === 1;
  } catch {
    return false;
  }
}

export function getIgnition(sensors, techSensors) {
  const status = getIgnitionStatus(sensors, techSensors);
  return (
    <div>
      Зажигание: &nbsp;
      {status ? (
        <span style={{ color: "green" }}>Включено</span>
      ) : (
        <span style={{ color: "rgb(177, 177, 33)" }}>Выключено</span>
      )}
    </div>
  );
}

export function getTerminalPower(sensors, techSensors) {
  try {
    const item = findSensor("Питание терминала", techSensors);
    let min = { x: -Infinity };
    for (let val of item.tbl) {
      if (val.x < sensors[item.p] && val.x >= min.x) {
        min = val;
      }
    }
    return (
      <div>
        Питание терминала: &nbsp;
        {min.b === 1 ? (
          <span style={{ color: "green" }}>Подключено</span>
        ) : (
          <span style={{ color: "red" }}>Отключено</span>
        )}
      </div>
    );
  } catch (e) {
    return false;
  }
}
