import React, {useEffect} from 'react';
import { useStore } from "effector-react";
import { useNavigate } from "react-router-dom";
import {Button, Divider, notification, Table} from "antd";
import TableDropdown from 'components/TableDropdown';
import { usePermission } from 'hooks/usePermission';

import ChangeModal from './changeUserGroupsModal';

import {
  $usersGroupsStore,
  $isEditModalOpen,

  showChangeModal,

  deleteUserGroupFx,
  getUsersGroupsFx,
} from "../../store/userGroups";
import { $userStore } from 'store/user';

import styles from "../Roles/components/PageHeader/page-header.module.scss";
import {routes} from "../../routes";

const columns = ({
    showChangeModal,
    onDeleteClick,
    onEditRoleClick,
    ...permissions
}) => {
  return [
    {
      title: 'Название',
      dataIndex: 'title',
      rules: { required: 'Не указано название группы' }
    },
    {
      title: 'Hello Page',
      dataIndex: 'hello_page',
    },
    {
      title: '',
      render: (row) => (
        <TableDropdown
          items={[
            { label: "Редактировать", onClick: showChangeModal, permission: "canEdit" },
            { label: "Роли группы", onClick: onEditRoleClick },
            { label: "Удалить", onClick: onDeleteClick, permission: "canDelete" },
          ].filter(item => item.permission ? permissions[item.permission] : true)}
          row={row}
        />
      )
    }
  ];
}

const UsersGroups = () => {
  const usersGroups = useStore($usersGroupsStore)
  const isEditModalOpen = useStore($isEditModalOpen);
  const {viewAsUserMode} = useStore($userStore);
  const navigate = useNavigate();

  const canCreate = usePermission("usersGroups", "create");
  const canEdit   = usePermission("usersGroups", "edit");
  const canDelete = usePermission("usersGroups", "delete");

  const [notificationsApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    getUsersGroupsFx();
  }, [viewAsUserMode])

  const onEditRoleClick =
      (row) => navigate(routes.groupRoles.replace(":id", row.id));

  const onDeleteUserGroup = async (row) => {
    const { data = {} } = await deleteUserGroupFx(row);

    if(data.error_msg) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    }else {
      notificationsApi.success({ message: 'Группа успешно удалена', duration: 5 });
    }
  }

  return (
    <section>
      <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>Группы пользователей</h2>
        { canCreate &&
            <Button onClick={showChangeModal}>
              Создать группу
            </Button>
        }
      </div>
      <Divider/>
      <Table
        dataSource={usersGroups}
        columns={columns({
          showChangeModal,
          onEditRoleClick,
          onDeleteClick: onDeleteUserGroup,
          canEdit,
          canDelete
        })}
        pagination={{
          defaultPageSize: 20
        }}
        scroll={{ x: true }}
      />
      {isEditModalOpen && <ChangeModal notificationsApi={notificationsApi} />}
      {contextHolder}
    </section>
  )
};

export default UsersGroups;