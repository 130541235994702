import DataFrame from "dataframe-js/"
import getRowData from "./getRowData"
import getGroupData from "./getGroupData";
import calcTypesData from "./calcTypesData";

function createTableData(list, groupBy, sumBy, divisionBy, calcType, tableType) {
    const df = new DataFrame(list);
    let table = df.withColumn(groupBy, row => {
        return getGroupData[groupBy](row);
    })
        .withColumn('sum', row => {
            if (sumBy in getRowData) {
                return getRowData[sumBy](row);
            }
            return row.get(sumBy);
        })
        .withColumn('division', row => {
            if (divisionBy in getRowData) {
                return getRowData[divisionBy](row);
            }
            return row.get(divisionBy);
        })
        .select(groupBy, 'division', 'sum');

    let groupedTable = groupingTable(groupBy, table);

    if (calcType !== 'none') {
        groupedTable = groupedTable.withColumn('result', row => {
            return +(calcTypesData[calcType](row)).toFixed(2);
        })
    }

    return changeTableType(groupedTable, tableType, calcType);
}

function groupingTable(groupBy, table) {
    let aggrigates = [];
    table = table.groupBy(groupBy);
    table = table.aggregate(group => {
        const res = {
            sum: group.stat.sum('sum'),
            division: group.stat.mean('division'),
        }

        return res;
    });
    table.withColumn('', row => {
        aggrigates = Array.from(Object.keys(row.get('aggregation')))
    })
    aggrigates.forEach(columnName => {
        table = table.withColumn(columnName, row => {
            return row.get('aggregation')[columnName];
        })
    })
    table = table.drop('aggregation');

    return table;
}

function changeTableType(table, tableType, calcType) {
    if (tableType === 'table') {
        return table.toArray().map(column => {
            const columnNames = table.listColumns();
            return Object.fromEntries(columnNames.map((name, index) => [name, column[index]]));
        });
    }

    if (tableType === 'column-chart') {
        return table.toArray().map(column => {
            return calcType === 'none' ? column.slice(0, column.length - 1) : column
        });
    }

    if (tableType === 'percent-chart') {
        const newTable = table.toArray().map(column => {
            return [column[0], column.at(-1), 80, 100];
        })
        return newTable;
    }
}

export default createTableData;