import { Select } from "antd";
import { Controller } from "react-hook-form";

import st from "../../pages/Fields/index.module.scss";

const FormSelect = ({
  options,
  form,
  placeholder = "",
  name
}) => (
  <Controller
    name={name}
    control={form.control}
    render={({ field, ...p }) => (
      <div className={st.modalItem}>
        <div>{placeholder}</div>
        <Select
          options={options}
          className={st.modalItem}
          placeholder={placeholder}
          defaultValue={field.value ? options.find(item => item.value == field.value)?.label : undefined}
          onChange={(value) => field.onChange(value)}
        />
      </div>
    )}
  />
)

export default FormSelect;