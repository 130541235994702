import { Button, Dropdown, Table } from "antd";
import dayjs from "dayjs";
import { usePermission } from "hooks/usePermission";
import { useMemo } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

const tableColumns = [
    {
        title: "Склад",
        dataIndex: "storage",
        key: "storage"
    },
    {
        title: "Поле",
        dataIndex: "plantation",
        key: "plantation"
    },
    {
        title: "Дата работы",
        dataIndex: "date_work",
        key: "date_work"
    },
    {
        title: "Дата взвешивания",
        dataIndex: "date_weighing",
        key: "date_weighing"
    },
    {
        title: "Водитель",
        dataIndex: "worker",
        key: "worker"
    },
    {
        title: "Вес полный",
        dataIndex: "weigh_brutto",
        key: "weigh_brutto"
    },
    {
        title: "Вес пустой",
        dataIndex: "weigh_technic",
        key: "weigh_technic"
    },
    {
        title: "Вес урожая",
        dataIndex: "weigh",
        key: "weigh"
    },
    {
        width: "10%",
        render: (item) =>
            (item.onEdit || item.onDelete) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Редактировать", key: "editItem"},
                        {label: "Удалить", key: "deleteItem"},
                    ].filter(menuItem => menuItem.key === "editItem" ? item.onEdit : item.onDelete),

                    onClick: (dropDownItem) => {
                        switch (dropDownItem.key) {
                            case "deleteItem":
                                item.onDelete();
                                break;
                            case "editItem":
                                item.onEdit();
                                break;
                            default:
                                break;
                        }
                    }
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
]

const WeighingTable = ({items = [], onEdit, onDelete}) => {
    const canEdit = usePermission("weighing", "edit");
    const canDelete = usePermission("weighing", "delete");

    const tableItems = useMemo(() =>
        items.map(item => ({
            ...item,
            worker: `${item.worker?.name} ${item.worker?.patronymic} ${item.worker?.surname}`,
            plantation: item.plantation?.name,
            storage: item.storage?.title,
            date_work: item.date_work ? dayjs(item.date_work).format('DD.MM.YYYY') : null,
            date_weighing: item.date_weighing ? dayjs(item.date_weighing).format('DD.MM.YYYY hh:mm:ss') : null,
            onEdit: canEdit && (() => onEdit(item)),
            onDelete: canDelete && (() => onDelete(item))
        })), [items, canEdit, canDelete, onDelete, onEdit]);

    return (
        <Table
            columns={tableColumns}
            dataSource={tableItems || []}
        />
    );
}

export default WeighingTable;