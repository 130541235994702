import { Button } from "antd";
import { usePermission } from "hooks/usePermission";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";

export const PageHeader = ({ onClick }) => {
  const canCreate = usePermission("technique", "create");
  const navigate = useNavigate();

  const showAllFields = (field) => {
    navigate("/technique/all/map", { state: { id: 'all' } });
  }

  return (
    <div className={styles.pageHeader}>
      <h2 className={styles.pageTitle}>Техника</h2>
      <div>
        {canCreate && (
          <>
            <Button style={{ marginRight: 16 }}>
              <a href="https://local.tn-group.net/" target="_blank">
                Мониторинг
              </a>
            </Button>
            <Button
              onClick={showAllFields}
              style={{ marginRight: 16 }}
            >
              Карта
            </Button>
          </>
        )}
        {canCreate &&
          <Button
            onClick={onClick}
          >
            Добавить технику
          </Button>
        }
      </div>
    </div>
  )
}

