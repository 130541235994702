import { useCallback, useEffect } from "react";
import {Form, Input, Button, DatePicker, notification, Select} from "antd";
import { validateStaffDataFx, searchStaffMemberFx } from "../../store/staff";
import dayjs from "dayjs";
import { $companiesStore, getCompanies } from "store/companies";
import { useStore } from "effector-react";


const UpdateStaffForm = ({
    onSubmit,
    submitTitle,
    onCancel,
    company, id,
    title, description, size, size_max,
}) => {
    const [form] = Form.useForm();
    const companies = useStore($companiesStore);

    useEffect(() => {
        getCompanies();
    }, []);

    const onFinish = useCallback((data) => {
        if (id) {
            data.storage = id;
        }
        onSubmit(data);
        onCancel();
    }, [])

    return (
        <Form
            autoComplete={false}
            requiredMark={false}
            layout="vertical"
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                label="Название"
                name="title"
                initialValue={title}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Описание"
                name="description"
                initialValue={description}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Предприятие"
                name="company"
                required={false}
                rules={[{required: true, message: 'Выберете предприятие'}]}
                initialValue={company?.id}
            >
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        option?.label
                            .toLowerCase()
                            .includes(
                                input.toLowerCase()
                            )
                    }
                    options={companies?.list?.map(item => ({value: item.id, label: item.name})) || []}
                />
            </Form.Item>
            <Form.Item
                label="Размер"
                name="size"
                initialValue={size}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Максимальный размер"
                name="size_max"
                initialValue={size_max}
            >
                <Input />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default UpdateStaffForm;

