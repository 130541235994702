import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useStore } from "effector-react";
import {
  Button,
  Input,
  Divider,
  Modal,
  notification
} from 'antd';
import st from "../index.module.scss";
import { changeUserEmailFx, $userMail } from "../../../store/user";

const profileMailItems = [
  {
    title: 'Новая почта',
    value: 'mail',
    type: 'email',
    rules: { required: "Не введена почта" }
  },
  {
    title: 'Новая почта повтор',
    value: 'mail1',
    type: 'email',
    rules: { required: "Не введено подтверждение почты" }
  },
  {
    title: 'Новый пароль',
    value: 'psw',
    type: 'password',
    rules: { required: "Не введен пароль" }
  },
  {
    title: 'Новый пароль повтор',
    value: 'psw1',
    type: 'password',
    rules: { required: "Не введено подтверждение пароля" }
  },
]

const ProfileMailModal = ({
  setMailModal,
  setCodeModal,
  setNewMail,
  notificationsApi
}) => {
  const userMail = useStore($userMail);
  const { control, handleSubmit, formState: { errors }, formState, setError, reset } = useForm({
    defaultValues: {
      mail: '',
      mail1: '',
      psw: '',
      psw1: ''
    }
  });

  useEffect(() => {
    reset();
  }, [])

  const onSubmit = async (props, ev) => {
    try {
      const { psw, psw1, mail, mail1 } = props;
      let isError = false

      if(psw !== psw1){
        ['psw', 'psw1'].forEach(name => {
          setError(name, { type: 'custom', message: 'Пароли не совпадают' });
        })
        isError = true
      }

      if(mail !== mail1){
        ['mail', 'mail1'].forEach(name => {
          setError(name, { type: 'custom', message: 'Почта не совпадает' });
        })
        isError = true
      }

      if(mail === userMail){
        setError('mail', { type: 'custom', message: 'Такая почта уже существует' });
        isError = true
      }

      if(psw.length < 8){
        setError('psw', { type: 'custom', message: 'Минимальная длина пароля 8 символов' });
        isError = true
      }

      if(!isError){
        const { data } = await changeUserEmailFx(props);
        const serverError = (data.error_message || data.error_msg)

        if(serverError){
          notificationsApi.error({ message: serverError, duration: 5 });
        }else{
          setNewMail(mail);
          setCodeModal(true);
          setMailModal(false);
          // notificationsApi.success({ message: 'На почту отправлено письмо для активации', duration: 5 });
        }
      }
    } catch(e) {
      notificationsApi.error({message: "Не удалось изменить данные"});
    }
  }

  return (
    <form
      className={st.modalForm}
      onSubmit={handleSubmit(onSubmit)}
    >
      {profileMailItems.map(({ title, value, rules, type } )=> (
        <div
          className={st.modalItem}
          key={value}
        >
          <div className={st.userItemTitle}>{title}</div>
          <Controller
            name={value}
            control={control}
            rules={rules}
            render={({ field }) =>
              <div>
                <div className={st.inputError}>
                  {errors[value] && errors[value].message}
                </div>
                <Input
                  {...field}
                  placeholder={title}
                  className={st.regInputMail}
                  status={errors[value] ? 'error' : ''}
                  type={type}
                />
              </div>
            }
          />
        </div>
      ))}
      <div className={st.modalFooter}>
        <Button
          onClick={() => setMailModal(false)}
        >
          Отменить
        </Button>
        <Button
          type="primary"
          htmlType="submit"
        >
          Сохранить данные
        </Button>
      </div>
    </form>
  )
}

export default ProfileMailModal;