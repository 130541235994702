import { useState, useCallback, useEffect } from "react";
import { useStore } from "effector-react";
import { Divider, notification } from "antd";
import { $techniqueStore, getTechniqueFx, editTechniqueFx, deleteTechniqueFx } from "store/technique";
import { $userStore } from "store/user";
import TechTable from "./TechTable";
import { PageHeader } from "./PageHeader";
import CreateTechModal from "./CreateTechModal";
import EditTechModal from "./EditTechModal";

const Technique = () => {
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const techniqueList = useStore($techniqueStore);
    const {viewAsUserMode} = useStore($userStore);

    // Fetch data
    useEffect(() => {
        getTechniqueFx();
    }, [viewAsUserMode])

    // Callbacks
    const headerClickHandler = useCallback(() => setCreateModal(true),  []);
    const hideCreateModal    = useCallback(() => setCreateModal(false), []);
    const hideEditModal      = useCallback(() => setEditModal(false),   []);
    const showEditModal = useCallback((item) => {
        setEditModal(true);
        setActiveItem(item);
    }, [])

    const editTechniqueHandler = useCallback((formData) => {
        editTechniqueFx(formData)
            .then(res => {
                res.data.status === "ok"
                    ? notification.success({message: "Техника успешно изменена"})
                    : notification.error({message: "Не удалось изменить технику"});
                hideEditModal();
            }).catch(() => notification.error({message: "Не удалось изменить технику"}));
    }, []);

    const createTechniqueHandler = useCallback((formData) => {
        editTechniqueFx(formData)
            .then(res => {
                res.data.status === "ok"
                    ? notification.success({message: "Техника успешно добавлена"})
                    : notification.error({message: "Не удалось добавить технику"});
                hideCreateModal();
            }).catch(() => notification.error({message: "Не удалось добавить технику"}));
    }, []);

    const deleteTechniqueHandler = useCallback((id) => {
        deleteTechniqueFx(id)
            .then(res =>
                res.data.status === "ok"
                    ? notification.success({message: "Техника успешно удалена"})
                    : notification.error({message: "Не удалось удалить технику"})
            ).catch(() => notification.error({message: "Не удалось удалить технику"}));
    }, []);

    return (
        <>
        <PageHeader onClick={headerClickHandler}/>
        <Divider />
        <TechTable
            items={techniqueList}
            onEdit={showEditModal}
            onDelete={deleteTechniqueHandler}
        />

        <CreateTechModal
            onSubmit={createTechniqueHandler}
            open={createModal}
            onCancel={hideCreateModal}
        />

        <EditTechModal
            onSubmit={editTechniqueHandler}
            open={editModal}
            onCancel={hideEditModal}
            activeItem={activeItem}
        />
        </>
    );
}

export default Technique;