import { Modal } from "antd";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import EditTechniqueForm from "./EditTechniqueForm";
import helpMessageRoutes from "helpMessageRoutes";

const CreateTechModal = ({...props}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Добавить технику" helpFile={helpMessageRoutes.technique.create}/>}
            footer={null}
            destroyOnClose
            {...props}
        >
            <EditTechniqueForm
                onSubmit={props.onSubmit}
                submitTitle={"Добавить"}
                onCancel={props.onCancel}
            />
        </Modal>
    );
}

export default CreateTechModal;