import { memo, useCallback, useEffect, useState } from 'react';
import styles from '../index.module.scss';
import { Table as AntdTable } from 'antd';
import { useStore } from 'effector-react';
import { $techniqueMonitoringStore } from 'store/techniqueMonitoring';
import { getTable } from 'api/wialonApi';

function getObjectRowsFromArray(rowData, columns, setMarkerPosition) {
    return rowData.map((el) => {
        let newRow = {
            key: el.c[0]
        };
        for (let i = 0; i < el.c.length; i++) {
            const cell = el.c[i];
            if (typeof cell === 'object') {
                newRow[columns[i].dataIndex] = (
                    <div 
                        style={{color: "#066397", cursor: "pointer"}} 
                        onClick={() => {
                            if ('x' in cell && 'y' in cell) {
                                setMarkerPosition([cell.y, cell.x])
                            }
                        }}
                    >
                        {cell.t}
                    </div>
                );
            } else {
                newRow[columns[i].dataIndex] = cell;
            }
        }
        if ("r" in el) {
            newRow.children = getObjectRowsFromArray(el.r, columns, setMarkerPosition);
        }
        return newRow;
    });
}

export const Table = memo(({ isShowLeftbar, setMarkerPosition }) => {
    const techMonitoring = useStore($techniqueMonitoringStore);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const updateColumnsAndRows = useCallback(async () => {
        const {table, index: tableIndex} = techMonitoring.selectedTable;
        const newColumns = [];

        for (let i = 0; i < table.header.length; i++) {
            const newColumn = {
                title: <div className={styles.bottomTableHeader}>{table.header[i]}</div>,
                dataIndex: table.header_type[i] || i,
                width: i === 0 ? 90 : (table.header[i].length * 10) + 5,
                render: (text) => <div style={{whiteSpace: "nowrap"}}>{text}</div>,
            }
            newColumns.push(newColumn);
        }

        const rowData = await getTable(tableIndex);
        if (!Array.isArray(rowData)) {
            setRows([]);
            setColumns([]);
            return null;
        }

        const newRows = getObjectRowsFromArray(rowData, newColumns, setMarkerPosition);

        setRows(newRows);
        setColumns(newColumns)
    }, [techMonitoring, setMarkerPosition])

    useEffect(() => {
        if (techMonitoring.selectedTable) {
            updateColumnsAndRows();
        }
    }, [techMonitoring, updateColumnsAndRows]);

    if (!techMonitoring.selectedTable) {
        return null;
    }

    

    const tableStyles = [styles.bottomTable, !isShowLeftbar ? styles.fullWidth : null].join(" ");
    return <div className={tableStyles}>
        <div className={styles.tableBody}>
        <AntdTable 
            size='small' 
            summary={() => {
                return <AntdTable.Summary fixed>
                    <AntdTable.Summary.Row>
                        {techMonitoring.selectedTable?.table?.total?.map((el, index) => (
                            <AntdTable.Summary.Cell 
                                key={index} 
                                className={styles.bottomTableSummary}
                            >
                                <div>{el || '---'}</div>
                            </AntdTable.Summary.Cell>
                        ))}
                    </AntdTable.Summary.Row>
                </AntdTable.Summary>
            }} 
            scroll={{y: 220, x: "max-content"}} 
            style={{ height: "100%", width: '100%' }} 
            tableLayout='auto' 
            pagination={false} 
            expandable={{
                indentSize: 2
            }}
            dataSource={rows} 
            columns={columns} 
        />
        </div>
    </div>
});