import { useCallback, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { PageHeader } from "./PageHeader";
import CulturesTable from "./CulturesTable"; 
import CreateCultureModal from "./CreateCultureModal";
import EditCultureModal from "./EditCultureModal";
import { Divider, notification } from "antd";
import { $culturesStore, getCulturesFx, editCultureFx, deleteCultureFx, getCultureByNameFx } from "store/cultures";
import { $userStore } from "store/user";

const Cultures = () => {
    const [createModalShow, setCreateModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editableItem, setEditableItem] = useState({});
    const store = useStore($culturesStore);
    const {viewAsUserMode} = useStore($userStore);

    // Fetch cultures
    useEffect(() => {
        getCulturesFx();
    }, [viewAsUserMode]);

    // Callbacks
    const updateCulture = useCallback((data, name) => {
        return editCultureFx({
            name: name || data.title,
            ...data,
            color: typeof(data.color) === "string" ? data.color : data.color.toHexString()
        });
    }, []);

    const removeCulture = useCallback((cultureName) => {
        deleteCultureFx(cultureName)
            .then(() => notification.success({message: "Культура удалена"}))
            .catch(() => notification.error({message: "Не удалось удалить культуру"}));
    }, [])

    const handleCreate = async (data) => {
        try {
            // If culture already exists
            const isExists = await getCultureByNameFx(data.title);
            if (!isExists.data.error_msg) {
                notification.error({message: "Культура с таким названием уже есть"})
                return;
            }

            updateCulture(data)
                .then(() => notification.success({message: "Культура создана"}))
                .catch(() => notification.error({message: "Не удалось создать культуру"}));

            setCreateModalShow(false);
        } catch {
            notification.error({message: "Не удалось создать культуру"})
        }
    }

    const handleEdit = async (data, name) => {
        updateCulture(data, name)
            .then(() => notification.success({message: "Культура успешно изменена"}))
            .catch(() => notification.error({message: "Не удалось изменить культуру"}));
        setEditModalShow(false);
    }

    return (
        <>
        <PageHeader onClick={() => setCreateModalShow(true)}/>
        <Divider />
        <CulturesTable
            items={store?.list}
            onDelete={removeCulture}
            onEdit={(item) => {
                setEditModalShow(true);
                setEditableItem(item);
            }}
        />
        <CreateCultureModal
            open={createModalShow}
            onCancel={() => setCreateModalShow(false)}
            onSubmit={handleCreate}
        />
        <EditCultureModal
            open={editModalShow}
            activeItem={editableItem}
            onCancel={() => setEditModalShow(false)}
            onSubmit={(data) => handleEdit(data, editableItem.name)}
        />
        </>
    );
}

export default Cultures;