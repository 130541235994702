import Modal from "antd/es/modal/Modal";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import CreateWorkerForm from "./CreateWorkerForm";
import helpMessageRoutes from "helpMessageRoutes";

const PickCompanyModal = ({opened, onCancel, onSubmit}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Добавить предпряитие" helpFile={helpMessageRoutes.worker.create}/>}
            open={opened}
            onCancel={onCancel}
            footer={null}
            destroyOnClose
        >
            <CreateWorkerForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitTitle={"Добавить"}
            />
        </Modal>
    );
}

export default PickCompanyModal;