import { Button } from "antd";
import { usePermission } from "hooks/usePermission";
import styles from "./page-header.module.scss";

export const PageHeader = ({user, onClick}) => {
  const canEdit = usePermission("user", "edit");

  return (
    <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>Роли пользователя {user}</h2>
        { canEdit &&
          <Button
            onClick={onClick}
          >
            Сохранить изменения
          </Button>
        }
    </div>
  )
}
