import { Table, Dropdown, Button } from "antd";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { usePermission } from "hooks/usePermission";
import { useMemo } from "react";

const tableColumns = [
    {
        title: "Модель",
        dataIndex: "model",
        key: "model"
    },
    {
        title: "Номер",
        dataIndex: "number_gov",
        key: "number_gov"
    },
    {
        title: "Инвентарный номер",
        dataIndex: "number_inv",
        key: "number_inv"
    },
    {
        title: "Предприятие",
        dataIndex: "company",
        render: company => company.name
    },
    {
        title: "Подразделение",
        dataIndex: "section",
        render: section => section.title
    },
    {
        title: "Сотрудник",
        dataIndex: "user",
        render: ({name, surname, patronymic, mail, login}) =>
            (name && surname && patronymic)
                ? [name, surname, patronymic].join(" ")
                : mail || login
    },
    {
        width: "10%",
        render: (item) =>
            (item.onEdit || item.onDelete) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Редактировать", key: "editItem"},
                        {label: "Удалить", key: "deleteItem"},
                    ].filter(menuItem => menuItem.key === "editItem" ? item.onEdit : item.onDelete),
                    onClick: (dropDownItem) =>
                        dropDownItem.key === "deleteItem"
                            ? item.onDelete()
                            : item.onEdit()
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
]

const TechTable = ({items, onDelete, onEdit}) => {
    const canEdit = usePermission("technique", "edit");
    const canDelete = usePermission("technique", "delete");

    const tableItems = useMemo(() => 
        items.map(item => ({
            ...item,
            onEdit: canEdit && (() => onEdit(item)),
            onDelete: canDelete && (() => onDelete(item.id))
        })),

        [items, onDelete, onEdit, canEdit, canDelete]
    );

    return (
        <Table
            columns={tableColumns}
            dataSource={tableItems || []}
        />
    );
}

export default TechTable;