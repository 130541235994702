import { Drawer, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useStore } from "effector-react";
import { usePermission } from "hooks/usePermission";
import { $sidebarState, closeSidebar } from "store/ui";
import { defaultMenuItems } from "./helpers";

const Sidebar = () => {
  const isOpen = useStore($sidebarState);
  const navigate = useNavigate();

  const companyAccess = usePermission("company");
  const companiesStatsAccess = usePermission("companies-stats");
  const usersAccess = usePermission("user");
  const rolesAccess = usePermission("role");
  const fieldsAccess = usePermission("plantation");
  const culturesAccess = usePermission("culture");
  const sectionsAccess = usePermission("section");
  const storageAccess = usePermission("storage");
  const techAccess = usePermission("technique");
  const techMonitoringAccess = usePermission("technique-monitoring");
  const techMonitoringGroupAccess = usePermission("technique-monitoring-group");
  const userGroupsAccess = usePermission("usersGroups");
  const techOperationsAccess = usePermission("tech-operations");
  const techOperationsGropupsAccess = usePermission("tech-operations-groups");
  const weighingAccess = usePermission('weighing');
  const fieldProductivityAccess = usePermission('field-productivity');
  const storageCopacityAccess = usePermission('storage-copacity');
  const materialAccess = usePermission('material');
  const materialGroupsAccess = usePermission('material-groups');
  
  const handleMenuItemSelect = ({ key }) => {
    navigate(key);
    closeSidebar();
  }

  const menuItems = defaultMenuItems.filter((item) => {
    if (item.key === 'stats-menu') {
      item.children = item.children.filter(item => {
        if(item.key === '/companies-stats' && !companiesStatsAccess) {
          return false;
        }
        if(item.key === '/storage-copacity' && !storageCopacityAccess) {
          return false;
        }
        if(item.key === '/field-productivity' && !fieldProductivityAccess) {
          return false;
        }
        if(item.key === "/culture-rotation-stats" && !fieldsAccess) {
          return false;
        }
        if(item.key === "/technique-monitoring" && !techMonitoringAccess) {
          return false;
        }
        if(item.key === '/technique-monitoring-group' && !techMonitoringGroupAccess) {
          if(item.key === "/technique-monitoring" && !techMonitoringAccess) {
            return false;
          }
        }
        return true;
      });
      if (item.children.length === 0) {
        return false;
      }
      return true;
    }
    if (item.key === 'guides') {
      item.children = item.children.filter(item => {
        if(item.key === "/cultures" && !culturesAccess) {
          return false;
        }
        if(item.key === "/tech-operations" && !techOperationsAccess) {
          return false;
        }
        if(item.key === "/tech-operations-groups" && !techOperationsGropupsAccess) {
          return false;
        }
        if(item.key === '/material' && !materialAccess) {
          return false;
        }
        if(item.key === '/material-groups' && !materialGroupsAccess) {
          return false;
        }
        return true;
      });
      if (item.children.length === 0) {
        return false;
      }
      return true;
    }
    if(item.key === "/companies" && !companyAccess) {
      return false;
    }
    if(item.key === "/users" && !usersAccess) {
      return false;
    }
    if(item.key === "/roles" && !rolesAccess) {
      return false;
    }
    if(item.key === "/fields" && !fieldsAccess) {
      return false;
    }
    if(item.key === "/sections" && !sectionsAccess) {
      return false;
    }
    if(item.key === "/storage" && !storageAccess) {
      return false;
    }
    if(item.key === '/usersGroups' && !userGroupsAccess) {
      return false;
    }
    if(item.key === "/technique" && !techAccess) {
      return false;
    }
    if(item.key === "/weighing" && !weighingAccess) {
      return false;
    }
    return true;
  });

  return (
    <Drawer
      title="Agro"
      open={isOpen}
      placement="left"
      onClose={closeSidebar}
      closeIcon={<ArrowLeftOutlined/>}
    >
      <Menu
        style={{ width: "100%", border: "none" }}
        onSelect={handleMenuItemSelect}
        selectedKeys={[window.location.pathname]}
        mode="inline"
        items={menuItems}
      />
    </Drawer>
  );
}

export default Sidebar;