import { useStore } from "effector-react";
import { $companiesStore, getCompanies } from "store/companies";
import {Form, Input, Select, Button} from "antd";
import { useEffect } from "react";

const UpdateSectionForm = ({
    onSubmit,
    submitTitle,
    onCancel,
    id, title, description, phone, company
}) => {
    const companies = useStore($companiesStore);

    const onFinish = (data) => {
        onSubmit({section: id, ...data});
    }

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Form
            autoComplete={false}
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item
                label="Предприятие"
                name="company"
                required={false}
                rules={[{required: true, message: 'Выберете предприятие'}]}
                initialValue={company?.id}
            >
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        option?.label
                            .toLowerCase()
                            .includes(
                                input.toLowerCase()
                            )
                    }
                    options={companies?.list?.map(item => ({value: item.id, label: item.name})) || []}
                />
            </Form.Item>

            <Form.Item
                label="Название подразделения"
                name="title"
                required={false}
                rules={[{ required: true, message: 'Введите название подразделения' }]}
                initialValue={title}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Описание подразделения"
                name="description"
                required={false}
                rules={[{ required: true, message: 'Введите описание подразделения' }]}
                initialValue={description}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Номер телефона"
                name="phone"
                required={false}
                rules={[{ required: true, message: 'Введите номер телефона' }]}
                initialValue={phone}
            >
                <Input />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default UpdateSectionForm;