import { useEffect, useMemo, useCallback } from "react";
import { useStore } from "effector-react";
import { Select, Input, Form, Button } from "antd";
import { $companiesStore, getCompanies } from "store/companies";
import { $sectionsStore, getSectionsFx } from "store/sections";

const CreateWorkerForm = ({onSubmit, submitTitle, onCancel, company, section, salary, percentage}) => {
    const [form] = Form.useForm();
    const activeCompany = Form.useWatch("company", form);


    const {list: initialCompanyList} = useStore($companiesStore);
    const companyList = useMemo(
        () => company ? ([company, ...initialCompanyList.filter(item => item.id !== company.id)]) : initialCompanyList,
        [company, initialCompanyList]
    );

    const {list: initialSectionList}  = useStore($sectionsStore);
    const sectionList = useMemo(
        () => section ? ([{...section, company: {id: section.company}}, ...initialSectionList.filter(item => item.id !== section.id)]) : initialSectionList,
        [section, initialSectionList]
    );

    const filteredSections = useMemo(
        () => sectionList.filter(({company}) => activeCompany === company.id),
        [activeCompany, sectionList]
    );

    const handleCompanyChange = useCallback((value) => {
        form.setFieldsValue({
            company: value,
            section: null
        });
    }, [form]);

    useEffect(() => {
        getCompanies();
        getSectionsFx();
        form.setFieldsValue({
            company: company?.id,
            section: section?.id,
            percentage,
            salary
        })
    }, [company, section, percentage, salary, form]);

    return (
        <Form
            onFinish={onSubmit}
            form={form}
            layout="vertical"
        >
            <Form.Item
                label={"Предприятие"}
                name={"company"}
                required={false}
                rules={[{ required: true, message: "Выберете предприятие"}]}
            >
                <Select
                    options={companyList.map(({name, id}) => ({value: id, label: name}))}
                    onChange={handleCompanyChange}
                />
            </Form.Item>
            <Form.Item
                label={"Подразделение"}
                name={"section"}
                required={false}
                validateStatus={(activeCompany && filteredSections.length === 0) && "error"}
                help={
                    (activeCompany && filteredSections.length === 0)
                        && "У предприятие не создано ни одного подразделения.\nОбратитесь к администратору"
                    }
                rules={[{ required: true, message: "Выберете подразделение"}]}
            >
                <Select
                    disabled={!activeCompany}
                    options={filteredSections.map(({title, id}) => ({value: id, label: title}))}
                />
            </Form.Item>
            <Form.Item
                label={"Ставка"}
                name={"percentage"}
                required={false}
                rules={[{ required: true, message: 'Введите ставку' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={"Оклад"}
                name={"salary"}
                required={false}
                rules={[{ required: true, message: "Введите оклад" }]}
            >
                <Input />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default CreateWorkerForm;