import { useCallback } from "react";
import {Form, Input, Button, DatePicker, notification} from "antd";
import { validateStaffDataFx, searchStaffMemberFx } from "../../store/staff";
import dayjs from "dayjs";


const UpdateStaffForm = ({
    onSubmit,
    submitTitle,
    onCancel,
    company, section, workerId: id,
    surname, name, patronymic, birthday,
    passport_series, passport_number,
    date_entry, inn, snils, percentage, 
    salary, phone, salary_premium, salary_additional
}) => {
    const [form] = Form.useForm();

    const validateData = useCallback((data) => {
        validateStaffDataFx({
            section,
            company,
            id,
            ...data,
            birthday: data.birthday ? dayjs(data.birthday).format("YYYY-MM-DD") : null,
            date_entry: data.date_entry ? dayjs(data.date_entry).format("YYYY-MM-DD") : null,
        }).then(res => {
            if (res?.data?.status === "ok")
                onSubmit({id, section, company, ...data})
            else
                notification.error({message: res?.data?.error_message});
        });
    }, [section, company, form]);

    const searchEmployee = useCallback((data) => {
        searchStaffMemberFx({
            section,
            company,
            ...form.getFieldsValue(),
            birthday: form.getFieldValue("birthday") && dayjs(data.birthday).format("YYYY-MM-DD"),
            date_entry: form.getFieldValue("date_entry") && dayjs(data.date_entry).format("YYYY-MM-DD"),
            percentage, 
            salary,
        }).then(({data}) => {
            if (data?.status === "ok") {
                form.setFieldsValue({
                    ...data.user,
                    birthday: data.user.birthday ? dayjs(data.user.birthday, "YYYY-MM-DD") : null,
                    date_entry: data.user.date_entry ? dayjs(data.user.date_entry, "YYYY-MM-DD") : null,
                    salary: salary,
                    percentage: percentage,
                })
            } else if (data?.status === "error") {
                notification.error({message: data?.error_message});
                if (data?.fields) {
                    form.setFields(data.fields.map(err => ({name: err.code, errors: [err.name]})))
                }
            }
        });
    }, [section, company, form])

    return (
        <Form
            autoComplete={false}
            requiredMark={false}
            layout="vertical"
            onFinish={validateData}
            form={form}
        >
            <Form.Item
                label="Фамилия"
                name="surname"
                initialValue={surname}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Имя"
                name="name"
                initialValue={name}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Отчество"
                name="patronymic"
                initialValue={patronymic}
            >
                <Input />
            </Form.Item>

            <Form.Item style={{marginBottom: 0}}>
            <Form.Item
                label="Дата рождения"
                name="birthday"
                initialValue={birthday ? dayjs(birthday, "YYYY-MM-DD") : null}
                style={{display: 'inline-block', marginRight: 8, minWidth: '100px', width: 'calc(30% - 8px)'}}
                >
                <DatePicker format={"DD.MM.YYYY"} />
            </Form.Item>
            <Form.Item
                label="Номер телефона"
                name="phone"
                initialValue={phone}
                required={false}
                style={{display: 'inline-block', width: '70%'}}
            >
                <Input />
            </Form.Item>
            </Form.Item>

            <Form.Item name="passport" label="Паспорт сотрудника" style={{marginBottom: 0}}>
            <Form.Item
                name="passport_series"
                initialValue={passport_series}
                required={false}
                style={{display: "inline-block", width: "calc(30% - 8px)", marginRight: 8}}
            >
                <Input placeholder="Серия" />
            </Form.Item>

            <Form.Item
                name="passport_number"
                initialValue={passport_number}
                required={false}
                style={{display: "inline-block", width: "70%"}}
            >
                <Input placeholder="Номер" />
            </Form.Item>
            </Form.Item>

            <Form.Item style={{marginBottom: 0}}>
            <Form.Item
                label="ИНН"
                name="inn"
                required={false}
                initialValue={inn}
                style={{display: "inline-block", width: "48%", marginRight: "4%"}}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="СНИЛС"
                name="snils"
                initialValue={snils}
                required={false}
                style={{display: "inline-block", width: "48%"}}
            >
                <Input />
            </Form.Item>
            </Form.Item>

            <Form.Item style={{marginBottom: 0}}>
            <Form.Item
                label="Ставка"
                name="percentage"
                required={true}
                initialValue={percentage}
                style={{display: "inline-block", width: "48%", marginRight: "4%"}}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Оклад"
                name="salary"
                required={true}
                initialValue={salary}
                style={{display: "inline-block", width: "48%"}}
            >
                <Input />
            </Form.Item>
            </Form.Item>

            <Form.Item style={{margin: 0}}>
            <Form.Item 
                label={'Премия'} 
                name={'salary_premium'}
                initialValue={salary_premium}
                style={{display: 'inline-block', width: '25%', marginRight: '3%'}}
                >
                <Input />
            </Form.Item>
            <Form.Item 
                label={'Доп. выплаты'} 
                name={'salary_additional'}
                initialValue={salary_additional}
                style={{display: 'inline-block', width: '25%',marginRight: '3%'}}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={'Дата трудоустройства'}
                name={'date_entry'}
                initialValue={date_entry ? dayjs(date_entry, "YYYY-MM-DD") : null}
                style={{display: 'inline-block',  marginTop: 0, width: '44%'}}
            >
                <DatePicker format={'DD.MM.YYYY'} style={{width: '100%'}} />
            </Form.Item>
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <>
                        {!id && <Button type="primary" onClick={searchEmployee}>Найти сотрудника</Button> }
                        <Button type="primary" htmlType="submit">{submitTitle}</Button>
                    </>
                </div>
            </Form.Item>
        </Form>
    );
}

export default UpdateStaffForm;

