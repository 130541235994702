import { useState, useEffect } from "react";
import { Modal, Button, Upload, Spin, Select, notification } from "antd";
import { CheckCircleOutlined, LoadingOutlined, UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import helpMessageRoutes from "helpMessageRoutes";
import { $editFieldRow, $mapMode, uploadFieldFx } from "store/fields";
import {
  getCompanies,
  $companiesFetched,
  $companiesStore
} from "../../store/companies";
import { useStoreMap, useUnit } from "effector-react";
import st from "./index.module.scss";

const ImportFieldsModal = ({ onCancel, ...props }) => {
  const [editRow, companiesFetched] = useUnit([
    $editFieldRow,
    $companiesFetched
  ]);

  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isFileError, setIsFileError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [companyId, setCompanyId] = useState(1);

  const companies = useStoreMap({
    store: $companiesStore,
    keys: [companiesFetched],
    fn: (state) => state.list.map(({ id, name }) =>
      ({ value: id, label: name })
    )
  })

  useEffect(() => {
    !companiesFetched && getCompanies()
  }, [companiesFetched]);

  const handleUpload = async() => {

    try {
      if(!file) {
        notification.error({ message: "Добавьте файл" });
        return;
      }
      setIsUploading(true);
      const isOk = await uploadFieldFx({ companyId, fieldFile: file });
      setIsUploading(false);
      isOk ?
        notification.success({ message: "Поле успешно загружено" }) :
        notification.error({ message: "Ошибка при загрузке файла" });

      handleCancel();
    } catch(e) {
      notification.error({ message: "Не удалось загрузить файл" });
      setIsUploading(false);
      setIsFileError(true);
    }
  }

  const handleCancel = () => {
    setFile(null);
    onCancel();
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={"Загрузить поля"}
          helpFile={helpMessageRoutes.fields.import}
        />
      }
      footer={[
        <Button onClick={handleCancel}>Отмена</Button>,
        <Button
          type="primary"
          loading={isUploading}
          onClick={() => handleUpload(file)}
        >
          Загрузить
        </Button>
      ]}
      onCancel={handleCancel}
      open={true}
      {...props}
    >
      <Upload.Dragger
        name="file"
        accept=".kml"
        fileList={null}
        multiple={false}
        onRemove={() => setFile(null)}
        beforeUpload={(file) => {
          if(!isFileError){
            setFile(file);
          }
          return false;
        }}
        onChange={({ file }) => {
          switch(file.status) {
            case "uploading":
              setIsFileLoading(true);
              setIsFileError(false);
              break;
            case "success":
              setIsFileLoading(false)
              setIsFileError(false);
              break;
            case "error":
              setIsFileLoading(false);
              setIsFileError(true);
              break;
            default:
              break;
          }
        }}
      >
        <p style={{ fontSize: 50, display: "flex", flexDirection: "column", alignItems: "center" }}>
          {
            isFileLoading &&
            <>
              <Spin indicator={<LoadingOutlined/>}/>
            </>
          }
          {
            isFileError &&
            <>
              <CloseCircleOutlined/>
              Ошибка при загрузке файла
            </>
          }
          {
            file && !isFileError &&
            <>
              <CheckCircleOutlined/>
              Файл добавлен
            </>
          }

          {
            !file &&
            <>
              <UploadOutlined/>
              Загрузите файл в формате kml
            </>
          }
        </p>
        <p>
          Перетащите файл или нажмите на область
        </p>
      </Upload.Dragger>
      <div className={st.modalItem} style={{ marginTop: 8 }}>
        <div>Выбор компании</div>
        {companiesFetched &&
          <Select
            options={companies}
            className={st.modalItem}
            placeholder="Выберите компанию"
            value={companies.find(item => item.value == companyId).label}
            onChange={(value) => setCompanyId(value)}
          />
        }
      </div>
    </Modal>
  );
}

export default ImportFieldsModal;
