import React, { useEffect } from 'react';
import { useStore } from "effector-react";
import { Button, Modal, Input } from "antd";
import { useForm, Controller } from "react-hook-form";

import helpMessageRoutes from 'helpMessageRoutes';
import {
  $editRow,
  closeChangeModal,
  editOrCreateUserGroupFx
} from "../../store/userGroups";

import st from "./index.module.scss";
import { ModalTitleForHelp } from 'components/ModalTitleForHelp';


const items = [
  {
    label: 'Имя',
    name: 'title',
    type: 'text',
    rules: { required: 'Не указан пароль' }
  },
  {
    label: 'Hello Page',
    name: 'hello_page',
    type: 'text',
    rules: { required: 'Не указана почта' }
  },
]

const ChangeModal = ({
  notificationsApi,
}) => {
  const editRow = useStore($editRow);

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      title: editRow.title,
      hello_page: editRow.hello_page,
    }
  });

  const onSubmit = async (props) => {
    const { title, hello_page } = props;

    const { data = {} } = await editOrCreateUserGroupFx({
      title,
      hello_page,
      user_group_id: editRow.id
    });

    if(data.error_msg) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    } else {
      notificationsApi.success({
        message: editRow.id ? 'Группа успешно отредактирована' : 'Группа успешно создана',
        duration: 5
      });
      closeChangeModal();
    }
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={editRow.id ? "Редактирование группы" : "Создание новой группы"}
          helpFile={helpMessageRoutes.userGroups[editRow.id ? "edit" : "create"]}
        />
      }
      open={true}
      onCancel={closeChangeModal}
      footer={null}
    >
      <form
        className={st.modalForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        {items.map(({ name, label, type, rules }) => (
          <div
            className={st.modalItem}
            key={name}
          >
            <div>{ label }</div>
            <div className={st.inputError}>{errors[name] && errors[name].message}</div>
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) =>
                <Input
                  status={errors[name] ? 'error' : ''}
                  type={type}
                  {...field}
                />
              }
            />
          </div>
        ))}
        <div className={st.modalFooter}>
          <Button
            onClick={closeChangeModal}
          >
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeModal;