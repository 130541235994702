import { Divider, notification } from "antd";
import { useParams, useLocation } from "react-router-dom"
import { RolesTable } from "./RolesTable";
import { PageHeader } from "./PageHeader/PageHeader";
import { useStore } from "effector-react";
import { $userRolesStore, saveUserPermissionsFx } from "store/userRoles";


const UserRoles = () => {
    const {id} = useParams();
    const userName = useLocation().state || id;
    const {roles, editedRoles} = useStore($userRolesStore);

    const handleSave = () => {
        roles
          .filter(role => editedRoles.has(role.id))
          .forEach(role => {
              saveUserPermissionsFx({uid: id, role})
                .then(res => {
                  if (res === false)
                    notification.error({key: "userPermissions", message: "Ошибка сохранения прав"})
                  else
                    notification.success({key: "userPermissions", message: "Права доступа сохранены"});
                })
                .catch(e => notification.error({key: "userPermissions", message: "Ошибка сохранения прав"}))
          })
    }


    return (
      <>
        <PageHeader user={userName} onClick={handleSave} />
        <Divider />
        <RolesTable userId={id} />
      </>
    )
}


export default UserRoles;