function getNearestItem(clickedPos, items) {
  let nearestElement = null;
  let nearestPoint = null;
  for (const item of items) {
    const localLngNearest =
      item.pos.x > clickedPos.lng
        ? item.pos.x - clickedPos.lng
        : clickedPos.lng - item.pos.x;
    const localLatNearest =
      item.pos.y > clickedPos.lat
        ? item.pos.y - clickedPos.lat
        : clickedPos.lat - item.pos.y;
    const localPoint = localLatNearest + localLngNearest;
    if (nearestPoint) {
      if (nearestPoint > localPoint) {
        nearestPoint = localPoint;
        nearestElement = item;
      }
    } else {
      nearestPoint = localPoint;
      nearestElement = item;
    }
  }

  return nearestElement;
}
export default getNearestItem;
