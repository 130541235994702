import React, { useEffect, useState } from "react";
import styles from "../index.module.scss";
import { Button, Divider } from "antd";
import { CloseOutlined, CompassOutlined } from "@ant-design/icons";
import {
    findSensor,
    getFuelLevel,
    getIgnition,
    getTerminalPower,
    getVoltage,
} from "../helpers/sensorHandler";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export function PopoverMenu({ clickedItem = {}, mousePosition, closePopover }) {
    const { nearestItem, technique } = clickedItem;
    const [tableItems, setTableItems] = useState([]);

    const popoverWidth = 600;
    const popoverHeight = 300;

    useEffect(() => {
        if (nearestItem) {
            const fuelLevel = getFuelLevel(nearestItem.p, technique.d.sens);
            let calibrationValue = findSensor("Объём тарировки", technique.d.sens);
            if (calibrationValue) {
                calibrationValue = calibrationValue.tbl[0].b;
            }
            const newTableItems = [
                getIgnition(nearestItem.p, technique.d.sens),
                fuelLevel && (
                    <>
                        Vбака: <span style={{ color: "green" }}>{fuelLevel} л</span>
                    </>
                ),
                getTerminalPower(nearestItem.p, technique.d.sens),
                getVoltage(technique.d.sens, nearestItem.p) || false,
                calibrationValue && <div>{`Объем тарировки: ${calibrationValue} л`}</div>,
                fuelLevel && calibrationValue && (
                    <div>
                        Заполненность бака:{" "}
                        <span style={{ color: "green" }}>
                            {((fuelLevel / calibrationValue) * 100).toFixed(2)}%
                        </span>
                    </div>
                ),
                calibrationValue && fuelLevel && (
                    <div>
                        Рекомендовано к заправке: &nbsp;
                        <span>{(Math.max(0, calibrationValue * 0.9 - fuelLevel)).toFixed(2)}л</span>
                    </div>
                ),
            ].filter((el) => el);
            setTableItems(newTableItems);
        }
    }, [nearestItem, technique]);


    if (!nearestItem) {
        return null;
    }

    return (
        <div
            style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                zIndex: 10000,
                cursor: "pointer",
            }}
            onClick={closePopover}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                id="tech-monitoring-popover"
                style={{
                    position: "absolute",
                    width: `${popoverWidth}px`,
                    height: `${popoverHeight}px`,
                    cursor: "auto",
                    zIndex: 10000,
                    left:
                        mousePosition.x + popoverWidth / 2 > window.innerWidth
                            ? window.innerWidth - popoverWidth
                            : mousePosition.x - popoverWidth / 2,
                    top:
                        mousePosition.y + popoverHeight > window.innerHeight
                            ? mousePosition.y - popoverHeight - 10
                            : mousePosition.y + 10,
                }}
            >
                <div className={styles.popoverBody}>
                    <div className={styles.popoverHeader}>
                        <div className={styles.popoverTitle}>
                            {technique?.d?.nm ?? "Транспорт"}
                        </div>
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            {dayjs
                                .utc(new Date(nearestItem.t * 1000))
                                .utcOffset(3)
                                .format("DD.MM.YYYY HH:mm:ss")}
                            <Button icon={<CloseOutlined />} onClick={closePopover} />
                        </div>
                    </div>
                    <Divider style={{ margin: "10px 0" }} />
                    <div className={styles.popoverContent}>
                        <div className={styles.popoverTopTable}>
                            <div>{nearestItem.pos.s} км/ч</div>
                            <div style={{ display: "flex", gap: "5px" }}>
                                <CompassOutlined style={{ fontSize: "24px", color: "gray" }} />
                                {nearestItem.pos.sc}
                            </div>
                            <div>
                                {nearestItem.pos.y}
                                <br />
                                {nearestItem.pos.x}
                            </div>
                        </div>
                        <div className={styles.sensors}>
                            Значение датчиков:
                            <div className={styles.popoverTable}>
                                {tableItems.map((el, index) => {
                                    return <div key={index}>{el}</div>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
