import { Button } from "antd";
import { usePermission } from "hooks/usePermission";

import styles from "./page-header.module.scss";

export const PageHeader = ({onClick}) => {
  const canEdit = usePermission("role", "create");
  return (
    <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>Роли пользователей</h2>
        { canEdit &&
          <Button
            onClick={onClick}
          >
            Создать роль
          </Button>
        }
    </div>
  )
}
