import React, { useEffect, useState } from 'react';
import { useStore } from "effector-react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Divider,
  Table,
  Modal,
  Dropdown,
  Input,
  Alert
} from 'antd';
import { IoEllipsisHorizontal } from 'react-icons/io5'
import {
  $companiesStore,
  getCompanies,
  createOrEditCompanyFx,
  unblockCompanyByIdFx,
  blockCompanyByIdFx
} from 'store/companies';
import {$userStore, $viewAsAdminUser} from "store/user";

import helpMessageRoutes from 'helpMessageRoutes';

import st from './index.module.scss';
import { ModalTitleForHelp } from 'components/ModalTitleForHelp';
import { usePermission } from 'hooks/usePermission';

const roleGroups = {
  admin: 1,
  users: 5
}

const Companies = () => {
  const { viewAsUserMode, user } = useStore($userStore);
  const { user: adminUser } = useStore($viewAsAdminUser);
  const list = useStore($companiesStore).list?.sort(company => company.blocked ? 1 : -1) || [];
  const canEdit = usePermission("company", "edit");
  const canCreate = usePermission("company", "create");

  const [isModalOpen, setIsOpenModal] = useState(false);
  const [editedItem, setEditedItem] = useState(null);
  const [modalMode, setModalMode] = useState();
  const [alertState, setAlertState] = useState([false, "", ""]); // Alert state [show: boolean, status: string, message: string]

  const { control, handleSubmit, formState: { errors }, setValue, reset } = useForm({
    defaultValues: {
      name: "",
      inn: "",
      address: "",
      ogrn: "",
      phone: "",
      id: null
    }
  });

  const onSubmit = (props) => {
    setIsOpenModal(false);
    try {
      createOrEditCompanyFx(props)
        .then(res => setAlertState(res.data.status === "ok" ? [true, "ok", "Операция прошла успешно"]: [true, "error", "Ошибка запроса"]))
        .catch(e => setAlertState([true, "error", "Ошибка запроса"]));
    } catch(e) {
      setAlertState([true, "error", "Ошибка запроса"]);
    }
  }

  const onUnblock = async (id) => {
    try {
      const res = await unblockCompanyByIdFx(id);
      setAlertState([true, res.data.status,
        res.data.status === "ok" ?
        "Предприятие разблокировано":
        "Не удалось разблокировать"
      ]);
    } catch(e) {
      setAlertState([true, "error", "Не удалось разблокировать"]);
    }
  }

  const onBlock = async (id) => {
    try {
    const res = await blockCompanyByIdFx(id);
    setAlertState([true, res.data.status,
      res.data.status === "ok" ?
      "Предприятие заблокировано":
      "Не удалось заблокировать"
    ]);
    } catch(e) {
      setAlertState([true, "error", "Не удалось заблокировать"]);
    }
  }

  useEffect(() => {
    getCompanies()
  }, [viewAsUserMode])

  useEffect(() => {
    if(editedItem){
      companyData.forEach(({ title, dataIndex }) => {
        setValue(dataIndex, editedItem[dataIndex], { shouldValidate: true })
      })
      setValue('id', editedItem.id, { shouldValidate: true })
    }

  }, [editedItem])

  const companyData = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      rules: { required: "Не введено название" }
    },
    {
      title: 'Инн',
      dataIndex: 'inn',
      key: 'inn',
      rules: { required: "Не указан ИНН" }
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      rules: { required: "Адрес не введен" }
    },
    {
      title: 'ОГРН',
      dataIndex: 'ogrn',
      key: 'ogrn',
      rules: { required: "Не указан ОГРН" },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    }
  ]
  

  const columns = [
    ...companyData,
    {
      title: '',
      width: 100,
      render: (row) => {
        const dropdownItems = [
            {
              key: 'editCompany',
              label: (
                <div
                  onClick={() => {
                    setIsOpenModal(true);
                    setEditedItem(row);
                    setModalMode('edit');
                  }}
                  className='dropdownItem'
                >
                  Редактировать предприятие
                </div>
              )
            },
            {
              key: 'blockCompany',
              label: (
                <div
                  className='dropdownItem'
                  onClick={() => onBlock(row.id)}
                >
                  Заблокировать предприятие
                </div>
              )
            },
            {
              key: 'unblockCompany',
              label: (
                <div
                  className="dropdownItem"
                  onClick={() => onUnblock(row.id)}
                >
                  Разблокировать предприятие
                </div>
              )
            }
          ]
        const items = dropdownItems.filter((item) => {
            if (item.key === "blockCompany" && (row.blocked || !canEdit))
              return false;
            if (item.key === "unblockCompany" && (!row.blocked || !canEdit))
              return false;
            return true;
        });
        
        if (canEdit) return (
          <Dropdown
            menu={{ items }}
            trigger="click"
          >
            <Button>
              <IoEllipsisHorizontal height={30} width={30} />
            </Button>
          </Dropdown>
        )
      }
    }
  ]

  if((viewAsUserMode && adminUser.group == roleGroups.admin) || user.group == roleGroups.admin){
    columns.splice(5, 0,  {
      title: 'Статус',
      dataIndex: '',
      key: '',
      render: (row) => {
        const { blocked } = row;
        let str = 'активен';

        if(blocked){
          str = 'заблокирован'
        }

        return str;
      }
    })
  }

  return (
    <div className={st.companies}>
      <div className={st.header}>
        <div className={st.pageTitle}>Список предприятий:</div>
        { canCreate &&
          <Button
            onClick={() => {
              setEditedItem(null);
              reset();
              setIsOpenModal(true);
              setModalMode('new');
            }}
          >
            Создать новое предприятие
          </Button>
        }
      </div>
      <Divider />
      <Table
        dataSource={list}
        columns={columns}
        pagination={{
          defaultPageSize: 111
        }}
        scroll={{ x: true }}
      />
      <Modal
        title={modalMode === 'edit'
          ?
          <ModalTitleForHelp
            title="Редактирование предприятия"
            helpFile={helpMessageRoutes.company.edit}
          />
          :
          <ModalTitleForHelp
            title="Создание нового предприятия"
            helpFile={helpMessageRoutes.company.create}
          />
        }
        open={isModalOpen}
        onCancel={() => setIsOpenModal(false)}
        footer={null}
      >
        <form
          className={st.modalForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          {companyData.map(({ title, dataIndex, rules }) => (
            <div className={st.modalItem}>
              <div>{title}</div>
              <Controller
                name={dataIndex}
                control={control}
                rules={rules}
                render={({ field }) =>
                  <Input
                    {...field}
                  />
                }
              />
            </div>
          ))}
          {Object.keys(errors).length > 0 && Object.keys(errors).map(item => (
            <div className={st.modalError}>{errors[item].message}</div>
          ))}
          <div className={st.modalFooter}>
            <Button
              onClick={() => setIsOpenModal(false)}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
              Сохранить
            </Button>
          </div>
        </form>
      </Modal>
      {alertState[0] &&
        <Alert
          className={st.statusAlert}
          message={alertState[2]}
          type={alertState[1] === "ok" ? "success" : "error"}
          closable
          onClose={() => setAlertState([false, alertState[1]])}
          showIcon
        />
      }
    </div>
  );
};

export default Companies;