import { useMemo } from "react";
import { Table, Button, Dropdown, DatePicker } from "antd";
import { usePermission } from "hooks/usePermission";
import { IoEllipsisHorizontal } from "react-icons/io5";
import dayjs from "dayjs";


const tableColumns = [
    {
        title: "ФИО",
        key: "name",
        render: item => [item.surname, item.name, item.patronymic].join(" ")
    },
    {
        title: "Дата рождения",
        dataIndex: "birthday",
        key: "birthday"
    },
    {
        title: "ИНН",
        dataIndex: "inn",
        key: "inn"
    },
    {
        title: "СНИЛС",
        dataIndex: "snils",
        key: "snils"
    },
    {
        title: "Номер телефона",
        dataIndex: "phone",
        key: "phone"
    },
    {
        title: "Статус",
        dataIndex: "status",
        key: "status"
    },
    {
        width: "10%",
        render: (item) =>
            (item.onEdit || item.onDelete || item.onQuit) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Редактировать", key: "onEdit"},
                        {label: "Уволить", key: "onQuit"},
                        {label: "Удалить", key: "onDelete"},
                    ].filter(menuItem => menuItem.key === 'onQuit' ? item.is_quit === 0 && item.onQuit : item[menuItem.key]),

                    onClick: (dropDownItem) => {
                        switch (dropDownItem.key) {
                            case "onDelete":
                                item.onDelete();
                                break;
                            case "onEdit":
                                item.onEdit();
                                break;
                            case 'onQuit':
                                item.onQuit();
                                break;
                            default:
                                break;
                        }
                    }
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
]

const StaffTable = ({items, onEdit, onDelete, onQuit}) => {
    const canEdit = usePermission("section/:id/staff/", "edit");
    const canDelete = usePermission("section/:id/staff/", "delete");

    const tableItems = useMemo(() =>
        items.map(item => ({
                ...item,
                birthday: item.birthday ? dayjs(item.birthday, "YYYY-MM-DD").format("DD.MM.YYYY") : "",
                status: item.is_quit === 0 
                    ? <div>Трудоустроен {item.date_entry ? dayjs(item.date_entry, 'YYYY-MM-DD').format('DD.MM.YYYY') : null}</div> 
                    : <div>Уволен {item.date_quit ? dayjs(item.date_quit, 'YYYY-MM-DD').format('DD.MM.YYYY') : null}</div>,
                onEdit: canEdit && (() => onEdit(item)),
                onDelete: canDelete && (() => onDelete(item)),
                onQuit: canEdit && (() => onQuit(item)),
            })
        ), 
    [items, canEdit, canDelete, onDelete, onEdit, onQuit]);

    return (
        <Table
            columns={tableColumns}
            dataSource={tableItems || []}
        />
    );
}

export default StaffTable;