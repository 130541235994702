import React, { useEffect } from 'react';
import { useStore } from "effector-react";
import { Button, Modal } from "antd";
import { Form } from 'components/Form';
import { useForm } from "react-hook-form";

import helpMessageRoutes from 'helpMessageRoutes';

import { ModalTitleForHelp } from 'components/ModalTitleForHelp';

import st from "../style.module.scss";
import { $materialGroup, getMaterialGroups } from 'store/materialGroup';
import { editOrCreateMaterialFx } from 'store/material';

const items = {
  input: {
    label: 'Название материала',
    name: 'title',
    rules: { required: 'Не указано название материала' }
  },
  textarea: {
    label: 'Описание материала',
    name: 'description',
    rules: { required: 'Не указана описание материала' }
  },
  select: {
    label: 'Группа материалов',
    name: 'material_group',
    rules: { required: 'Не указана группа материалов' }
  }
}

export const CreateModal = ({
  item,
  notificationsApi,
  onClose
}) => {
  const materialGroups = useStore($materialGroup);
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      title: item ? item.title : "",
      description: item ? item.description : "",
      material_group: item?.material_group ? item.material_group?.id : "",
    }
  });

  useEffect(() => {
    getMaterialGroups();
  }, [])

  const onSubmit = async (props) => {
    const { title, description, material_group } = props;

    const { data } = await editOrCreateMaterialFx({
      description,
      title,
      material_group,
      material: item
    });

    if(data.error_message) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    } else {
      notificationsApi.success({
        message: item ? 'Материал успешно изменен' : 'Материал успешно добавлен',
        duration: 5
      });

      onClose();
    }
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={item ? "Редактирование материала" : "Создание материала"}
          helpFile={helpMessageRoutes.material[item ? "edit" : "create"]}
        />
      }
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <form
        className={st.modalForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Input
          input={items.input}
          form={{ control, formState }}
          name={'name'}
        />
        <Form.TextArea
          input={items.textarea}
          form={{ control, formState }}
          name={'description'}
          rows={4}
        />
        <Form.Select
          input={items.select}
          form={{ control, formState }}
          name={'material_group'}
          options={materialGroups.map(item => ({ label: item.title, value: item.id }))}
          placeholder={items.select.label}
        />

        <div className={st.modalFooter}>
          <Button onClick={onClose}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  );
};