import { Checkbox, Table } from "antd"
import { useStore } from "effector-react"
import { useEffect } from "react"
import {
    $userRolesStore,
    fetchUserRolesFx,
    toggleRoleView,
    toggleRoleCreate,
    toggleRoleEdit,
    toggleRoleDelete
} from "store/userRoles"
import { $userStore } from "store/user"

const tableColumns = [
    {
        title: "Название роли",
        dataIndex: "title",
        key: "title"
    },
    {
        title: "URL роли",
        dataIndex: "url",
        key: "url"
    },
    {
        title: "Просмотр",
        dataIndex: "view",
        key: "viewPermission",
        width: "10%",
        render: (_, role) =>
            <Checkbox
                onClick={() => toggleRoleView(role.id)}
                checked={role.view}
            />
    },
    {
        title: "Создание",
        dataIndex: "create",
        key: "createPermission",
        width: "10%",
        render: (_, role) =>
            <Checkbox
                onClick={() => toggleRoleCreate(role.id)}
                checked={role.create}
            />
    },
    {
        title: "Редактирование",
        dataIndex: "edit",
        key: "editPermission",
        width: "10%",
        render: (_, role) =>
            <Checkbox
                onClick={() => toggleRoleEdit(role.id)}
                checked={role.edit}
            />
    },
    {
        title: "Удаление",
        dataIndex: "delete",
        key: "deletePermission",
        width: "10%",
        render: (_, role) =>
            <Checkbox
                onClick={() => toggleRoleDelete(role.id)}
                checked={role.delete}
            />
    },
]


export const RolesTable = ({userId}) => {
    const {roles} = useStore($userRolesStore);
    const {viewAsUserMode} = useStore($userStore);

    useEffect(() => {
        fetchUserRolesFx(parseInt(userId));
    }, [userId, viewAsUserMode]);

    return (
        <Table
            columns={tableColumns}
            dataSource={roles || []}
        />
    )
}
