import React, { useState } from 'react';
import { useStoreMap, useStore } from "effector-react";

import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { setSession, $userStore } from "./store/user";
import { $globalOverflow } from "./store/ui";
import { privateRoutes, publicRoutes } from 'routes';
import CheckPermission from 'hoc/CheckPermission';

import PageLayout from './layouts/PageLayout';
import AuthLayout from "./layouts/AuthLayout";
import Header from './layouts/Header';

import './shared/styles/App.scss';

function App() {
  const [isInit, setIsInit] = useState(false);
  const globalOverflow = useStore($globalOverflow);

  if(!isInit){
    localStorage.getItem('user') && setSession(JSON.parse(localStorage.getItem('user')));
    setIsInit(true);
  }

  const userId = useStoreMap($userStore, (store) => store.authData.id);

  return (
    <>
      <Helmet titleTemplate="Agro" defaultTitle="Agro">
        <meta name="description" content="Agro" />
      </Helmet>
      <div
        className="page"
        style={{ overflow: globalOverflow ? 'hidden' : ''}}
      >
        <Header />
        <Routes>
          {userId ?
            <Route
              element={<PageLayout />}
            >
              {privateRoutes.map(({path, element}) =>
                <Route key={path} path={path} element={
                  <CheckPermission>{element}</CheckPermission>
                } />
              )}
            </Route> :
            <Route element={<AuthLayout />}>
              {publicRoutes.map(route =>
                <Route key={route.path} {...route} />
              )}
            </Route>
          }
        </Routes>
      </div>
    </>
  );
}

export default App;
