import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, forward } from "effector";

// Store
export const $storageStore = createStore({
    list: []
});

// Events
const setStorageList = createEvent();

// Effects
export const getStorageListFx = createEffect(async (params) => {
    const res = await get(urls.storageList, params);
    return res;
});

export const getStorageFx = createEffect(async (params) => {
    const res = await get(urls.storageGet, params);
    return res;
});

export const editStorageFx = createEffect(async (data) => {
    const res = await post({
        module: "storage",
        action: "edit",
        data: JSON.stringify(data)
    });
    return res;
});

export const deleteStorageFx = createEffect(async (storage) => {
    const res = await get(urls.storageDelete, {storage: storage.id, company: storage.company.id});
    return res;
});

// Forwards
forward({
    from: getStorageListFx.done,
    to: setStorageList
});

// Event handlers
$storageStore
    .on(setStorageList, (_, {result}) => ({list: result.data.list || []}));
