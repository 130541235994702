import { Button, DatePicker, Form, Input, Select } from "antd"
import { useStore } from "effector-react";
import { useCallback, useEffect } from "react";
import dayjs from "dayjs";

import { $fieldsList, fetchFieldsFx } from "store/fields";
import { $staffStore, getStaffFx } from "store/staff";
import { $storageStore, getStorageListFx } from "store/storage";
import { $techniqueStore, getTechniqueFx } from "store/technique";


const units = [
    {
        value: 1,
        label: 'КГ (Килограмм)'
    },
    {
        value: 2,
        label: 'Ц (Центнер)'
    },
    {
        value: 3,
        label: 'Т (Тонн)'
    },
]

const UpdateWeighingForm = ({
    comment, plantation, storage, date_doc,
    worker, technic, weigh_technic,
    date_weighing, weigh_brutto, date_work,
    submitTitle, onCancel, onSubmit,
    technic_additional, id,
}) => {
    const { list: storageList } = useStore($storageStore);
    const { list: staffList } = useStore($staffStore);
    const fieldsList = useStore($fieldsList);
    const techniqueList = useStore($techniqueStore);

    const [form] = Form.useForm();

    useEffect(() => {
        getStorageListFx();
        getStaffFx();
        getTechniqueFx();
        fetchFieldsFx(1);
    }, []);

    const onFinish = useCallback((data) => {
        onSubmit({
            ...data,
            id,
            technic_additional: data.technic_additional ? data.technic_additional : technic_additional ? technic_additional.id : null,
            technic: data.technic ? data.technic : technic ? technic.id : null,
            worker: data.worker ? data.worker : worker ? worker.id : null,
            plantation: data.plantation ? data.plantation : plantation ? plantation.id : null,
            storage: data.storage ? data.storage : storage ? storage.id : null,
            date_doc: data.date_doc ? dayjs(data.date_doc).format('YYYY-MM-DD') : null,
            date_work: data.date_work ? dayjs(data.date_work).format('YYYY-MM-DD') : null,
            date_weighing: data.date_weighing ? dayjs(data.date_weighing).format('YYYY-MM-DD hh:mm:ss') : null,
            unit: data.unit ?? 1,
        });
        onCancel();
    }, [])


    return <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
    >
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px'}}>
                <Form.Item
                    label={'Дата документа'}
                    name={'date_doc'}
                    initialValue={date_doc ? dayjs(date_doc, 'YYYY-MM-DD') : null}
                >
                    <DatePicker format={'DD.MM.YYYY'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label={'Дата работ'}
                    name={'date_work'}
                    initialValue={date_work ? dayjs(date_work, 'YYYY-MM-DD') : null}
                >
                    <DatePicker format={'DD.MM.YYYY'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="Водитель"
                    name="worker"
                >
                    <Select
                        showSearch
                        defaultValue={worker ? `${worker?.name} ${worker?.surname} ${worker?.patronymic}` : null}
                        filterOption={(input, option) =>
                            option?.label
                                .toLowerCase()
                                .includes(
                                    input.toLowerCase()
                                )
                        }
                        options={staffList?.map((item) => {
                            const { name, surname, patronymic, login, mail } = item?.user;
                            const userSign = (name && surname && patronymic)
                                ? [name, surname, patronymic].join(" ")
                                : (login || mail);
                            return {
                                value: item.id,
                                label: userSign
                            }
                        }) || []
                        }
                    />
                </Form.Item>
                <Form.Item
                    label={'Время взвешивание полного'}
                    name={'date_weighing'}
                    initialValue={date_weighing ? dayjs(date_weighing, 'YYYY-MM-DD hh:mm:ss') : null}
                >
                    <DatePicker format={'DD.MM.YYYY HH:mm:ss'} showTime style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label={'Транспорт'}
                    name={'technic'}
                >
                    <Select
                        showSearch
                        defaultValue={technic ? { value: technic.id, label: technic.model } : null}
                        filterOption={(input, option) =>
                            option?.label
                                .toLowerCase()
                                .includes(
                                    input.toLowerCase()
                                )
                        }
                        options={techniqueList?.filter(item => item.type === 'truck')?.map(item => ({ value: item.id, label: item.model })) || []}
                    />
                </Form.Item>
                <Form.Item
                    label={'Прицеп'}
                    name={'technic_additional'}
                >
                    <Select
                        showSearch
                        defaultValue={technic_additional ? { value: technic_additional.id, label: technic_additional.model } : null}
                        filterOption={(input, option) =>
                            option?.label
                                .toLowerCase()
                                .includes(
                                    input.toLowerCase()
                                )
                        }
                        options={techniqueList?.filter(item => item.type === 'trailer')?.map(item => ({ value: item.id, label: item.model })) || []}
                    />
                </Form.Item>
                <Form.Item
                    label="Склад"
                    name="storage"
                    required={false}
                >
                    <Select
                        showSearch
                        defaultValue={storage ? { value: storage.id, label: storage.title } : null}
                        filterOption={(input, option) =>
                            option?.label
                                .toLowerCase()
                                .includes(
                                    input.toLowerCase()
                                )
                        }
                        options={storageList?.map(item => ({ value: item.id, label: item.title })) || []}
                    />
                </Form.Item>
                <Form.Item
                    label={'Поле'}
                    name="plantation"
                >
                    <Select
                        showSearch
                        defaultValue={plantation ? { value: plantation.id, label: plantation.name } : null}
                        filterOption={(input, option) =>
                            option?.label
                                .toLowerCase()
                                .includes(
                                    input.toLowerCase()
                                )
                        }
                        options={fieldsList?.map(item => ({ value: item.id, label: item.name })) || []}
                    />
                </Form.Item>
                <Form.Item
                    label={'Примечание'}
                    name={'comment'}
                    initialValue={comment}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Единица измерения'}
                    name='unit'
                >
                    <Select
                        showSearch
                        defaultValue={units[0]}
                        filterOption={(input, option) =>
                            option?.label
                                .toLowerCase()
                                .includes(
                                    input.toLowerCase()
                                )
                        }
                        options={units?.map((item) => ({ value: item.id, label: item.label }))}
                    />
                </Form.Item>
                <Form.Item
                    label={'Вес полный'}
                    name={'weigh_brutto'}
                    initialValue={weigh_brutto}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={'Вес пустой'}
                    name={'weigh_technic'}
                    initialValue={weigh_technic}
                >
                    <Input />
                </Form.Item>
        </div>
        <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: '10px' }}>
                <Button onClick={onCancel}>Отмена</Button>
                <Button type="primary" htmlType="submit">{submitTitle}</Button>
            </div>
        </Form.Item>
    </Form>
}

export default UpdateWeighingForm;