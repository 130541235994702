import { Button, Form, Select } from "antd";
import { useEffect } from "react";

import styles from './index.module.scss';

const FilterForm = ({ setFilterForm }) => {
    const [filterForm] = Form.useForm();

    const onFinish = (form) => {
        setFilterForm(form);
    }

    useEffect(() => {
        onFinish(filterForm.getFieldValue());
    }, []);


    return <Form
        layout="vertical"
        form={filterForm}
        onFinish={onFinish}
    >
        <div className={styles.filterForm}>
            <Form.Item
                name="activated"
                initialValue={[1]}
                label={'Активность'}
            >
                <Select
                    mode="multiple"
                    options={[
                        {
                            value: 1,
                            label: 'Активен'
                        },
                        {
                            value: 0,
                            label: 'Не активен'
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item
                name="blocked"
                initialValue={[0]}
                label={'Статус'}
            >
                <Select
                    mode="multiple"
                    options={[
                        {
                            value: 1,
                            label: 'Заблокирован'
                        },
                        {
                            value: 0,
                            label: 'Не заблокирован'
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item
                initialValue={[0]}
                name="is_worker"
                label={'Тип аккаунта'}
            >
                <Select
                    mode="multiple"
                    options={[
                        {
                            value: 0,
                            label: 'Системный'
                        },
                        {
                            value: 1,
                            label: 'Сотрудник'
                        },
                    ]}
                />
            </Form.Item>
            <Button htmlType="submit">
                Применить
            </Button>
        </div>
    </Form>
}

export default FilterForm;