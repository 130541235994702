import {createEffect, createEvent, createStore, sample} from "effector";
import { get, post, urls } from 'api';

export const $usersGroupsStore = createStore([]);
export const $editRow = createStore(false);
export const $isEditModalOpen = createStore(false);
export const $maxId = createStore(null);

export const showChangeModal = createEvent();
export const closeChangeModal = createEvent();

export const userGroupCreated = createEvent();
export const userGroupEdited = createEvent();

export const getUsersGroupsFx = createEffect();
export const deleteUserGroupFx = createEffect();
export const editOrCreateUserGroupFx = createEffect();



getUsersGroupsFx.use(async () => {
  const res = await get(urls.getUsersGroups);
  return res;
})

editOrCreateUserGroupFx.use(async (props) => {
  const { user_group_id, hello_page, title } = props;

  const postData = {
    module: 'user_group',
    action: 'edit',
    data: JSON.stringify({ hello_page, title }),
  }

  postData['user_group_id'] = user_group_id || $maxId.getState();

  console.log(postData);

  const res = await post(postData);

  return res;
})

deleteUserGroupFx.use(async (row) => {
  const res = await get(urls.deleteUsersGroup, { user_group_id: row.id });
  return res;
})

$usersGroupsStore
  .on(getUsersGroupsFx.done, (store, { result }) => result.data.list)
  .on(userGroupEdited, (store, { result }) => {
    const { user_group } = result.data

    return store.map(item => item.id == user_group.id ? user_group : item)
  })
  .on(userGroupCreated, (store, { result }) => {
    const { user_group } = result.data

    return [...store, user_group]
  })
  .on(deleteUserGroupFx.done, (store, { params, result }) => {
    return store.filter(item => item.id !== params.id)
  })

$editRow
  .reset(closeChangeModal);

sample({
  clock: showChangeModal,
  fn: (props) => {
    return props.id ? props : { title: "", hello_page: "", id: null }
  },
  target: $editRow
})

sample({
  clock: $editRow,
  source: $editRow,
  target: $isEditModalOpen
})

sample({
  clock: $usersGroupsStore,
  fn: (store) => Math.max.apply(null, store.map(item => item.id)) + 1,
  target: $maxId
})

sample({
  clock: editOrCreateUserGroupFx.done,
  filter: ({ params }) => !!params.user_group_id,
  target: userGroupEdited
})

sample({
  clock: editOrCreateUserGroupFx.done,
  filter: ({ params }) => !params.user_group_id,
  target: userGroupCreated
})