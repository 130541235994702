import Modal from "antd/es/modal/Modal";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import helpMessageRoutes from "helpMessageRoutes";
import UpdateStorageForm from "./UpdateStorageForm";

const CreateStorageModal = ({onSubmit, ...props}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Добавление склада" helpFile={helpMessageRoutes.storage.create}/>}
            footer={null}
            destroyOnClose
            {...props}
        >
            <UpdateStorageForm 
                onSubmit={onSubmit} 
                onCancel={props.onCancel} 
                submitTitle={'Добавить'} 
            />
        </Modal>
    )
}

export default CreateStorageModal;
