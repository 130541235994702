import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useStore } from "effector-react";
import Sidebar from 'components/Sidebar';

import { getUserFx, $userStore } from "../../store/user";

import st from './index.module.scss';

const User = () => {
  const { isFetchedOk } = useStore($userStore);

  useEffect(() => {
    getUserFx();
  }, []);

  if(!isFetchedOk){
    return null;
  }

  return (
    <div className={st.layout}>
      <Sidebar />
      <div className={st.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default User;