import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from 'leaflet';
import "leaflet-polylinedecorator";

function PolylineDecorator({ polyline }) {
  const map = useMap();
  const [decorator, setDecorator] = useState(null);

  map.on('zoom', () => {
    let repeat = '10%';
    const zoom = map.getZoom();
    switch (zoom) {
      case 18:
      case 17:
      case 16:
        repeat = '0.05%';
        break;
      case 15:
      case 14:
        repeat = '0.4%';
        break;
      case 13:
      case 12:
        repeat = '1%';
        break;
      case 11:
      case 10:
        repeat = '5%';
        break;
      case 9:
        repeat = '20%';
        break;
      default:
        repeat = '40%'
    }
    decorator?.setPatterns([{
      repeat: repeat,
      symbol: L.Symbol.arrowHead({
        pixelSize: 10,
        polygon: false,
        pathOptions: { stroke: true, color: "#fff" },
      }),
    }])
  })

  useEffect(() => {
    if (!map) return;

    const polylinePos = polyline.reduce((acc, el) => {
      const res = [];
      el.forEach(el => res.push([el.pos.y, el.pos.x]));
      return [...acc, ...res]
    }, []);


    const newDecorator = (L.polylineDecorator(polylinePos, {
      patterns: [
        {
          repeat: "1%",
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            polygon: false,
            pathOptions: { stroke: true, color: "#fff" },
          }),
        },
      ]
    }).addTo(map));

    setDecorator(newDecorator)
    return () => {
      if (newDecorator) {
        map.removeLayer(newDecorator);
      }
    }
  }, [map, polyline]);

  return null;
}
export default PolylineDecorator;