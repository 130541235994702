import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, forward, sample } from "effector";

export const $techniqueStore = createStore([]);
export const $techniquesCoords = createStore([]);

const setTechnique = createEvent();
export const fetchingTechnique = createEvent();

export const getTechniqueFx = createEffect(async () => {
  const res = await get(urls.techniqueList);
  return res;
});

export const editTechniqueFx = createEffect(async({ technic, company, section, user, ...data }) => {
  const res = await post({
    module: "technic",
    action: "edit",
    technic: technic || "",
    company, section, user,
    data: JSON.stringify(data)
  });

  return res;
});

export const deleteTechniqueFx = createEffect( async(id) => {
  const res = await get(urls.techniqueDelete, { technic: id });
  return res;
});

forward({
  from: getTechniqueFx.done,
  to: setTechnique
});

forward({
  from: editTechniqueFx.done,
  to: getTechniqueFx
});

forward({
  from: deleteTechniqueFx.done,
  to: getTechniqueFx
});

sample({
  source: $techniquesCoords,
  clock: $techniqueStore,
  fn: (_, store) => store.map(item => ({ ...item, data: JSON.parse(item?.data) })),
  target: $techniquesCoords
});

sample({
  source: $techniqueStore,
  clock: fetchingTechnique,
  filter: (store) => !store.length,
  target: getTechniqueFx
})

// Event Handlers
$techniqueStore.on(setTechnique, (_, { result }) => result.data.list || []);