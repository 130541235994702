import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, forward, sample } from "effector";

// Store
export const $culturesStore = createStore({
  list: []
});
export const $culturesList = createStore([]);
export const $culturesSettings = createStore([]);
export const $culturesFetched = createStore(false);

// Events
const setCultures = createEvent();
export const getCulturesList = createEvent();

// Effects
export const getCulturesFx = createEffect(async() => {
  const res = await get(urls.cultureList);
  return res;
});

export const getCultureByNameFx = createEffect(async(culture_name) => {
  const res = await get(urls.cultureGet, { culture_name });
  return res;
})

export const editCultureFx = createEffect(async({ name, ...data }) => {
  const svgRawImage = await data.image?.file.text();
  const res = await post({
    module: "culture",
    action: "edit",
    culture_name: name,
    data: JSON.stringify({ ...data, image: svgRawImage || undefined })
  });
  return res;
});

export const deleteCultureFx = createEffect(async(culture_name) => {
  const res = await get(urls.cultureDelete, { culture_name })
  return res;
});

// Forwards
forward({
  from: getCulturesFx.done,
  to: setCultures
})

// Refetch cultures
forward({
  from: editCultureFx.done,
  to: getCulturesFx
})
forward({
  from: deleteCultureFx.done,
  to: getCulturesFx
})

sample({
  source: sample({
    clock: getCulturesList,
    target: getCulturesFx
  }),
  clock: getCulturesFx.done,
  fn: (_, { result }) => result.data?.list,
  target: $culturesList,
})

sample({
  clock: getCulturesFx.done,
  fn: () => true,
  target: $culturesFetched
})

// Event handlers
$culturesStore
  .on(setCultures, (_, { result }) => ({ list: result.data.list || [] }));