import { notification } from "antd";
import { clearReport, createReport, generateSid, getBadgesFromTable, getGeneralTable, getTrack, wialonPost, wialonUrls } from "api/wialonApi";
import { createEffect, createEvent, createStore, forward, sample } from "effector";

export const $techniqueMonitoringStore = createStore({
  transportList: [],
  sid: null,
  loading: false,
  report: [],
  table: [],
  selectedTable: null,
  token: localStorage.getItem("wwt") ?? null,
});

const setTechniqueMonitoring = createEvent();
const setTechMonitoringSession = createEvent();
const setTechMonitoringTransportList = createEvent();
const setTechMonitoringTable = createEvent();
export const clearTechMonitoring = createEvent();
export const setTechMonitoringLoading = createEvent();
export const setTechMonitoringTechnique = createEvent();
export const setTechMonitoringSelectedTable = createEvent();
export const setTechMonitoringToken = createEvent();

export const getTechMonitoringTransportListFx = createEffect(async () => {
  await wialonPost(wialonUrls.cleanMonitoring);
  const vehicleList = await wialonPost("svc=core/update_data_flags", {
    spec: [{ type: "type", data: "avl_unit", flags: 4294967295, mode: 1 }],
  });
  return vehicleList;
});

export const getTechniqueMonitoringFx = createEffect(async (data) => {
  const store = $techniqueMonitoringStore.getState();
  const report = [];
  for (const techniqueId of data.techniques) {
    const technique = store.transportList.find(tech => tech.d?.id === techniqueId);
    try {
      await clearReport();
      const reportTable = await createReport(data.from, data.to, techniqueId);
      const badges = await getBadgesFromTable(reportTable,
        ['unit_thefts', 'unit_fillings', 'unit_stays', 'unit_digital_sensors']);
      const track = await getTrack(techniqueId);
      report.push({
        technique,
        badges,
        track,
      });

      if (data.techniques.length === 1) {
        setTechMonitoringTable(reportTable);
      }

    } catch (e) {
      notification.info({
        message: `Отчет по технике ${technique?.d?.nm} пустой`
      })
    }
  }

  if (data.techniques.length > 1) {
    const generalTable = await getGeneralTable(data.from, data.to, data.techniques);
    setTechMonitoringTable(generalTable);
  }

  setTechMonitoringLoading(false);
  return report;
});

export const getTechMonitoringSessionFx = createEffect(async (data) => {
  const res = await generateSid(data.token);  

  if (res.status !== 200 || "error" in res.data) {
    setTechMonitoringToken(null);
    return null;
  }
  return res.data.eid;
});

export const updateTechMonitoringSessionFx = createEffect(() => {
  wialonPost(wialonUrls.updateSession);
});

$techniqueMonitoringStore.reset(clearTechMonitoring);

forward({
  from: getTechniqueMonitoringFx.done,
  to: setTechniqueMonitoring,
});

sample({
  clock: setTechMonitoringToken,
  source: $techniqueMonitoringStore,
  filter: (store, ) => {
    if (!store.token) {
      return false;
    }
    return true;
  },
  target: getTechMonitoringSessionFx,
})

sample({
  clock: getTechMonitoringSessionFx.done,
  filter: ({result}) => !!result,
  target: [setTechMonitoringSession, getTechMonitoringTransportListFx]
})

sample({
  clock: getTechMonitoringTransportListFx.done,
  target: setTechMonitoringTransportList
})

$techniqueMonitoringStore.on(setTechniqueMonitoring, (state, { result }) => {
  return {
    ...state,
    report: result,
  }
});

$techniqueMonitoringStore.on(setTechMonitoringTable, (state, res) => {
  const tables = res.data?.reportResult?.tables;
  return {
    ...state,
    table: tables || [],
    selectedTable: state.selectedTable ? {
      ...state.selectedTable,
      table: tables[state.selectedTable.index]
    } : null,
  }
});

$techniqueMonitoringStore.on(setTechMonitoringToken, (state, token) => {
  return {
    ...state,
    token,
  }
});

$techniqueMonitoringStore.on(setTechMonitoringSession, (state, { result }) => {
  return {
    ...state,
    sid: result,
  };
});

$techniqueMonitoringStore.on(setTechMonitoringLoading, (state, loading) => {
  return {
    ...state,
    loading,
  };
});

$techniqueMonitoringStore.on(setTechMonitoringSelectedTable, (state, selectedTable) => {
  return {
    ...state,
    selectedTable,
  };
});

$techniqueMonitoringStore.on(setTechMonitoringTechnique, (state, technique) => {
  return {
    ...state,
    technique,
  };
});

$techniqueMonitoringStore.on(
  setTechMonitoringTransportList,
  (state, { result }) => {
    return {
      ...state,
      transportList: result.data,
    };
  }
);