import styles from '../index.module.scss'
import { Button, DatePicker, Divider, Form, Select, Tag } from 'antd';
import { $techniqueMonitoringStore, getTechniqueMonitoringFx, setTechMonitoringLoading, setTechMonitoringSelectedTable } from 'store/techniqueMonitoring';
import { useStore } from 'effector-react';
import { memo} from 'react';

export const Leftbar = memo(({ trackColors, isMultiply, showLeftbar, setShowLeftbar }) => {
    const {transportList, report,loading} = useStore($techniqueMonitoringStore)
    const [form] = Form.useForm();

    function onSubmit(data) {
        if (!isMultiply) {
            data.techniques = [data.techniques];
        }
        const utcDiff = new Date().getTimezoneOffset() / 60 + 3;
        data.from =
            data.from &&
            data.from
                .set("hours", 0)
                .set("minutes", 0)
                .set("seconds", 0)
                .set("milliseconds", 0)
                .unix();
        data.to =
            data.to &&
            data.to
                .set("days", data.to.get("days") + 1)
                .set("hours", 0)
                .set("minutes", 0)
                .set("seconds", 0)
                .set("milliseconds", -1)
                .unix();
        data.from = data.from - utcDiff * 60 * 60;
        data.to = data.to - utcDiff * 60 * 60;

        setTechMonitoringLoading(true);
        getTechniqueMonitoringFx(data);
    }

    return <div
        className={`${styles.leftbar} ${showLeftbar ? styles.showLeftbar : ""} `}
    >
        <div className={styles.pageHeader}>
            <h2 style={{ fontWeight: "bold", margin: 0, fontSize: "16px" }}>
                {isMultiply ? "Мониторинг техники (групповой)" : "Мониторинг техники"}
            </h2>
            <Button onClick={() => setShowLeftbar(false)}>Скрыть</Button>
        </div>
        <Divider />
        <Form layout={"vertical"} form={form} onFinish={onSubmit}>
            <Form.Item
                label={"Техника:"}
                name={"techniques"}
                required={false}
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Выберите технику" }]}
            >
                {
                    isMultiply
                        ? <Select
                            style={{ width: "100%" }}
                            mode="multiple"
                            tagRender={({ label, value, closable, onClose }) => {
                                const onPreventMouseDown = (event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                };
                                const index = report.findIndex(report => report?.technique?.d?.id === value);
                                return <Tag
                                    style={{ border: `3px solid ${trackColors[index]}`, margin: "3px" }}
                                    onMouseDown={onPreventMouseDown}
                                    closable={closable}
                                    onClose={onClose}
                                >
                                    {label}
                                </Tag>
                            }}
                            options={transportList.map((transport) => {
                                return { value: transport.d.id, label: transport.d.nm };
                            })}
                        /> 
                        : <Select
                            style={{ width: "100%" }}
                            options={transportList.map((transport) => {
                                return { value: transport.d.id, label: transport.d.nm };
                            })}
                        />
                }
            </Form.Item>
            <Form.Item
                label={"от:"}
                name={"from"}
                required={false}
                rules={[{ required: true, message: "Выберите дату" }]}
                style={{ marginBottom: 10 }}
            >
                <DatePicker style={{ width: "100%" }} format={"DD.MM.YYYY "} />
            </Form.Item>
            <Form.Item
                label={"до: "}
                name={"to"}
                required={false}
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: "Выберите дату" }]}
            >
                <DatePicker style={{ width: "100%" }} format={"DD.MM.YYYY"} />
            </Form.Item>
            <div style={{ display: "flex", gap: "5px" }}>
                <Button onClick={() => form.resetFields()}>Очистить</Button>
                <Button
                    loading={loading}
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    Сформировать отчет
                </Button>
            </div>
        </Form>
        <Divider />
        <TableButtons />
    </div>
});

const TableButtons = memo(() => {
    const techMonitoring = useStore($techniqueMonitoringStore);

    if (!techMonitoring.table) {
        return null;
    }

    return <div className={styles.selectTableButtons}>
    {techMonitoring.table.map((el, index) => {
        return <Button 
            key={index}
            size='small' 
            className={styles.selectTableButton}
            type={index === techMonitoring.selectedTable?.index ? "primary" : "default"}
            onClick={async () => {
                setTechMonitoringSelectedTable({index, table: el})
            }}
        >
            {el.label}
        </Button>
    })}
</div>
});