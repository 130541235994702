import React, {useEffect} from 'react';
import st from "../index.module.scss";
import {Controller, useForm} from "react-hook-form";
import { Button, Input, Alert } from "antd";
import { sendActivationFx } from "../../../store/user";

const ProfileMailCodeModal = ({
  setCodeModal,
  codeModal,
  newMail,
  notificationsApi
}) => {
  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = async (props, ev) => {
    try {
      const { code } = props;

      const { data } = await sendActivationFx({ mail: newMail, code });
      const error = (data.error_message || data.error_msg)

      if(data.doc){
        setCodeModal(false);
        notificationsApi.success({ message: 'Код подтвержден', duration: 5 });
      } else {
        notificationsApi.error({ message: error, duration: 5 });
      }
    } catch(e) {
      notificationsApi.error({ message: "Не удалось проверить код подтверждения", duration: 5 });
    }
  }

  useEffect(() => {
    reset()
  }, [codeModal])

  return (
    <>
      <div className={st.codeItemTitle}>
        <Alert
          type="success"
          message="На почту был отправлен код для подтверждения смены почты"
        />
      </div>
      <form
        className={st.codeForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={st.modalItem}>
          <Controller
            name="code"
            control={control}
            render={({ field }) =>
              <div>
                <div className={st.inputError}>
                  {errors["code"] && errors["code"].message}
                </div>
                <Input
                  {...field}
                  placeholder="Введите код"
                  className={st.regInputMail}
                  status={errors["code"] ? 'error' : ''}
                />
              </div>
            }
          />
        </div>
        <div className={st.modalFooter}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 0, marginTop: 18, marginLeft: 'auto' }}
          >
            Подтвердить
          </Button>
        </div>
      </form>
    </>
  );
};

export default ProfileMailCodeModal;