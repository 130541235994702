import { Form, Input, Select, Button } from "antd";
import { useEffect } from "react";
import { useStore } from "effector-react";
import { $usersGroupsStore, getUsersGroupsFx } from "store/userGroups";
import { DatePicker } from "antd/es";
import dayjs from "dayjs";

const ChangeUsersForm = ({onSubmit, submitTitle, onCancel, user}) => {
    const { name, surname, patronymic, phone, group, passport_series, passport_number, snils, inn, birthday } = user;
    // Group list
    const groupList = useStore($usersGroupsStore);

    // Form init
    const [form] = Form.useForm();

    useEffect(() => {
        getUsersGroupsFx();
        form.setFieldsValue({
            name, surname, patronymic, phone, group, passport_series, passport_number, snils, inn, 
            birthday: birthday ? dayjs(birthday, "YYYY-MM-DD"): null,
        });
    }, [form, name, surname, patronymic, phone, group, passport_series, passport_number, snils, inn, birthday ]);

    return (
        <Form
            layout="vertical"
            onFinish={onSubmit}
            form={form}
        >
            <Form.Item
                label="Фамилия"
                name="surname"
                required={false}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Имя"
                name="name"
                required={false}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Отчество"
                name="patronymic"
                required={false}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Номер телефона"
                name="phone"
                required={false}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Дата рождения"
                name="birthday"
                initialValue={birthday ? dayjs(birthday, "YYYY-MM-DD") : null}
            >
                <DatePicker format={"DD.MM.YYYY"} />
            </Form.Item>

            <Form.Item name="passport" label="Паспорт сотрудника" style={{marginBottom: 0}}>
                <Form.Item
                    name="passport_series"
                    initialValue={passport_series}
                    style={{display: "inline-block", width: "calc(30% - 8px)", marginRight: 8}}
                >
                    <Input placeholder="Серия" />
                </Form.Item>
                <Form.Item
                    name="passport_number"
                    initialValue={passport_number}
                    style={{display: "inline-block", width: "70%"}}
                >
                    <Input placeholder="Номер" />
                </Form.Item>
            </Form.Item>

            <Form.Item style={{marginBottom: 0}}>
            <Form.Item
                label="ИНН"
                name="inn"
                initialValue={inn}
                style={{display: "inline-block", width: "48%", marginRight: "4%"}}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="СНИЛС"
                name="snils"
                initialValue={snils}
                style={{display: "inline-block", width: "48%"}}
            >
                <Input />
            </Form.Item>
            </Form.Item>

            <Form.Item
                label="Группа пользователей"
                name="group"
                required={false}
                rules={[{required: true, message: 'Выберете группу пользователей'}]}
            >
                <Select
                    options={groupList?.map(({title, id}) => ({label: title, value: id})) || []}
                />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default ChangeUsersForm;