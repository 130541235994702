import React, { useEffect, useState} from 'react';
import { useStore } from "effector-react";
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Divider,
  Table,
  Dropdown,
  notification,
} from "antd";
import { IoEllipsisHorizontal } from 'react-icons/io5'
import { $userStore, $viewAsAdminUser, viewAdminAsUserFx } from "../../store/user";
import {
  getUserListFx,
  sendForgotPasswordFx,
  blockUserFx,
  unblockUserFx,
  $userList,
} from "store/adminUsers";

import NewUserModal from './NewUserModal';
import ChangeUsersModal from "./ChangeUsersModal";

import st from './index.module.scss';
import { routes } from 'routes';
import { usePermission } from 'hooks/usePermission';
import FilterForm from './FilterForm';
import useColumnSearch from './searchHook';
import dayjs from 'dayjs';

const roleGroups = {
  admin: 1,
  users: 5
}

const User = () => {
  const { isFetchedOk, viewAsUserMode, authData, user } = useStore($userStore);
  const { user: adminUser } = useStore($viewAsAdminUser);
  const userList = useStore($userList).sort((user) => user.blocked ? 1 : -1);
  const canView = usePermission("user", "view");
  const canCreate = usePermission("user", "create");
  const canEdit = usePermission("user", "edit");
  const canViewWorkers = usePermission("worker");

  const [newUserIsOpen, setNewUserIsOpen] = useState(false);
  const [changeUserIsOpen, setChangeUserIsOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null)
  const [notificationApi, contextHolder] = notification.useNotification();

  const [filters, setFilters] = useState({});
  const getSearchProps = useColumnSearch();

  const navigate = useNavigate();

  const findSelectedUser = (id) => {
    return userList.find(user => user.id == id)
  }

  const blockSelectedUser = async () => {
    try {
      const {data: {status}} = await blockUserFx(selectedUser.id);
      if (status !== "ok") {
        notificationApi.error({message: "Не удалось заблокировать пользователя", duration: 5});
      }
      notificationApi.success({message: "Пользователь успешно заблокирован", duration: 5});
    } catch(e) {
      notificationApi.error({message: "Не удалось заблокировать пользователя", duration: 5});
    }
  }

  const unblockSelectedUser = async () => {
    try {
      const {data: {status}} = await unblockUserFx(selectedUser.id);
      if (status !== "ok") {
        notificationApi.error({message: "Не удалось разблокировать пользователя", duration: 5});
      }
      notificationApi.success({message: "Пользователь успешно разблокирован", duration: 5});
    } catch(e) {
      notificationApi.error({message: "Не удалось разблокировать пользователя", duration: 5});
    }
  }

  const sendForgotPassword = async () => {
    try {
      const { data } = await sendForgotPasswordFx({ mail: selectedUser.mail });
      if(data.error_msg){
        notificationApi.error({ message: data.error_message, duration: 5 });
      }else{
        notificationApi.success({ message: `Запрос на восстановление пароля ${selectedUser.mail} успешно отправлен`, duration: 5 });
      }
    } catch(e) {
      notificationApi.error({message: "Не удалось отправить запрос на восстановление пароля", duration: 5});
    }
  }

  useEffect(() => {
    isFetchedOk && getUserListFx()
  }, [isFetchedOk, viewAsUserMode]);

  const dropdowItems = [
    {
      key: 'editUserProfile',
      label: (
        <div
          className={st.dropdownItem}
          onClick={() => {
            setChangeUserIsOpen(!changeUserIsOpen)
          }}
        >
          Редактировать страницу пользователя
        </div>
      ),
    },
    {
      key: 'loginAsUser',
      label: (
        <div
          className={st.dropdownItem}
          onClick={() => {
            viewAdminAsUserFx(selectedUser.id)
            window.scrollTo(0, 0)
          }}
        >
          Войти как пользователь
        </div>
      ),
    },
    {
      key: 'regenerateUserPassword',
      label: (
        <div
          className={st.dropdownItem}
          onClick={sendForgotPassword}
        >
          Восстановить пароль пользователю
        </div>
      ),
    },
    {
      key: 'userRoles',
      label: (
        <div
          className='dropdownItem'
          onClick={() => navigate(
            routes.userRoles.replace(":id", selectedUser.id),
            {state: selectedUser.name || selectedUser.mail}
          )}
        >
          Роли пользователя
        </div>
      )
    },
    {
      key: 'blockUser',
      label: (
        <div
          className="dropdownItem"
          onClick={blockSelectedUser}
        >
          Заблокировать пользователя
        </div>
      )
    },
    {
      key: 'unblockUser',
      label: (
        <div
          className="dropdownItem"
          onClick={unblockSelectedUser}
        >
          Разблокировать пользователя
        </div>
      )
    },
    {
      key: 'userWorker',
      label: (
        <div
          className="dropdownItem"
          onClick={() => navigate(routes.workPlace.replace(":id", selectedUser.id))}
        >
          Место работы
        </div>
      )
    }
  ]

  const columns = [
    {
      title: 'ФИО/Логин',
      dataIndex: '',
      key: "nameSurname",
      render: (row) => {
        return row.name ? `${row.name} ${row.surname}` : row.login
      },
      fixed: 'left',
      sorter: (a, b) => {
        const row1 = a.name ? `${a.name} ${a.surname}` : a.login;
        const row2 = b.name ? `${b.name} ${b.surname}` : b.login;
        return row1.localeCompare(row2);
      },
      ...getSearchProps('name'),
    },
    {
      title: 'Email',
      dataIndex: 'mail',
      key: 'mail',
      sorter: (a, b) => a.mail?.localeCompare(b.mail),
      ...getSearchProps('mail'),
    },
    {
      title: 'Создан',
      sorter: (a, b) => {
        const format = 'YYYY-MM-DD hh:mm:ss';
        return dayjs(a.created, format) - dayjs(b.created, format);
      },
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Место работы',
      dataIndex: 'worker',
      key: 'worker',
      render: (worker) => {
        const res = worker.map(item =>
          <>
            <div>{item.company.name}/{item.section.title}</div>
          </>
        )
        return res.length === 0 ? "-" : res;
      }
    },
    {
      title: '',
      dataIndex: '',
      render: (row) => {
        const { blocked, activated, id } = row;

        const menuItems = dropdowItems.filter(item => {
          if(viewAsUserMode && adminUser.id === id && item.key === 'loginAsUser'){
            return false;
          }
          if(authData.uid === id && item.key === 'loginAsUser'){
            return false;
          }
          if((blocked || !activated) && item.key === 'loginAsUser'){
            return false;
          }
          if ((blocked || authData.uid === id) && item.key === "blockUser"){
            return false;
          }
          if ((!blocked || authData.uid === id) && item.key === "unblockUser"){
            return false;
          }

          // Permission check
          if (item.key === "editUserProfile" && !canEdit)
            return false;
          if (item.key === "loginAsUser" && !canView)
            return false;
          if (item.key === "regenerateUserPassword" && !canEdit)
            return false;
          if (item.key === "userRoles" && !canEdit)
            return false;
          if (item.key === "blockUser" && !canEdit)
            return false;
          if (item.key === "unblockUser" && !canEdit)
            return false;
          if (item.key === "userWorker" && !canViewWorkers)
            return false;

          return true;
        })
        
        if (!canEdit)
          return false;

        return (
          <Dropdown
            menu={{ items: menuItems }}
            trigger="click"
            onOpenChange={() => {
              setSelectedUser(findSelectedUser(row.id))
            }}
          >
            <Button>
              <div className={st.profileAvatar}>
                <IoEllipsisHorizontal height={30} width={30} />
              </div>
            </Button>
          </Dropdown>
        )
      }
    }
  ]

  if((viewAsUserMode && adminUser.group == roleGroups.admin) || user.group == roleGroups.admin){
    columns.splice(3, 0,  {
      title: 'Статус',
      dataIndex: '',
      key: '',
      onFilter: (value, record) => {
        for (const [filterKey, filterValues] of Object.entries(filters)) {
          if (!filterValues.includes(record[filterKey]) && filterValues.length !== 0) {
            return false;
          }
        }

        return true;
      },

      defaultFilteredValue: [''],

      render: (row) => {
        const { activated, blocked } = row;
        let str = 'не активирован';

        if(blocked){
          str = 'заблокирован'
        }else if(activated){
          str = 'активен'
        }

        return str;
      }
    })
  }

  return (
    <div>
      <div className={st.header}>
        <div className={st.pageTitle}>
          Пользователи
        </div>
        { canCreate &&
          <Button
            className={st.headerNewUser}
            onClick={() => setNewUserIsOpen(true)}
          >
            Зарегистрировать нового пользователя
          </Button>
        }
      </div>
      <Divider />
      <FilterForm setFilterForm={setFilters} />
      <Table
        dataSource={userList}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 100, userList.length],
        }}
        scroll={{ x: true }}
      />
      <NewUserModal
        newUserIsOpen={newUserIsOpen}
        setNewUserIsOpen={setNewUserIsOpen}
        notificationApi={notificationApi}
      />
      {selectedUser &&
        <ChangeUsersModal
          changeUserIsOpen={changeUserIsOpen}
          setChangeUserIsOpen={setChangeUserIsOpen}
          selectedUser={selectedUser}
          notificationApi={notificationApi}
        />
      }
      {contextHolder}
    </div>
  );
};

export default User;