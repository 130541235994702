import { Button, DatePicker, Form, Modal } from 'antd';
import { ModalTitleForHelp } from 'components/ModalTitleForHelp';
import dayjs from 'dayjs';
import helpMessageRoutes from 'helpMessageRoutes';
import { useEffect } from 'react';

const QuitEmployeeModal = ({activeItem, onCancel, onSubmit, open, ...props}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [open])

    return <Modal
            title={<ModalTitleForHelp title="Увольнение сотрудника" helpFile={helpMessageRoutes.sections.staffQuit}/>}
            footer={null}
            destroyOnClose
            onCancel={onCancel}
            open={open}
            {...props}
        >
            <Form
            autoComplete={false}
            requiredMark={false}
            layout="vertical"
            onFinish={(user) => {
                onCancel();
                onSubmit({
                    date_quit: user.date_quit ? dayjs(user.date_quit).format('YYYY-DD-MM') : null, 
                    id: activeItem.workerId})
            }}
            form={form}
        >
            <Form.Item
                label={'Дата увольнение'}
                name={'date_quit'}
                initialValue={null}
                style={{display: 'inline-block',  marginTop: 0, width: '44%'}}
            >
                <DatePicker format={'DD.MM.YYYY'} style={{width: '100%'}} />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                        <Button type="primary" htmlType="submit">Уволить сотрудника</Button>
                </div>
            </Form.Item>
        </Form>
    </Modal>
}

export default QuitEmployeeModal;