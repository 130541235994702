export const dataToLeafletFormat = (polygons, isGeoJSON) => {
  if(polygons.length === 0){
    return []
  }

  // const splitFromMap = polygons.split(",");
  const data = [];

  for(let i = 0; i < polygons.length; i++){
    (i % 2) && data.push(isGeoJSON ?
      [parseFloat(polygons[i - 1]), parseFloat(polygons[i])] : //lnglat
      [parseFloat(polygons[i]), parseFloat(polygons[i - 1])] //latlng
    )
  }

  return data.filter(item => !isNaN(item[0]));
}

export const leafletFormatToData = (polygons) => {
  let strPolygons = "";

  polygons.forEach(({ lat, lng }, ix) => {
    strPolygons += `${lng},${lat}`;
    if(polygons.length - 1 > ix){
      strPolygons += ",";
    }
  })

  return strPolygons
}

export const getCenter = (arr, isGeoJSON) => {
  const x = arr.map (function (a){ return a[0] });
  const y = arr.map (function (a){ return a[1] });
  const minX = Math.min.apply (null, x);
  const maxX = Math.max.apply (null, x);
  const minY = Math.min.apply (null, y);
  const maxY = Math.max.apply (null, y);
  return isGeoJSON ? [(minY + maxY) / 2, (minX + maxX) / 2] : [(minX + maxX) / 2, (minY + maxY) / 2];
}