import React from 'react';
import { Button, Modal } from "antd";
import { Form } from 'components/Form';
import { useForm } from "react-hook-form";

import { editOrCreateTechOperationGroupsFx } from "store/operationsGroup";
import helpMessageRoutes from 'helpMessageRoutes';

import { ModalTitleForHelp } from 'components/ModalTitleForHelp';

import st from "../style.module.scss";

const items = [
  {
    label: 'Название группы',
    name: 'title',
    rules: { required: 'Не указано название группы' }
  },
  {
    label: 'Описание группы',
    name: 'description',
    type: 'textarea',
    rules: { required: 'Не указана описание группы' }
  },
]

export const CreateModal = ({
  item,
  notificationsApi,
  onClose
}) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      title: item ? item.title : "",
      description: item ? item.description : "",
    }
  });

  const onSubmit = async (props) => {
    const { title, description } = props;

    const { data } = await editOrCreateTechOperationGroupsFx({
      description,
      title,
      techOperation: item
    });

    if(data.error_message) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    } else {
      notificationsApi.success({
        message: item ? 'Технологическая группа успешно изменена' : 'Технологическая группа успешно добавлена',
        duration: 5
      });

      onClose();
    }
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={item ? "Редактирование технологической группы" : "Создание технологической группы"}
          helpFile={helpMessageRoutes.techOperationsGroups[item ? "edit" : "create"]}
        />
      }
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <form
        className={st.modalForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        {items.map((props) => (
          props.type == 'textarea' ? (
            <Form.TextArea
              input={props}
              form={{ control, formState }}
              rows={4}
            />
          ): (
            <Form.Input
              input={props}
              form={{ control, formState }}
            />
          )
        ))}
        <div className={st.modalFooter}>
          <Button onClick={onClose}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  );
};