import React, { useEffect, useMemo, useState } from 'react';
import { useStore, useUnit } from 'effector-react';
import { getStats, $cultureStats, $tableStats } from "store/cultureRotationStats";
import st from "./style.module.scss";
import { Button, Divider, Table, Select } from "antd";
import { Chart } from "react-google-charts";
import { $culturesList, getCulturesList, $culturesFetched } from "../../store/cultures";
import { $userStore } from 'store/user';

const CultureRotationStats = (props) => {
  const {viewAsUserMode} = useStore($userStore)
  const [cultureStats, tableStats, cultures, culturesFetched] =
    useUnit([$cultureStats, $tableStats, $culturesList, $culturesFetched]);
  const [year, setYear] = useState(2024);
  const [options, setOptions] = useState({
    title: "Структура культур",
    tooltip: {
      text: 'percentage'
    }
  });

  useEffect(() => {
    getStats(year);
    getCulturesList();
  }, [viewAsUserMode]);

  useEffect(() => {
    if(culturesFetched && Object.entries(cultureStats).length > 0){
      const colors = statsFormat.map(item => {
        return cultures.find(culture => culture.name == item[0].slice(0, item[0].lastIndexOf(' ')))?.color ?? 'black'
      })
      setOptions(prev => ({ ...prev, colors }))
    }
  }, [culturesFetched, cultureStats])

  const statsFormat = Object.entries(cultureStats)
    .filter(([name]) => name != 'all')
    .map(([name, data]) => ([
      `${name} ${data?.percent}%`,
      +data?.percent,
    ]));

  const chartData = [["Cultures", "Area"], ...statsFormat];

  const onChange = (year) => {
    setYear(year)
  }

  return (
    <div>
      <div className={st.headerWrapper}>
        <h1 className={st.pageHeading}>
          Статистика культур на {year} год
        </h1>
        <div>
          <Select
            options={[
              { value: '2024', label: '2024' },
              { value: '2023', label: '2023' },
            ]}
            defaultValue={2024}
            onChange={onChange}
          />
          <Button
            onClick={() => getStats(year)}
          >
            Сформировать отчет
          </Button>
        </div>
      </div>
      <Divider/>

      <div className={st.content}>
        <div className={st.left} style={{ width: statsFormat.length > 0 ? '50%' : '100%' }}>
          <Table
            columns={[
              {
                title: "Имя культуры",
                dataIndex: "name",
              },
              {
                title: 'Процент',
                dataIndex: 'percent',
              },
              {
                title: 'Площадь',
                dataIndex: 'area',
              },
            ]}
            dataSource={tableStats}
            locale={{ emptyText: `Данных за ${year} год не найдено` }}
          />
        </div>
        {statsFormat.length > 0 && (
          <div className={st.right}>
            <Chart
              chartType="PieChart"
              data={chartData}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CultureRotationStats;