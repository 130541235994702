import { useMemo } from "react";
import { Polyline } from "react-leaflet";

export function TrackLines({ track, technique, handleTrackClick, color }) {
    const PolylineTracks = useMemo(() => {
        const pathOptions = {
            color: color,
            weight: 5,
        }
    
        return track.map((trackItem, index) => {
            return <Polyline
            key={index}
            pathOptions={pathOptions}
            positions={trackItem.map(el => [el.pos.y, el.pos.x])}
            eventHandlers={{
                click: (e) => {
                    handleTrackClick(e.latlng, trackItem, e.containerPoint, technique);
                }
            }}
        />
        });
    }, [track, handleTrackClick, technique, color]);

    return PolylineTracks;
}