import {post} from "api";
import { createStore, createEffect, createEvent, sample } from "effector";

export const $cultureStats = createStore([]);
export const $tableStats = createStore([]);

export const getStatsFx = createEffect();

export const getStats = createEvent();


getStatsFx.use(async (year) => {
  const res = await post({
    module: 'culture_rotation',
    action: 'stat_culture',
    year: year
  });

  return res;
})

sample({
  source: sample({
    clock: getStats,
    target: getStatsFx
  }),
  clock: getStatsFx.done,
  fn: (_, { result }) => result.data || [],
  target: $cultureStats
})

sample({
  clock: getStatsFx.done,
  fn: (_, { result }) => Object.entries(result.data)
    .map(([name, value]) => name != 'all' ? { name, ...value } : null)
    .filter(Boolean),
  target: $tableStats
})