import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, sample } from "effector";

export const $materials = createStore([]);

export const getMaterialsFx = createEffect();
export const deleteMaterialFx = createEffect();
export const editOrCreateMaterialFx = createEffect();

export const getMaterials = createEvent();

getMaterialsFx.use(async () => {
  const res = await get(urls.materialList);
  return res;
});

editOrCreateMaterialFx.use(async (props) => {
  const { title, description, material_group, material } = props;

  const postData = {
    module: 'material',
    action: 'edit',
    data: JSON.stringify({ title, description, material_group }),
  }

  if(material?.id){
    postData['id'] = material.id;
  }

  const res = await post(postData);

  return res;
})

deleteMaterialFx.use(async (materialId) => {
  const res = await get(urls.materialDelete, { id: materialId });
  return res;
})

sample({
  source: sample({
    clock: getMaterials,
    target: getMaterialsFx
  }),
  clock: getMaterialsFx.done,
  fn: (_, { result }) => result.data?.list || [],
  target: $materials
})

sample({
  clock: [deleteMaterialFx.done, editOrCreateMaterialFx.done],
  target: getMaterials
})