import { createStore, createEffect, createEvent, forward } from "effector";
import { get, urls } from "api";

// Store
export const $workersStore = createStore([]);

// Events
const setWorkers = createEvent();

// Effects
export const getWorkersFx = createEffect(async (data) => {
    const res = await get(urls.workersList, {user: typeof data === "object" ? data.result : data});
    return res;
});

export const editWorkerFx = createEffect(async (data) => {
    const res = await get(urls.workersEdit, data);
    return res;
})

export const deleteWorkerFx = createEffect(async ({company, section, user}) => {
    const res = await get(urls.workersDelete, {
        company: company.id,
        section: section.id,
        user: user.id
    });
    return res;
})

// Forwards
forward({
    from: getWorkersFx.done,
    to: setWorkers
});

// Event handlers
$workersStore.on(setWorkers, (_, {result}) => result.data.list || []);
