import { Modal, Table } from "antd";
import { useLocation } from "react-router-dom";
import { pathDocs } from "./ModalDocForAdmin";

const columns = [
    {
        title: "Роль",
        dataIndex: "roleName",
        key: "roleName",
        width: "20%"
    },
    {
        title: "Модификатор",
        dataIndex: "accessModifier",
        key: "accessModifier",
        width: "10%"
    },
    {
        title: "Описание",
        dataIndex: "descr",
        key: "descr"
    }
]

const PermissionModal = (props) => {
    const {pathname} = useLocation();

    return (
        <Modal
            width={"100%"}
            {...props}
            footer={null}
        >
            <Table
                columns={columns}
                dataSource={pathDocs[pathname.replace(/\d+/g, "*")]}
                pagination={false}
            />

        </Modal>
    );
}

export default PermissionModal;