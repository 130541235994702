import { Button } from "antd";
import { usePermission } from "hooks/usePermission";
import styles from "./index.module.scss";

export const PageHeader = ({onClick}) => {
  const canCreate = usePermission("culture", "create");

  return (
    <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>Культуры</h2>
        { canCreate &&
          <Button
            onClick={onClick}
          >
            Добавить культуру
          </Button>
        }
    </div>
  )
}

