import helpMessageRoutes from "helpMessageRoutes";
import { routes } from "routes";

const pathModuleMapping = {
    [routes.users]: helpMessageRoutes.user.list,
    [routes.companies]: helpMessageRoutes.company.list,
    [routes.companiesStats]: helpMessageRoutes.companiesStats.list,
    [routes.profile]: helpMessageRoutes.user.profile,
    [routes.login]: helpMessageRoutes.auth.login,
    [routes.registration]: helpMessageRoutes.auth.reg,
    [routes.forgot]: helpMessageRoutes.auth.forgot,
    [routes.activation]: helpMessageRoutes.auth.activate,
    [routes.roles]: helpMessageRoutes.role.list,
    [routes.userRoles]: helpMessageRoutes.user.editRole,
    [routes.fields]: helpMessageRoutes.fields.list,
    [routes.fieldMap]: helpMessageRoutes.fields.showOnMap,
    [routes.cultures]: helpMessageRoutes.cultures.list,
    [routes.section]: helpMessageRoutes.sections.list,
    [routes.staff]: helpMessageRoutes.sections.staffList,
    [routes.storage]: helpMessageRoutes.storage.list,
    [routes.usersGroups]: helpMessageRoutes.userGroups.list,
    [routes.groupRoles]: helpMessageRoutes.userGroups.editRole,
    [routes.weighing]: helpMessageRoutes.weighing.list,
    [routes.technique]: helpMessageRoutes.technique.list,
    [routes.techniqueMonitoring]: helpMessageRoutes.techniqueMonitoring.list,
    [routes.techniqueMonitoringGroup]: helpMessageRoutes.techniqueMonitoringGroup.list,
    [routes.workPlace]: helpMessageRoutes.worker.list,
    [routes.techOperations]: helpMessageRoutes.techOperations.list,
    [routes.techOperationsGroups]: helpMessageRoutes.techOperationsGroups.list,
    [routes.material]: helpMessageRoutes.material.list,
    [routes.materialGroups]: helpMessageRoutes.materialGroups.list,
    [routes.cultureRotation]: helpMessageRoutes.culturesRotation.list,
    [routes.fieldProductivity]: helpMessageRoutes.fieldProductivity.stats,
    [routes.storageCopacity]: helpMessageRoutes.storageCopacity.stats,
    [routes.cultureRotationStats]: helpMessageRoutes.culturesRotationStats.list,
}

export const getHelpByPath = (pathname) => pathModuleMapping[pathname.replace(/(\/[0-9]*\/)/, "/:id/")];