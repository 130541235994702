import { createStore, createEvent, createEffect, sample, guard } from "effector";
import { urls, get, post } from '../api';

export const $companiesStore = createStore({
  list: []
});
export const $companiesFetched = createStore(false);

const changeCompanies = createEvent();
const editCompany = createEvent();
const addCompany = createEvent();
const getCompaniesDone = createEvent()

export const getCompanies = createEffect(async () => {
  const res = await get(urls.enterprisesList);

  return res;
});

export const unblockCompanyByIdFx = createEffect(async (id) => {
  const res = await get(urls.enterprisesUnblockById, {company_id: id});
  return res;
})

export const blockCompanyByIdFx = createEffect(async (id) => {
  const res = await get(urls.enterprisesBlockById, {company_id: id});
  return res;
})

export const createOrEditCompanyFx = createEffect(async (props) => {
  const { id } = props;

  const copyProps = JSON.parse(JSON.stringify(props));
  delete copyProps.id;

  const postData = {
    module: 'company',
    action: 'edit',
    data: JSON.stringify(copyProps)
  }
  id && (postData['company_id'] = id)

  const res = await post(postData)
  return res;
})

getCompanies.done.watch(({ params, result }) => {
  changeCompanies(result.data.list)
});

sample({
  clock: getCompanies.done,
  target: changeCompanies
})

sample({
  clock: getCompanies.done,
  fn: () => true,
  target: $companiesFetched
})

createOrEditCompanyFx.done.watch(({ params, result }) => {
  params.id ? editCompany(params) : addCompany({ params, id: result.data.id })
})

unblockCompanyByIdFx.done.watch((res) => {
  if (res.result.data.status === "ok"){
    getCompanies();
  }
})

blockCompanyByIdFx.done.watch((res) => {
  if (res.result.data.status === "ok"){
    getCompanies();
  }
})

$companiesStore
  .on(changeCompanies, (state, data) => ({
    ...state,
    list: data
  }))
  .on(editCompany, (state, data) => {
    const newList = state.list.map((item) => {
      if(data.id === item.id){
        return { ...item, ...data };
      }
      return item
    })

    return {
      ...state,
      list: newList
    }
  })
  .on(addCompany, (state, data) => {
    const newList = state.list.concat({ ...data.params, id: data.id });

    return {
      ...state,
      list: newList
    }
  })


