import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Divider } from "antd";
import { useStore } from 'effector-react';
import TableDropdown from "components/TableDropdown";
import { CreateModal } from './modals';
import { usePermission } from "hooks/usePermission";

import styles from "../Fields/index.module.scss";
import { $materials, deleteMaterialFx, getMaterialsFx } from 'store/material';
import { getMaterialGroupsFx } from 'store/materialGroup';
import { $userStore } from 'store/user';

const Material = () => {
  const materials = useStore($materials);
  const {viewAsUserMode} = useStore($userStore);
  const rows = Object.groupBy(materials, ({ material_group }) => material_group.id);
  const rowsInit = {}

  const sortMaterials = [];
  Object.entries(rows).forEach(([, value]) => {
    value.forEach(item => sortMaterials.push(item));
  })

  const canEdit = usePermission("material", "edit");
  const canDelete = usePermission("material", "delete");
  const canCreate = usePermission("material", "create");

  const [createModalFlag, setCreateModalFlag] = useState();
  const [editId, setEditId] = useState();
  const [notificationsApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    getMaterialsFx();
    getMaterialGroupsFx();
  }, [viewAsUserMode]);

  const deleteMaterial = async (materialId) => {
    const { data } = await deleteMaterialFx(materialId);

    if(data.error_message) {
      notificationsApi.error({
        message: data.error_message,
        duration: 5
      });
    } else {
      notificationsApi.success({
        message: 'Материал успешно удален',
        duration: 5
      });
    }
  }

  const dropdownItems = []

  canEdit && dropdownItems.push({
    label: "Редактировать",
    onClick: (item) => {
      setEditId(item.id);
      setCreateModalFlag(true);
    }
  })

  canDelete && dropdownItems.push({
    label: "Удалить",
    onClick: (item) => deleteMaterial(item.id)
  });

  return (
    <div>
      <div className={styles.headerWrapper}>
        <h1 className={styles.pageHeading}>
          Материалы
        </h1>
        <div>
          {canCreate && (
            <Button
              onClick={() => {
                setCreateModalFlag(true);
                setEditId(false);
              }}
            >
              Создать
            </Button>
          )}
        </div>
      </div>

      <Divider/>

      <Table
        columns={[
          {
            title: 'Группа',
            dataIndex: ['material_group', 'title'],
            onCell: (row) => {
              const rowGroupId = row?.material_group?.id;

              if(!rowGroupId){
                return {}
              }

              const length = rows[rowGroupId].length;
              const rowSpan = rowsInit[rowGroupId] ? 0 : length;
              rowsInit[rowGroupId] = true;

              return { rowSpan }
            },
          },
          {
            title: "Название",
            dataIndex: "title",
            width: '30%'
          },
          {
            title: 'Описание материала',
            dataIndex: 'description',
          },
          {
            title: '',
            render: (row) => {
              return {
                props: {
                  style: { textAlign: 'right' }
                },
                children: (
                  <TableDropdown
                    items={dropdownItems}
                    row={row}
                  />
                )
              }
            }
          }
        ]}
        dataSource={sortMaterials}
      />

      {createModalFlag && (
        <CreateModal
          onClose={() => setCreateModalFlag(false)}
          item={editId && materials.find(item => item.id == editId)}
          notificationsApi={notificationsApi}
        />
      )}

      {contextHolder}
    </div>
  )
};

export default Material;