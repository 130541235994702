import React from 'react';
import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Alert, notification } from "antd";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';

import { useStore } from "effector-react";
import { $userStore, sendActivationFx } from "../../../store/user";

import {get, urls} from "../../../api";

import st from "./index.module.scss";

const Activation = () => {
  const $store = useStore($userStore);
  const query = queryString.parse(window.location.search);
  const navigate = useNavigate();

  const queryCode = query.code || "";
  const queryMail = query.mail ? query.mail : $store.mail;

  const { control, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      code: queryCode
    }
  });

  const [showAlert, setShowAlert] = useState([false, "ok"]); // Alert state [visibililty, status]

  const onSubmit = async (props) => {
    try {
      const { code } = props;

      const { data } = await sendActivationFx({ mail: queryMail, code });

      if(data.error_message){
        setError('code', { type: "custom", message: data.error_message });
      }else{
        navigate('/login');
      }
    } catch(e) {
      notification.error({message: "Не удалось подключиться к серверу. Повторите попытку позже"});
    }
  }

  const resendActivationCode = async () => {
    try {
      const res = await get(urls.resendActivation, {mail: $store.mail});
      if (res.status === 200)
        setShowAlert([true, "ok"]);
      else
        setShowAlert([true, "bad"]);
    } catch(e) {
      notification.error({message: "Не удалось подключиться к серверу. Повторите попытку позже"});
    }
  }

  return (
    <div className={st.content}>
      <form
        className={st.regButtons}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="code"
          control={control}
          rules={{ required: "Не введен код активации" }}
          render={({ field }) =>
            <Input
              {...field}
              placeholder="Код активации"
              className={st.regInputMail}
            />
          }
        />
        {Object.keys(errors).length > 0 && Object.keys(errors).map(item => (
          <div key={errors[item]} className={st.regErrorMsg}>{errors[item].message}</div>
        ))}
        <Button
          type="primary"
          htmlType="submit"
          className={st.regButton}
        >
          Активировать аккаунт
        </Button>
        <div className={st.resendCodeWrapper}>
          <small className={st.resendCodeTitle}>Код подтверждения не пришел?</small>
          <Button
            type="link"
            className={st.resendCodeButton}
            onClick={resendActivationCode}
          >
            Отправить код повторно
          </Button>
      </div>
      </form >
      
      {showAlert[0] &&
        <Alert
          className={st.statusAlert}
          message={showAlert[1] === "ok" ? "Код подтверждения успешно отправлен" : "Не удалось отправить код подверждения"}
          type={showAlert[1] === "ok" ? "success" : "error"}
          closable
          onClose={() => setShowAlert([false, showAlert[1]])}
          showIcon
        />
      }
    </div>
  );
};

export default Activation;