import { useStore } from 'effector-react';
import styles from './index.module.scss';
import { $weighingStore, $weighingYearsStore, getWeighingListFx, getWeighingYearsFx } from 'store/weighing';
import { useEffect } from 'react';
import { Select } from 'antd';

const PageHeader = () => {
    const { years } = useStore($weighingYearsStore);
    const { year } = useStore($weighingStore);

    useEffect(() => {
        getWeighingYearsFx();
    }, []);

    function changeYear(newYear) {
        getWeighingListFx(newYear);
    }

    return <div className={styles.pageHeader}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <h2 className={styles.pageTitle}>
                Заполненность складов
            </h2>
            <Select
                defaultValue={year}
                options={years.map(year => ({ value: year, label: year }))}
                onChange={changeYear}
            />
        </div>
    </div>
}

export default PageHeader;