import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore, useUnit, useStoreMap, useList } from "effector-react";
import cx from 'classnames';
import { Button, Dropdown, Select } from 'antd';
import * as L from "leaflet";

import { GeoJSON, MapContainer, TileLayer, Marker, useMap, Popup } from "react-leaflet";
import { dataToLeafletFormat, getCenter } from "../../shared/utils/leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster'

import {
  $geoJsonFeatures,
  $fieldsList,
  $mapMode,
  $editFieldRow,
  fetchFieldsFx,
  setEditField,
  fetchingFields,
  $selectFieldRow,
  setSelectField
} from "../../store/fields";
import {
  setHelpModule
} from "../../store/helpModal";
import {
  $techniquesCoords,
  fetchingTechnique
} from "../../store/technique";

import helpMessageRoutes from "../../helpMessageRoutes";
import ChangeFieldModal from "pages/Fields/ChangeFieldModal";

import { iconPerson } from './icons/CarIcon'
import { createClusterCustomIcon } from './icons/ClusterIcon'

import styles from "./index.module.scss";
import { usePermission } from "../../hooks/usePermission";
import { LeftOutlined } from "@ant-design/icons";
import { IoEllipsisHorizontal } from "react-icons/io5";
import FieldSelectModal from "./FieldDropdownModal";

const popupProps = (item) => {
  const {
    model,
    number_gov,
    number_inv,
    data: { count_miles, count_hours, position: { x, y } }
  } = item;

  return [
    { value: model, title: 'Марка' },
    { value: number_gov, title: 'Гос. номер' },
    { value: number_inv, title: 'Инв. номер' },
    { value: parseInt(count_miles), title: 'Кол-во километров' },
    { value: parseInt(count_hours), title: 'Кол-во часов' },
    { value: x, title : 'x'},
    { value: y, title: 'y' }
  ].filter(item => !!item.value)
}

const getLayer = (map, id) => {
  const layers = map.target._layers;
  let newLayers;
  Object.keys(layers).forEach((layerKey) => {
    if(layers[layerKey].feature && layers[layerKey].feature.properties.id == id){
      newLayers = layers[layerKey]
    }
  })

  return newLayers;
}

const GeoJsonComponent = ({ geoJson, setHoverId, paramsId }) => {
  const map = useMap();
  const GeoJSONRef = useRef();

  const onEachFeature = (feature, layer) => {
    const { properties } = feature

    layer.bindTooltip(`${properties.name} <br /> ${properties.description}`);

    if(paramsId != 'all'){
      map.fitBounds(layer.getBounds())
    }

    layer.on({
      click: () => setSelectField(properties.id),
    });
  }

  const style = (feature) => {
    return {
      fillColor: feature.properties.color || '#3388ff',
      color: feature.properties.color || '#3388ff'
    }
  }

  return (
    <GeoJSON
      data={geoJson}
      key={Math.floor(Math.random() * 100)}
      style={style}
      onEachFeature={onEachFeature}
      ref={GeoJSONRef}
    />
  )
}

const FieldMap = () => {
  const { id: paramsId } = useParams();
  const navigate = useNavigate();
  const [hoverId, setHoverId] = useState();
  const [map, setMap] = useState();
  const [showMap, setShowMap] = useState(false);
  const [geoJson, setGeoJson] = useState();
  const markersCoords = useStore($techniquesCoords);
  const { pathname } = useLocation();
  const canEdit = usePermission("culture-rotation", "edit");

  const isFields = pathname.search('fields') > -1;

  const [mapMode, editRow, selectRow, geoJsonFeatures] = useUnit([
    $mapMode,
    $editFieldRow,
    $selectFieldRow,
    $geoJsonFeatures
  ]);

  useEffect(() => {
    setGeoJson({
      type: "FeatureCollection",
      features: paramsId != 'all' ?
        geoJsonFeatures.filter(item => item.properties.id == paramsId) : geoJsonFeatures,
    })

  }, [paramsId, geoJsonFeatures]);

  useEffect(() => {
    fetchingTechnique();
    fetchingFields(1);
  }, []);

  useEffect(() => {
    setHelpModule(helpMessageRoutes.fields.allMaps);
    return () => {
      setHelpModule(null)
    }
  }, [])

  const changeLayerColor = (id) => {
    const layers = map.target._layers;

    Object.entries(layers).forEach(([key, layer]) => {
      layer.feature && layer.setStyle({ color: '#3388ff' });
    })

    id && getLayer(map, id)?.setStyle({ color: 'red' });
  }

  return (
    <div>
      {!mapMode &&
        <div className={styles.mapWrapper}>
          <MapContainer
            style={{ height: "100%", width: '100%' }}
            center={[50.82567414095759, 43.98719787597657]}
            zoom={paramsId == 'all' ? 11 : 13}
            zoomControl={false}
            scrollWheelZoom={true}
            whenReady={(m) => setMap(m)}
          >
            <TileLayer
              url="http://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            />
            {!isFields && (
              <MarkerClusterGroup
                showCoverageOnHover={false}
                maxClusterRadius={60}
                iconCreateFunction={createClusterCustomIcon}
              >
                {markersCoords.map(item => (
                  item.data 
                  ? <Marker
                      position={[item.data.position.y, item.data.position.x]}
                      icon={iconPerson(item.model)}
                    >
                      <Popup offset={L.point(77, -30)}>
                        {popupProps(item).map((caraDataItem) => (
                        <div style={{ fontWeight: 'bold' }}>
                          <span>{caraDataItem.title}: </span>
                          <span>{caraDataItem.value}</span>
                        </div>
                        ))}
                      </Popup>
                    </Marker> 
                  : null
                ))}
              </MarkerClusterGroup>
            )}
            <GeoJsonComponent
              geoJson={geoJson}
              setHoverId={setHoverId}
              style={(eee) => {
                console.log(eee)
              }}
              paramsId={paramsId}
            />

          </MapContainer>
          {paramsId == 'all' ?
            isFields 
              ? <div className={`${styles.mapBlock } ${showMap ? styles.showBlock : ''}`}>
                  <Button onClick={() => setShowMap(prev => !prev)}>{showMap ? 'Cкрыть список полей' : 'Показать список полей'}</Button>
                  <div
                    className={`${styles.mapList} ${showMap ? styles.showMap : ''}`}
                    onMouseLeave={() => changeLayerColor(null)}
                  >
                  {geoJson?.features.map((item, ix) => (
                    <div
                    key={ix}
                    className={cx(styles.mapItem, { active: hoverId == item.properties.id })}
                    onMouseOver={(ev) => changeLayerColor(item.properties.id)}
                    >
                      {item.properties.name}
                      <Dropdown
                      className={styles.dropdown}
                      menu={{
                        items: [
                          {label: "Редактировать", key: "editItem"},
                          {label: "Севооборот", key: "cropRotation"},
                        ].filter((el) => el.key === 'editItem' ? canEdit : true),

                        onClick: (dropdownItem) => {
                          switch(dropdownItem.key) {
                            case 'editItem':
                              setEditField(item.properties.id);
                              break;
                            case 'cropRotation':
                              navigate(`/fields/${item.properties.id}/culture-rotation`)
                          }
                        }
                      }}
                      trigger="click"
                      >
                        <Button>
                          <IoEllipsisHorizontal height={30} width={30} />
                        </Button>
                      </Dropdown>
                    </div>
                  ))}
                  </div>
                </div>
              : null
            : <div className={styles.mapDescr}>
              <div>{geoJson?.features[0]?.properties.name}</div>
              <div>{geoJson?.features[0]?.properties.description}</div>
            </div>
          }
          <div className={styles.leftBlock}>
            <div className={styles.zoomBtns}>
              <button tton className={styles.zoomBtn} onClick={() => map.target.zoomIn()}>+</button>
              <button className={styles.zoomBtn} onClick={() => map.target.zoomOut()}>-</button>
            </div>
          <Button
            onClick={() => navigate(isFields ? "/fields" : "/technique")}
          >
            {isFields? "К списку полей" : "К списку техники"}
          </Button>
          </div>
        </div>
      }
      {(mapMode || editRow) && <ChangeFieldModal />}
      {(mapMode || selectRow) && <FieldSelectModal />}
    </div>
  )
}

export default FieldMap;