import { useMemo } from "react";
import { Table, Button, Dropdown } from "antd";
import { usePermission } from "hooks/usePermission";
import { IoEllipsisHorizontal } from "react-icons/io5";

const tableColumns = [
    {
        title: "Название",
        dataIndex: "title",
        key: "name"
    },
    {
        title: "Описание",
        dataIndex: "description",
        key: "description"
    },
    {
        title: "Телефон",
        dataIndex: "phone",
        key: "phone"
    },
    {
        title: "Предприятие",
        dataIndex: "company",
        key: "company",
        render: (item) => item.name
    },
    {
        width: "10%",
        render: (item) =>
            (item.onEdit || item.onDelete) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Штатная численность", key: "staffPage"},
                        {label: "Редактировать", key: "editItem"},
                        {label: "Удалить", key: "deleteItem"},
                    ].filter(menuItem => menuItem.key === "editItem" ? item.onEdit : item.onDelete),
                    onClick: (dropDownItem) => {
                        switch (dropDownItem.key) {
                            case "staffPage":
                                item.onStaffView();
                                break;
                            case "deleteItem":
                                item.onDelete();
                                break;
                            case "editItem":
                                item.onEdit();
                                break;
                            default:
                                break;
                        }
                    }
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
]

const SectionsTable = ({items, onStaffView, onDelete, onEdit}) => {
    const canView = usePermission("section")
    const canEdit = usePermission("section", "edit");
    const canDelete = usePermission("section", "delete");

    const tableItems = useMemo(() =>
        items.map(item => ({
            ...item,
            onStaffView: canView && (() => onStaffView(item)),
            onEdit: canEdit && (() => onEdit(item)),
            onDelete: canDelete && (() => onDelete(item))
        })), [items, canView, canEdit, canDelete, onStaffView, onDelete, onEdit]);

    return (
        <Table
            columns={tableColumns}
            dataSource={tableItems || []}
        />
    );
}

export default SectionsTable;