export const pathDocs = {
    "/companies": [
        {roleName: "company/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Предприятия"},
        {roleName: "company", accessModifier: "Просмотр", descr: "Доступ к страничке предприятий"},
        {roleName: "company", accessModifier: "Редактирование", descr: "Возможность заблокировать/разблокировать предприятие"},
        {roleName: "company", accessModifier: "Создание", descr: "Возможность создания нового предприятия"},
        {roleName: "company", accessModifier: "Удаление", descr: "Доступ к удалению роли"}
    ],
    "/companies-stats": [
        {roleName: "companies-stats/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Статистика предприятий"},
    ],
    "/fields": [
        {roleName: "fields/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Поля"},
        {roleName: "fields", accessModifier: "Просмотр", descr: "Доступ к страничке с полями"},
        {roleName: "fields", accessModifier: "Редактирование", descr: "Доступ к редактированию поля"},
        {roleName: "fields", accessModifier: "Создание", descr: "Доступ к созданию нового поля"},
        {roleName: "fields", accessModifier: "Удаление", descr: "Доступ к удалению поля"}
    ],
    "/fields/*/map": [
        {roleName: "plantation", accessModifier: "Просмотр", descr: "Доступ к странице Общая карта полей"},
    ],
    "fields/*/culture-rotation": [
        {roleName: "fields/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Севооборота"},
        {roleName: "fields", accessModifier: "Просмотр", descr: "Доступ к страничке с Севооборот"},
        {roleName: "fields", accessModifier: "Редактирование", descr: "Доступ к редактированию записи Севооборота"},
        {roleName: "fields", accessModifier: "Создание", descr: "Доступ к созданию записи Севооборота"},
        {roleName: "fields", accessModifier: "Удаление", descr: "Доступ к удалению записи Севооборота"}
    ],
    "/roles": [
        {roleName: "role/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Роли"},
        {roleName: "role", accessModifier: "Просмотр", descr: "Доступ к странице с ролями"},
        {roleName: "role", accessModifier: "Создание", descr: "Доступ к созданию новой роли"},
        {roleName: "role", accessModifier: "Редактирование", descr: "Доступ к редактированию роли"},
        {roleName: "role", accessModifier: "Удаление", descr: "Доступ к удалению роли"},
    ],
    "/users": [
        {roleName: "user/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Пользователи"},
        {roleName: "user", accessModifier: "Просмотр", descr: "Доступ к странице с пользователями и ко входу в аккаунт пользоватля"},
        {roleName: "user", accessModifier: "Создание", descr: "Доступ к регистрации новых пользователей"},
        {roleName: "user", accessModifier: "Редактирование", descr: "Доступ к почте, имени, блокировке/разблокировке, восстановлению пароля и ролям пользователя"},
        {roleName: "user", accessModifier: "Удаление", descr: "Доступ к удалению пользователя"},
    ],
    "/cultures": [
        {roleName: "culture/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Культуры"},
        {roleName: "culture", accessModifier: "Просмотр", descr: "Доступ к странице с культурами"},
        {roleName: "culture", accessModifier: "Создание", descr: "Доступ к созданию новой культуры"},
        {roleName: "culture", accessModifier: "Редактирование", descr: "Доступ к редактированию культуры"},
        {roleName: "culture", accessModifier: "Удаление", descr: "Доступ к удалению культуры"},
    ],
    "/sections": [
        {roleName: "section/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Подразделения"},
        {roleName: "section", accessModifier: "Просмотр", descr: "Доступ к странице с подразделениями"},
        {roleName: "section", accessModifier: "Создание", descr: "Доступ к созданию нового подразделения"},
        {roleName: "section", accessModifier: "Редактирование", descr: "Доступ к редактированию подразделения"},
        {roleName: "section", accessModifier: "Удаление", descr: "Доступ к удалению подразделения"},
    ],
    "/storage": [
        {roleName: "storage/*", accessModifier: "", descr: "Доступ ко всем складам"},
        {roleName: "storage", accessModifier: "Просмотр", descr: "Доступ к просмотру складов"},
        {roleName: "storage", accessModifier: "Создание", descr: "Доступ к созданию нового склада"},
        {roleName: "storage", accessModifier: "Редактирование", descr: "Доступ к редактированию склада"},
        {roleName: "storage", accessModifier: "Удаление", descr: "Доступ к удалению склада"},
    ],
    "/sections/*/staff": [
        {roleName: "section/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Подразделения"},
        {roleName: "/sections/*/staff", accessModifier: "Просмотр", descr: "Доступ к странице со штатной численностью  подразделения"},
        {roleName: "/sections/*/staff", accessModifier: "Создание", descr: "Доступ к добавлению нового сотрудника в штат"},
        {roleName: "/sections/*/staff", accessModifier: "Редактирование", descr: "Доступ к редактированию сотрудника в штате"},
        {roleName: "/sections/*/staff", accessModifier: "Удаление", descr: "Доступ к удалению сотрудника из штата"},
    ],
    "/technique": [
        {roleName: "technique/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Техника"},
        {roleName: "technique", accessModifier: "Просмотр", descr: "Доступ к странице с техникой"},
        {roleName: "technique", accessModifier: "Создание", descr: "Доступ к созданию новой техники"},
        {roleName: "technique", accessModifier: "Редактирование", descr: "Доступ к редактированию техники"},
        {roleName: "technique", accessModifier: "Удаление", descr: "Доступ к удалению техники"},
    ],
    "/technique-monitoring": [
        {roleName: "technique-monitoring/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Мониторинг техники"},
        {roleName: "technique-monitoring", accessModifier: "Просмотр", descr: "Доступ к странице с Мониторингом техники"},
    ],
    "/technique-monitoring-group": [
        {roleName: "technique-monitoring-group/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Групповой отчет по технике"},
        {roleName: "technique-monitoring-group", accessModifier: "Просмотр", descr: "Доступ к странице Групповой отчет по технике"},
    ],
    "/tech-operations": [
        {roleName: "tech-operations/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Технические операции"},
        {roleName: "tech-operations", accessModifier: "Просмотр", descr: "Доступ к странице с техническими операциями"},
        {roleName: "tech-operations", accessModifier: "Создание", descr: "Доступ к созданию технологических операций"},
        {roleName: "tech-operations", accessModifier: "Редактирование", descr: "Доступ к редактированию технических операций"},
        {roleName: "tech-operations", accessModifier: "Удаление", descr: "Доступ к удалению технических операций"},
    ],
    "/tech-operations-groups": [
        {roleName: "tech-operations-groups/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Технические группы"},
        {roleName: "tech-operations-groups", accessModifier: "Просмотр", descr: "Доступ к странице с техническими группами"},
        {roleName: "tech-operations-groups", accessModifier: "Создание", descr: "Доступ к созданию технологических групп"},
        {roleName: "tech-operations-groups", accessModifier: "Редактирование", descr: "Доступ к редактированию технических групп"},
        {roleName: "tech-operations-groups", accessModifier: "Удаление", descr: "Доступ к удалению технических групп"},
    ],
    "/technique/*/map": [
        {roleName: "technique", accessModifier: "Просмотр", descr: "Доступ к страница Общая карта техники"},
    ],
    "/usersGroups": [
        {roleName: "usersGroups/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Группы пользователей"},
        {roleName: "usersGroups", accessModifier: "Просмотр", descr: "Доступ к странице с группами пользователей"},
        {roleName: "usersGroups", accessModifier: "Создание", descr: "Доступ к созданию группы пользователей"},
        {roleName: "usersGroups", accessModifier: "Редактирование", descr: "Доступ к редактированию групп пользователей"},
        {roleName: "usersGroups", accessModifier: "Удаление", descr: "Доступ к удалению групп пользователей"},
    ],
    "/weighing": [
        {roleName: "weighing/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Взвешивание урожая"},
        {roleName: "weighing", accessModifier: "Просмотр", descr: "Доступ к странице со взвешиванием"},
        {roleName: "weighing", accessModifier: "Создание", descr: "Доступ к созданию взвешивания"},
        {roleName: "weighing", accessModifier: "Редактирование", descr: "Доступ к редактированию взвешиваний"},
        {roleName: "weighing", accessModifier: "Удаление", descr: "Доступ к удалению взвешивания"},
    ],
    "/usersGroups/*/roles": [
        {roleName: "usersGroups/*", accessModifier: "", descr: "Доступ ко всем страницам раздела Роли группы пользователей"},
        {roleName: "usersGroups", accessModifier: "Просмотр", descr: "Доступ к странице с правами Роли групп пользователей"},
        {roleName: "usersGroups", accessModifier: "Создание", descr: "Не имеет эффекта"},
        {roleName: "usersGroups", accessModifier: "Редактирование", descr: "Доступ к редактированию прав Роли групп пользователей"},
        {roleName: "usersGroups", accessModifier: "Удаление", descr: "Не имеет эффекта"},
    ],
    '/storage-copacity': [
        {roleName: 'storageCopacity/*', accessModifier: 'Просмотр', descr: "Доступ к странице с Заполеннностями складов"},
    ],
    '/field-productivity': [
        {roleName: 'fieldProductivity/*', accessModifier: 'Просмотр', descr: "Доступ к странице с Уражайностями полей"},
    ],
    '/material': [
        {roleName: 'material/*', accessModifier: 'Просмотр', descr: "Доступ к странице с материалами"},
        {roleName: 'material/*', accessModifier: 'Создание', descr: "Доступ к созданию материала"},
        {roleName: 'material/*', accessModifier: 'Редактирование', descr: "Доступ к редактированию материала"},
        {roleName: 'material/*', accessModifier: 'Удаление', descr: "Доступ к удалению материала"},
    ],
    '/material-groups': [
        {roleName: 'material-groups/*', accessModifier: 'Просмотр', descr: "Доступ к странице с группами материалов"},
        {roleName: 'material-groups/*', accessModifier: 'Создание', descr: "Доступ к созданию групп материалов"},
        {roleName: 'material-groups/*', accessModifier: 'Редактирование', descr: "Доступ к редактированию групп материалов"},
        {roleName: 'material-groups/*', accessModifier: 'Удаление', descr: "Доступ к удалению групп материалов"},
    ],
}
