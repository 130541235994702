import { routes } from "routes";

export const defaultMenuItems = [
  { label: "Пользователи", key: routes.users, endpoint: "user" },
  { label: "Роли пользователей", key: routes.roles, endpoint: "role" },
  { label: "Группы пользователей", key: routes.usersGroups, endpoint: "usersGroups" },
  { label: "Предприятия", key: routes.companies, endpoint: "company" },
  { label: "Подразделения", key: routes.section, endpoint: "section" },
  { label: "Склады", key: routes.storage, endpoint: "storage" },
  { label: "Поля", key: routes.fields, endpoint: "plantation" },
  { label: "Взвешивание урожая", key: routes.weighing, endpoint: "weighing" },
  { label: "Техника", key: routes.technique, endpoint: "technique" },
  {
    label: "Отчеты", 
    key: 'stats-menu', 
    endpoint: 'stats-menu',
    children: [
      { label: "Штатная численность предприятий", key: routes.companiesStats, endpoint: "companies-stats" },
      { label: "Заполненность складов", key: routes.storageCopacity, endpoint: "storage-copacity" },
      { label: "Урожайность полей", key: routes.fieldProductivity, endpoint: "field-productivity" },
      { label: "Статистика культур", key: routes.cultureRotationStats, endpoint: "culture-rotation-stats" },
      { label: "Мониторинг техники", key: routes.techniqueMonitoring, endpoint: 'technique-monitoring'},
      { label: "Мониторинг техники (групповой)", key: routes.techniqueMonitoringGroup, endpoint: 'technique-monitoring-group'},
    ]
  },
  {
    label: 'Справочники',
    key: 'guides',
    endpoint: 'guides',
    children: [
      { label: "Культуры", key: routes.cultures, endpoint: "culture" },
      { label: "Технологические операции", key: routes.techOperations, endpoint: "tech-operations" },
      { label: "Технологические группы", key: routes.techOperationsGroups, endpoint: "tech-operations-groups" },
      { label: "Материалы", key: routes.material, endpoint: "material" },
      { label: "Группы материалов", key: routes.materialGroups, endpoint: "material-groups" },
    ]
  },
]

export const getNavLinks = (permissions) => {
  return defaultMenuItems.filter(item => permissions[item.endpoint]?.view === 1);
}