import { createDomain } from "effector";
import { get, urls } from "api";
import { forward } from "effector";

const initialState = {
    roles: [],
    editedRoles: new Set()
}

const userRolesDomain = createDomain();

export const $userRolesStore = userRolesDomain.createStore(initialState);

// Events
export const setUserRoles = userRolesDomain.createEvent();
export const toggleRoleView = userRolesDomain.createEvent();
export const toggleRoleCreate = userRolesDomain.createEvent();
export const toggleRoleEdit = userRolesDomain.createEvent();
export const toggleRoleDelete = userRolesDomain.createEvent();

// Effects
export const fetchUserRolesFx = userRolesDomain.createEffect(async (payload) => {
    let res;
    if (parseInt(payload))
        res = await get(urls.userRoles, {user: payload});
    else if (payload?.params?.uid)
        res = await get(urls.userRoles, {user: payload.params.uid});
    else
        res = await get(urls.userRoles);
    return res.data.list;
});

export const saveUserPermissionsFx = userRolesDomain.createEffect(async ({role, uid}) => {
    let res;
    const roleModifiers = {
        view: role.view ? 1 : 0,
        create: role.create ? 1 : 0,
        edit: role.edit ? 1 : 0,
        "delete": role.delete ? 1 : 0
    };

    // If every modifier is 0 deletes the role
    if (Object.values(roleModifiers).every(modifier => modifier === 0)) {
        res = await get(urls.roleDeleteToUser, {
            role: role.id,
            user: uid
        });
        if (res.data.error_message === "permission_user_not_found" || res.data.status === "ok")
            return true;
    } else {
        res = await get(urls.roleEditToUser, {
            role: role.id,
            user: uid,
            view: role.view ? 1 : 0,
            create: role.create ? 1 : 0,
            edit: role.edit ? 1 : 0,
            "delete": role.delete ? 1 : 0
        });
    }
    if (res && res.data.status === "error")
        res = await get(urls.roleAddToUser, {
            role: role.id,
            user: uid,
            view: role.view ? 1 : 0,
            create: role.create ? 1 : 0,
            edit: role.edit ? 1 : 0,
            "delete": role.delete ? 1 : 0
        })
    if (!res || res.data.status !== "ok") {
        return false;
    }
})

// Forwards
forward({
    from: fetchUserRolesFx.done,
    to: setUserRoles
});

// Events handlers
$userRolesStore
    .on(
        setUserRoles, (state, {result}) => ({
            ...state,
            roles: result.map(role =>
                ({
                    ...role,
                    key: role.id,
                    roleName: role.title || role.url
                }))
        })
    )
    .on(
        toggleRoleView, (state, id) => {
            return ({
                editedRoles: state.editedRoles.add(id),
                roles: state.roles.map(role => role.id === id
                ? {...role, view: !role.view}
                : role
            )});
        }
    )
    .on(
        toggleRoleCreate, (state, id) => {
            return ({
                editedRoles: state.editedRoles.add(id),
                roles: state.roles.map(role => role.id === id
                    ? {...role, create: !role.create}
                    : role
                )
            })
        }
    )
    .on(
        toggleRoleEdit, (state, id) => {
            return ({
                editedRoles: state.editedRoles.add(id),
                roles: state.roles.map(role => role.id === id
                    ? {...role, edit: !role.edit}
                    : role
                )
            })
        }
    )
    .on(
        toggleRoleDelete, (state, id) => {
            return ({
                editedRoles: state.editedRoles.add(id),
                roles: state.roles.map(role => role.id === id
                    ? {...role, delete: !role.delete}
                    : role
                )
            })
        }
    );