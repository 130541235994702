import { forward, sample, createStore, createEvent, createEffect, attach } from "effector";
import { urls, get, post } from "api";
import { dataToLeafletFormat } from "../shared/utils/leaflet";

// Store
export const $fieldsList = createStore([]);
export const $geoJsonFeatures = createStore([]);
export const $editFieldRow = createStore(null);
export const $selectFieldRow = createStore(null);
export const $mapMode = createStore(false);

// Events
const setFields = createEvent();
export const setEditField = createEvent();
export const setSelectField = createEvent();
export const deleteEditRow = createEvent();
export const deleteSelectRow = createEvent();
export const toggleMap = createEvent();
export const showMap = createEvent();
export const closeMap = createEvent();
export const plantationCreated = createEvent();
export const plantationChanged = createEvent();
export const updateJsonMap = createEvent();
export const setViewMapMode = createEvent();
export const fetchingFields = createEvent();

// Effects
export const fetchFieldsFx = createEffect();
export const changeFieldFx = createEffect();
export const uploadFieldFx = createEffect();

const clearPolygons = (plantation) => {
  const poligon = plantation.poligon ?
    plantation.poligon.split(',').map(item => item.indexOf('0 ') > -1 ? item.slice(2) : item) : []

  return {
    ...plantation,
    poligon
  };
}


fetchFieldsFx.use(async(companyId) => {
  const { data } = await get(urls.plantationList, { company_id: companyId });

  return data.list.map(clearPolygons).reverse()
});

changeFieldFx.use(async (props) => {
  const { name, description, poligon, company, plantation } = props;

  const res = await post({
    module: "plantation",
    action: "edit",
    company: company,
    plantation,
    data: JSON.stringify({ name, description, poligon })
  })

  return res;
})

uploadFieldFx.use(async({ companyId, fieldFile }) => {
  const res = await post({
    module: 'plantation',
    action: 'kml_import',
    company_id: companyId,
    data_file: fieldFile
  })

  return res.data.status === "ok";
})


forward({
  from: fetchFieldsFx.done,
  to: setFields
});

sample({
  clock: setEditField,
  source: $fieldsList,
  fn: (store, eventFieldId) => {
    return eventFieldId ?
      store.find(item => item.id === eventFieldId) :
      { name: "", description: "", poligon: "", company: "" }
  },
  target: $editFieldRow
})

sample({
  clock: setSelectField,
  source: $fieldsList,
  fn: (store, eventFieldId) => {
    return eventFieldId ?
      store.find(item => item.id === eventFieldId) :
      { name: "", description: "", poligon: "", company: "" }
  },
  target: $selectFieldRow
})

sample({
  clock: toggleMap,
  source: $mapMode,
  fn: (mapMode) => !mapMode,
  target: $mapMode
})

sample({
  clock: changeFieldFx.done,
  filter: ({ params }) => !params.plantation,
  target: plantationCreated
})

sample({
  clock: changeFieldFx.done,
  filter: ({ params }) => !!params.plantation,
  target: plantationChanged
})

sample({
  clock: $fieldsList,
  target: updateJsonMap
})

sample({
  clock: showMap,
  target: $mapMode
})

sample({
  clock: fetchingFields,
  target: fetchFieldsFx
})

// Reducers
$fieldsList
  .on(setFields, (_, { result }) => {
    return result;
  })
  .on(plantationChanged, (store, { params, result }) => {
    const plantation = result.data.plantation;

    return store.map(item => item.id === plantation.id ? clearPolygons(plantation) : item)
  })
  .on(plantationCreated, (store, { result }) => {
    const plantation = result.data.plantation;

    return [clearPolygons(plantation), ...store]
  })

$geoJsonFeatures
  .on(updateJsonMap, (store, fieldsList) => {
    const data = [];

    const getProp = (props) => {
      try{
        if(props.culture_rotation.current.culture){
          return props.culture_rotation.current.culture.color
        }else if(props.culture_rotation.prev.culture){
          return props.culture_rotation.prev.culture.color
        }
      }
      catch(e){
        console.log('aaaaaaaaaaaa')
      }
    }

    const getGeoJsonData = (props) => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [props.polygon],
      },
      properties: {
        name: props.name,
        id: props.id,
        description: props.description,
        color: getProp(props)
      },
    })

    fieldsList.forEach((item) => data.push(
      getGeoJsonData({
        polygon: dataToLeafletFormat(item.poligon, true),
        ...item
      })
    ))

    return data
  })

$editFieldRow.reset(deleteEditRow);
$mapMode.reset(deleteEditRow);
$selectFieldRow.reset(deleteSelectRow);
$mapMode.reset(deleteSelectRow);