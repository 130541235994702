import Modal from "antd/es/modal/Modal";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import helpMessageRoutes from "helpMessageRoutes";
import UpdateWeighingForm from "./UpdateWeighingForm";

const EditWeighingModal = ({onSubmit, editableItem, ...props}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Изменить взвешивание" helpFile={helpMessageRoutes.weighing.edit}/>}
            footer={null}
            destroyOnClose
            {...props}
        >
            <UpdateWeighingForm 
                onSubmit={onSubmit} 
                onCancel={props.onCancel} 
                submitTitle={'Изменить'}
                {...editableItem}
            />
        </Modal>
    )
}

export default EditWeighingModal;
