import axios from 'axios';
import { $userStore, $viewAsAdminUser } from "store/user";

const baseURL = 'https://api.xn----7sbegmlg5bets.xn--p1ai/api/';

const instance = axios.create({
  baseURL,
});

const defaultUrls = {
  registration: 'module=auth&action=user_add',
  login: 'module=auth&action=login',
  sendRestoreRequest: 'module=auth&action=send_psw_to_mail',
  setNewPassword: 'module=auth&action=set_new_password',
  activation: 'module=auth&action=activate_user',
  resendActivation: 'module=auth&action=send_activation_code',
  editPassword: 'module=auth&action=edit_password',

  userData: 'module=auth&action=user_info',
  userRoles: 'module=role&action=permission_user_list',
  userPermissionsList: 'module=role&action=permission_list',

  enterprisesList: 'module=company&action=list',
  enterprisesCreateOrEdit: 'module=company&action=edit',

  enterprisesUnblockById: 'module=company&action=company_enable',
  enterprisesBlockById: 'module=company&action=company_disable',

  adminUsersList: 'module=user&action=list',
  changeUserMail: 'module=user&action=mail_edit',
  blockUserById: 'module=user&action=user_disable',
  unblockUserById: 'module=user&action=user_enable',

  adminForgotPassword: 'module=admin&action=send_psw_to_mail',
  newAdminUser: 'module=admin&action=user_add&group=5',
  changeAdminUser: 'module=admin&action=user_edit',
  viewAsUser: 'module=admin&action=login_into_user',

  rolesList: 'module=role&action=list',
  roleAdd: 'module=role&action=add',
  roleEditById: 'module=role&action=edit',
  roleRemoveById: 'module=role&action=delete',

  roleCheckUserPerm: 'module=role&action=permission',
  roleAddToUser: 'module=role&action=permission_user_add',
  roleEditToUser: 'module=role&action=permission_user_edit',
  roleDeleteToUser: 'module=role&action=permission_user_delete',

  roleGroupList: 'module=role&action=permission_group_list',
  roleAddToGroup: 'module=role&action=permission_group_add',
  roleEditToGroup: 'module=role&action=permission_group_edit',
  roleDeleteToGroup: 'module=role&action=permission_group_delete',

  getUsersGroups: 'module=user_group&action=list',
  changeUsersGroup: 'module=user_group&action=edit',
  deleteUsersGroup: 'module=user_group&action=delete',

  plantationList: 'module=plantation&action=list',
  plantationGetById: 'module=plantation&action=get',

  cultureList: 'module=culture&action=list',
  cultureGet: 'module=culture&action=get',
  cultureEdit: 'module=culture&action=edit',
  cultureDelete: 'module=culture&action=delete',

  sectionList: 'module=section&action=list',
  sectionGet: 'module=section&action=get',
  sectionEdit: 'module=section&action=edit',
  sectionDelete: 'module=section&action=delete',
  sectionListByCompany: 'module=section&action=list_by_company',
  sectionListStaff: 'module=worker&action=list',
  sectionEditEmployee: 'module=worker&action=edit_as_user',
  sectionDeleteEmployee: 'module=worker&action=delete',
  sectionQuitEmployee: 'module=worker&action=quit',

  storageList: 'module=storage&action=list',
  storageCompanyList: 'module=storage&action=list_by_company',
  storageGet: 'module=storage&action=get',
  storageDelete: 'module=storage&action=delete',

  techniqueList: 'module=technic&action=list',
  techniqueEdit: 'module=technic&action=edit',
  techniqueGet: 'module=technic&action=get',
  techniqueDelete: 'module=technic&action=delete',

  techniqueMonitoringList: 'module=technic-monitoring&action=list',

  workersList: 'module=worker&action=list',
  workersEdit: 'module=worker&action=edit',
  workersGetBySectionId: 'module=worker&action=list',
  workersDelete: 'module=worker&action=delete',

  cultureRotationList: 'module=culture_rotation&action=list',
  cultureRotationEdit: 'module=culture_rotation&action=edit',
  cultureRotationDelete: 'module=culture_rotation&action=delete',
  cultureRotationStats: 'module=culture_rotation&action=stat_culture',

  techOperationsList: 'module=tech_operation&action=list',
  techOperationsEdit: 'module=tech_operation&action=edit',
  techOperationsDelete: 'module=tech_operation&action=delete',

  techGroupsOperationsList: 'module=tech_operation&action=list_group',
  techGroupsOperationsEdit: 'module=tech_operation&action=edit_group',
  techGroupsOperationsDelete: 'module=tech_operation&action=delete_group',

  weighingList: 'module=weighing&action=list',
  weighingYears: 'module=weighing&action=weighing_year_list',
  weighingCompanyList: 'module=weighing&action=list_by_company',
  weighingGet: 'module=weighing&action=get',
  weighingDelete: 'module=weighing&action=delete',

  materialList: 'module=material&action=list',
  materialEdit: 'module=material&action=edit',
  materialDelete: 'module=material&action=delete',

  materialGroupsList: 'module=material&action=list_group',
  materialGroupsEdit: 'module=material&action=edit_group',
  materialGroupsDelete: 'module=material&action=delete_group',

  getHelp: 'module=help&action=get'
}

const urls = {};

const setUrls = () => {
  Object.keys(defaultUrls).forEach(item => {
    // let newStr = '?' + defaultUrls[item];
    let newStr = defaultUrls[item];

    urls[item] = newStr;
  });
}

const get = async (module, params, useSessionFromStorage) => {
  const {viewAsUserMode, authData} = $userStore.getState();
  const adminUserStoreId = $viewAsAdminUser.map(store => store.authData.id)
  const sessionId = viewAsUserMode ? adminUserStoreId.getState() : authData.id;

  let requestUrl = ('?' + module);

  if(useSessionFromStorage){
    const user = JSON.parse(localStorage.getItem('user'))
    requestUrl += ('&s=' + user.id);
  }else if(sessionId){
    requestUrl += ('&s=' + sessionId);
  }

  if(params){
    requestUrl += ("&" + new URLSearchParams(params).toString())
  }

  const res = await instance.get(requestUrl);
  return res;
}

const post = async (params) => {
  const {viewAsUserMode, authData} = $userStore.getState();
  const adminUserStoreId = $viewAsAdminUser.map(store => store.authData.id)
  const sessionId = viewAsUserMode ? adminUserStoreId.getState() : authData.id;

  const formData = new FormData();

  sessionId && formData.append('s', sessionId)
  Object.keys(params).forEach(item => {
    formData.append(item, params[item])
  })

  const res = await instance.post("", formData);
  return res;
}

setUrls();

export { urls, get, post };

export default instance;