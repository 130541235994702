import hexRgb from "hex-rgb";
import { Hotline } from 'react-leaflet-hotline';

const getHotlineColor = (hexColor) => {
    let lineColors = hexColor.split(";").map((speedAndColor) => {
        speedAndColor = speedAndColor.split(" ");
        const rgb = hexRgb(speedAndColor[1]);
        return {
            ...{ r: rgb.red, g: rgb.green, b: rgb.blue },
            t: speedAndColor[0] / 100,
        };
    });
    return lineColors;
}

export function TrackLines({ track, technique, handleTrackClick }) {
    return <Hotline
        data={track}
        getLat={(t) => {
            return t.pos.y;
        }}
        getLng={(t) => {
            return t.pos.x;
        }}
        getVal={(t) => {
            return t.pos.s;
        }}
        options={{
            min: 0,
            max: 100,
            outlineWidth: 5,
            outlineColor: "black",
            palette: getHotlineColor(technique.d.prp.track_speed),
        }}
        eventHandlers={{
            click: (e, i) => {
                handleTrackClick(e.latlng, track[i], e.containerPoint, technique);
            }
        }}
    />
}