import React, { useEffect, useState } from 'react';
import { useStore, useUnit, useStoreMap } from "effector-react";
import { Button, Modal, Select } from "antd";
import { useForm, Controller } from "react-hook-form";
import helpMessageRoutes from 'helpMessageRoutes';
import {
  $editFieldRow,
  deleteEditRow,
  $mapMode,
  toggleMap,
  changeFieldFx,
} from "../../store/fields";
import {
  $companiesFetched,
  $companiesStore,
  getCompanies
} from "../../store/companies";

import { leafletFormatToData } from 'shared/utils/leaflet';

import { ModalTitleForHelp } from 'components/ModalTitleForHelp';
import { Form } from 'components/Form';

import st from "./index.module.scss";
import Map from "../../components/Map";

const items = [
  {
    label: 'Имя поля',
    name: 'name',
    type: 'text',
    rules: { required: 'Не указано поля' }
  },
  {
    label: 'Описание поля',
    name: 'description',
    type: 'text',
    rules: { required: 'Не указано описание поля' }
  },
]

const ChangeModal = ({
  notificationsApi,
}) => {
  const [editRow, mapMode, companiesFetched] = useUnit([
    $editFieldRow,
    $mapMode,
    $companiesFetched
  ]);
  const companies = useStoreMap({
    store: $companiesStore,
    keys: [companiesFetched],
    fn: (state) => state.list.map(({ id, name }) =>
      ({ value: id, label: name })
    )
  })
  const [latLng, setLatLng] = useState();

  const { setValue, ...form } = useForm({
    defaultValues: {
      description: editRow.description,
      name: editRow.name,
      poligon: editRow.poligon,
      company: editRow.company
    }
  });

  useEffect(() => {
    !companiesFetched && getCompanies()
  }, [companiesFetched]);

  const onSubmit = async (props) => {
    const { name, description, company } = props;
    const sendData = {
      name,
      description,
      company,
      plantation: editRow.id
    }

    latLng && (sendData['poligon'] = leafletFormatToData(latLng[0]));

    const { data = {} } = await changeFieldFx(sendData);

    if(data.error_msg) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    } else {
      notificationsApi.success({
        message: editRow.id ? 'Поле успешно отредактировано' : 'Поле успешно создано',
        duration: 5
      });
      deleteEditRow();
    }
  }

  if(mapMode){
    return (
      <Map
        mode={editRow.poligon.length ? 'edit' : 'create'}
        strPolygons={editRow.poligon}
        onCreateLayer={(latLng) => setLatLng(latLng)}
      />
    )
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={editRow.id ? "Редактирование поля" : "Создание нового поля"}
          helpFile={helpMessageRoutes.fields[editRow.id ? 'edit' : 'create']}
        />
      }
      open={true}
      onCancel={deleteEditRow}
      footer={null}
    >
      <form
        className={st.modalForm}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {items.map((props) =>
          <Form.Input input={props} form={form} />
        )}
        <Controller
          name="company"
          control={form.control}
          render={({ field, ...p }) => (
            <div className={st.modalItem}>
              <div>Выбор компании</div>
              {companiesFetched &&
                <Select
                  options={companies}
                  className={st.modalItem}
                  placeholder="Выберите компанию"
                  defaultValue={field.value ? companies.find(item => item.value == field.value)?.label : ""}
                  onChange={(value) => field.onChange(value)}
                />
              }
            </div>
          )}
        />
        <Button
          onClick={toggleMap}
          className={st.modalItem}
        >
          {editRow.poligon.length ? "Изменить координаты карты" : "Выставить координаты карты"}
        </Button>
        <div className={st.modalFooter}>
          <Button onClick={deleteEditRow}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangeModal;