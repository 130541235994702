import { Table, Button } from "antd"
import { usePermission } from "hooks/usePermission";

// Columns headers
const tableColumns = [
    {
        title: "Название роли",
        dataIndex: "roleName",
        key: "roleName",
        render: (_, role) => role.roleName || role.roleURL
    },
    {
        title: "URL роли",
        dataIndex: "roleURL",
        key: "roleURL"
    },
    {
        title: "",
        dataIndex: "roleActionEdit",
        key: "roleActionEdit",
        render: (_, role) => <Button onClick={role.onEditRole}>Редактировать</Button>
    },
    {
        title: "",
        dataIndex: "roleActionDelete",
        key: "roleActionDelete",
        render: (_, role) => <Button onClick={role.onRemoveRole}>Удалить</Button>
    }
]

// Turns role object to correct table value
const parseRoles = (roles, onEditRole, onRemoveRole) =>
    roles.map(role =>
        ({
            key: role.id,
            roleName: role.title,
            roleURL: role.url,
            onEditRole: () => onEditRole(role),
            onRemoveRole: () => onRemoveRole(role.id)
        })
    );

export const RolesTable = ({roles, onEditRole, onRemoveRole}) => {
    const editRole = usePermission("role", "edit");
    const deleteRole= usePermission("role", "delete");

    const filteredColumns = tableColumns
        .filter(({dataIndex}) => {
            if (dataIndex === "roleActionEdit" && !editRole) {
                return false;
            }
            if (dataIndex === "roleActionDelete" && !deleteRole)
                return false;
            return true;
        })

    return (
        <Table
            pagination={{
                hideOnSinglePage: true,
                defaultPageSize: 100,
            }}
            columns={filteredColumns}
            dataSource={roles.length && parseRoles(roles, onEditRole, onRemoveRole)}
        />
    )
}
