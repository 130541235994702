import { Form, Input, Select, Button } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { get, urls } from "api"

import { useStore } from "effector-react";
import { $companiesStore, getCompanies } from "store/companies";
import { $sectionsStore, getSectionsFx } from "store/sections";
import { $userList, getUserListFx } from "store/adminUsers";

const carTypes = [
    {
        label: 'Любой',
        value: '',
    },
    {
        label: 'Легковой',
        value: 'car',
    },
    {
        label: 'Грузовой',
        value: 'truck',
    },
    {
        label: 'Комбайн',
        value: 'combainer'
    },
    {
        label: 'Трактор',
        value: 'tractor',
    },
    {
        label: 'Прицеп',
        value: 'trailer',
    }
]

const EditTechniqueForm = ({
    onSubmit,
    submitTitle,
    onCancel,
    // Default values
    id, model, number_gov, number_inv, company, section, user, type
}) => {
    const {list: companiesList} = useStore($companiesStore);
    const {list: sectionsList} = useStore($sectionsStore);
    const userList = useStore($userList);

    const [isUserLoading, setIsUserLoading] = useState(false);
    const [filteredUserList, setFilteredUserList] = useState([]);

    const [form]  = Form.useForm();
    const pickedCompany = Form.useWatch("company", form);
    const pickedSection = Form.useWatch("section", form);

    // Filtered lists
    const filteredSectionsList = useMemo(
        () => sectionsList.filter(({company}) => company?.id === pickedCompany),
        [pickedCompany, sectionsList]
    );

    // onChange callbacks
    const onCompanyChange = useCallback((value) => {
        form.setFieldsValue({
            company: value,
            section: null,
            user: null
        })
    }, [form]);

    const onSectionChange = useCallback((value) => {
        form.setFieldsValue({
            section: value,
            user: null
        })
    }, [form]);

    // Data fetching
    useEffect(() => {
        getCompanies();
        getSectionsFx();
        getUserListFx();
        form.setFieldsValue({
            company: company?.id,
            section: section?.id,
            user: user?.id
        })
    }, [form, company, section, user]);

    
    useEffect(() => {
        if (pickedCompany && pickedSection) {
            setIsUserLoading(true);
            get(urls.workersGetBySectionId, {company: pickedCompany, section: pickedSection})
                .then(res => setFilteredUserList(res.data.list?.map(item => item.user)))
                .finally(() => setIsUserLoading(false));
        }
    }, [pickedSection, userList]);


    const onFinish = useCallback((data) => {
        onSubmit({...data, technic: id})
    }, [onSubmit, id]);

    return (
        <Form
            onFinish={onFinish}
            layout="vertical"
            form={form}
        >
            <Form.Item
                label="Модель"
                name="model"
                required={false}
                rules={[{ required: true, message: 'Введите модель' }]}
                initialValue={model}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Номер"
                name="number_gov"
                required={false}
                initialValue={number_gov}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Инвентарный номер"
                name="number_inv"
                required={false}
                initialValue={number_inv}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Предприятие"
                name="company"
                required={false}
                rules={[{required: true, message: 'Выберете предприятие'}]}
            >
                <Select
                    options={companiesList?.map(item => ({value: item.id, label: item.name})) || []}
                    onChange={onCompanyChange}
                />
            </Form.Item>

            <Form.Item
                label="Подразделение"
                name="section"
                required={false}
                validateStatus={(pickedCompany && filteredSectionsList.length === 0) ? "error" : undefined}
                help={
                    (pickedCompany && filteredSectionsList.length === 0)
                        ? "У предприятие не создано ни одного подразделения.\nОбратитесь к администратору"
                        : undefined
                    }
                rules={[
                    {required: true, message: 'Выберете подразделение'}
                ]}
            >
                <Select
                    disabled={!pickedCompany}
                    options={filteredSectionsList?.map(item => ({value: item.id, label: item.title})) || []}
                    onChange={onSectionChange}
                />
            </Form.Item>

            <Form.Item
                label="Сотрудник"
                name="user"
                required={false}
                validateStatus={(!isUserLoading && pickedSection && filteredUserList?.length === 0) ? "error" : undefined}
                help={(!isUserLoading && pickedSection && filteredUserList?.length === 0) ? "В подразделении нет сотрудников. Обратитесь к администратору" : undefined}
                rules={[{required: true, message: 'Выберете сотрудника'}]}
            >
                <Select
                    disabled={!pickedSection}
                    loading={isUserLoading}
                    options={filteredUserList?.map(({id, name, surname, patronymic, login, mail}) =>
                        ({
                            value: id,
                            label: (name && surname && patronymic) ? [name, surname, patronymic].join(" ") : (mail || login)
                        })) || []}
                />
            </Form.Item>

            <Form.Item
                label='Тип техники'
                name='type'
                initialValue={type}
            >
                <Select
                    options={carTypes}
                />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default EditTechniqueForm;