import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Divider } from "antd";
import { useStore } from 'effector-react';
import { useNavigate, useParams } from "react-router-dom";
import TableDropdown from "components/TableDropdown";
import { CreateModal } from './modals';
import { usePermission } from "hooks/usePermission";
import {
  $operationsGroup,
  deleteTechOperationGroupsFx,
  getTechOperationsGroups
} from "../../store/operationsGroup";
import { $userStore } from 'store/user';

import styles from "./style.module.scss";

const TechOperations = () => {
  const data = useStore($operationsGroup);
  const {viewAsUserMode} = useStore($userStore);

  const canEdit = usePermission('tech-operations-groups', "edit");
  const canDelete = usePermission('tech-operations-groups', "delete");
  const canCreate = usePermission('tech-operations-groups', "create");

  const [createModalFlag, setCreateModalFlag] = useState();
  const [editId, setEditId] = useState();
  const [notificationsApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    getTechOperationsGroups();
  }, [viewAsUserMode]);

  const deleteItem = async (id) => {
    const { data } = await deleteTechOperationGroupsFx(id);

    if(data.error_message) {
      notificationsApi.error({
        message: data.error_message,
        duration: 5
      });
    } else {
      notificationsApi.success({
        message: 'Технологическая группа успешно удалена',
        duration: 5
      });
    }
  }

  const dropdownItems = []

  canEdit && dropdownItems.push({
    label: "Редактировать",
    onClick: (item) => {
      setEditId(item.id);
      setCreateModalFlag(true);
    }
  })

  canDelete && dropdownItems.push({
    label: "Удалить",
    onClick: (item) => deleteItem(item.id)
  });

  return (
    <div>

      <div className={styles.headerWrapper}>
        <h1 className={styles.pageHeading}>
          Технологические группы
        </h1>
        <div>
          {canCreate && (
            <Button
              onClick={() => {
                setCreateModalFlag(true);
                setEditId(false);
              }}
            >
              Создать
            </Button>
          )}
        </div>
      </div>

      <Divider/>

      <Table
        columns={[
          {
            title: "Название",
            dataIndex: "title",
            width: '30%'
          },
          {
            title: 'Описание операции',
            dataIndex: 'description',
          },
          {
            title: '',
            render: (row, field) => {
              return {
                props: {
                  style: { textAlign: 'right' }
                },
                children: (
                  <TableDropdown
                    items={dropdownItems}
                    row={row}
                  />
                )
              }
            }
          }
        ]}
        dataSource={data}
      />

      {createModalFlag && (
        <CreateModal
          onClose={() => setCreateModalFlag(false)}
          item={editId && data.find(item => item.id == editId)}
          notificationsApi={notificationsApi}
        />
      )}

      {contextHolder}
    </div>
  )
};

export default TechOperations;