import { createDomain, forward, createStore, createEvent, split, sample } from "effector";
import { get, urls } from "api";

const initialState = {
    isLoading: false,
    isOpen: false,
    modalContent: ""
}

const modalDomain = createDomain();
export const $manualModule = createStore(null);

// Events
export const openModal = modalDomain.createEvent();
export const closeModal = modalDomain.createEvent();
export const setModalContent = modalDomain.createEvent();

export const setHelpModule = createEvent();

// Effects
export const fetchModalContentFx = modalDomain.createEffect(async ({module, file}) => {
    const res = await get(urls.getHelp, {folder: module, file});
    if (res.data.status === "error")
        return `папка: ${module}, файл: ${file}.txt`;
    return res.data.text;
});

// Forwards
forward({
    from: openModal,
    to: fetchModalContentFx
})
forward({
    from: fetchModalContentFx.done,
    to: setModalContent
})
split({
    source: setHelpModule,
    match: {
        withParams: (p) => !!p,
        withoutParamas: (p) => !p
    },
    cases: {
        withParams: [$manualModule, fetchModalContentFx],
        withoutParamas: $manualModule
    }
})

// Store and Event handlers
export const $modalStore = modalDomain.createStore(initialState)
    .on(
        openModal,
        (state) => ({...state, isLoading: true, isOpen: true})
    )
    .on(
        closeModal,
        (state) => ({...state, isOpen: false})
    )
    .on(
        setModalContent,
        (state, res) => ({...state, isLoading: false, modalContent: res.result})
    );