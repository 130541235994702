import { Marker, Popup } from "react-leaflet";
import styles from "../index.module.scss";
import L from "leaflet";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Fragment, memo } from "react";
dayjs.extend(utc);

const stopIcon = new L.Icon({
    iconUrl: "https://local.tn-group.net/static/images/routes_add/stops.svg",
    iconSize: [20, 20],
    iconAnchor: [10, 20],
});
const parkingIcon = new L.Icon({
    iconUrl: "https://local.tn-group.net/static/images/routes_add/parkings.svg",
    iconSize: [20, 20],
    iconAnchor: [10, 20],
});
const fillingIcon = new L.Icon({
    iconUrl: "https://local.tn-group.net/static/images/routes_add/fillings.svg",
    iconSize: [20, 20],
    iconAnchor: [10, 20],
});
const antifillingIcon = new L.Icon({
    iconUrl: "https://local.tn-group.net/static/images/routes_add/thefts.svg",
    iconSize: [20, 20],
    iconAnchor: [10, 20],
});

function BadgePopup({ time, duration, type, value }) {
    const titleValues = {
        stop: "Остановка",
        parking: "Стоянка",
        filling: "Заправка",
        antifilling: "Слив",
    };
    const title = titleValues[type];
    const durationHours = Math.floor(duration / 60 / 60);
    const durationMinutes = Math.floor(duration / 60 - durationHours * 60);
    const durationSeconds = Math.floor(
        duration - (durationHours * 60 * 60 + durationMinutes * 60)
    );
    return (
        <Popup minWidth={90}>
            <div className={styles.badgeBody}>
                <div className={styles.badgeHeader}>
                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>{title}</span>
                    <span style={{ fontSize: "14px" }}>
                        {dayjs
                            .utc(new Date(time * 1000))
                            .utcOffset(3)
                            .format("DD.MM.YYYY HH:mm:ss")}
                    </span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>
                        Длительность:
                        {duration !== null
                            ? ` ${durationHours} ч. ${durationMinutes} мин. ${durationSeconds} сек.`
                            : "Н/Д"}
                    </span>
                    {value && (
                        <span>
                            Объем:
                            {value} л.
                        </span>
                    )}
                </div>
            </div>
        </Popup>
    );
}

export const Badges = memo(({ badges }) => {
    return (
        <>
            {badges.stopList?.map((el, index) => {
                return <Fragment key={`stopList${index}`}>
                    {el.r?.map((el, index) => {
                        if (!el.c[3]?.y || !el.c[3]?.x) {
                            return null;
                        }
                        return (
                            <Marker
                                key={index}
                                position={[el.c[3].y, el.c[3].x]}
                                icon={stopIcon}
                            >
                                <BadgePopup time={el.t2} duration={null} type={'stop'} />
                            </Marker>
                        );
                    })}
                </Fragment>
            })}
            {badges.parkingList?.map((el, index) => {
                return <Fragment key={`parkingList${index}`}>
                    {el.r?.map((el, index) => {
                        if (!(el.c[3]?.y || el.c[3]?.x)) {
                            return null;
                        }
                        return (
                            <Marker
                                key={index}
                                position={[el.c[3].y, el.c[3].x]}
                                icon={parkingIcon}
                                zIndexOffset={10}
                            >
                                <BadgePopup time={el.t2} duration={el.t2 - el.t1} type={'parking'} />
                            </Marker>
                        );
                    })}
                </Fragment>
            })}
            {badges.antifillingList?.map((el, index) => {
                return <Fragment key={`antifillingList${index}`}>
                    {el.r?.map((el, index) => {
                        if (!el.c[3]?.y || !el.c[3]?.x) {
                            return null;
                        }
                        return (
                            <Marker
                                key={index}
                                position={[el.c[3].y, el.c[3].x]}
                                icon={antifillingIcon}
                                zIndexOffset={20}
                            >
                                <BadgePopup time={el.t2} duration={null} type={'antifilling'} value={+el.c[6].split(' ')[0]} />
                            </Marker>
                        );
                    })}
                </Fragment>
            })}
            {badges.fillingList?.map((el, index) => {
                return <Fragment key={`fillingList${index}`}>
                    {el.r?.map((el, index) => {
                        if (!el.c[3]?.y || !el.c[3]?.x) {
                            console.log(el)
                            return null;
                        }
                        return (
                            <Marker
                                key={index}
                                position={[el.c[3].y, el.c[3].x]}
                                icon={fillingIcon}
                                zIndexOffset={19}
                            >
                                <BadgePopup time={el.t2} duration={null} type={'filling'} value={+el.c[8].split(' ')[0]} />
                            </Marker>
                        );
                    })}
                </Fragment>
            })}
        </>
    );
});
