import { Navigate, useLocation } from "react-router-dom";
import { usePermission } from "hooks/usePermission";
import { routes, routesPerm } from "routes";

const CheckPermission = ({children}) => {
    const {pathname} = useLocation();
    const permissionName = routesPerm[pathname?.replace(/\d+/g, ":id")] || "";

    const canView = usePermission(permissionName);

    if (!permissionName)
        return children;

    if (!canView)
        return <Navigate to={routes.profile}/>;

    return children;
}

export default CheckPermission;