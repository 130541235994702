import { useMemo } from "react";
import { Table, Button, Dropdown } from "antd";
import { usePermission } from "hooks/usePermission";
import { IoEllipsisHorizontal } from "react-icons/io5";


const tableColumns = [
    {
        title: "Название",
        dataIndex: "title",
        key: "title"
    },
    {
        title: "Описание",
        dataIndex: "description",
        key: "description"
    },
    {
        title: "Предприятие",
        dataIndex: "company",
        key: "company"
    },
    {
        title: "Объем склада",
        dataIndex: "size",
        key: "size"
    },
    {
        title: "Максимальный объем склада",
        dataIndex: "size_max",
        key: "size_max"
    },
    {
        width: "10%",
        render: (item) =>
            (item.onEdit || item.onDelete) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Редактировать", key: "editItem"},
                        {label: "Удалить", key: "deleteItem"},
                    ].filter(menuItem => menuItem.key === "editItem" ? item.onEdit : item.onDelete),

                    onClick: (dropDownItem) => {
                        switch (dropDownItem.key) {
                            case "deleteItem":
                                item.onDelete();
                                break;
                            case "editItem":
                                item.onEdit();
                                break;
                            default:
                                break;
                        }
                    }
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
]

const StorageTable = ({items, onEdit, onDelete}) => {
    const canEdit = usePermission("storage", "edit");
    const canDelete = usePermission("storage", "delete");

    const tableItems = useMemo(() =>
        items.map(item => ({
            ...item,
            company: item.company.name,
            onEdit: canEdit && (() => onEdit(item)),
            onDelete: canDelete && (() => onDelete(item))
        })), [items, canEdit, canDelete, onDelete, onEdit]);

    return (
        <Table
            columns={tableColumns}
            dataSource={tableItems || []}
        />
    );
}

export default StorageTable;