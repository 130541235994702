import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Divider } from "antd";
import { useStore, useStoreMap } from 'effector-react';
import { useNavigate, useParams } from "react-router-dom";
import TableDropdown from "../../components/TableDropdown";
import { CreateModal, CloseModal } from './modals';
import { usePermission } from "hooks/usePermission";
import { ImLock, ImUnlocked } from "react-icons/im";

import {
  getCultureRotationList,
  $cultureRotationStore,
  deleteCultureRotationFx,
} from "../../store/cultureRotation";
import styles from "../Fields/index.module.scss";
import {
  getCulturesList,
  $culturesList
} from "../../store/cultures";
import {
  fetchingFields,
  $fieldsList
} from "../../store/fields";
import { $userStore } from 'store/user';

const CropRotation = (props) => {
  const { id: fieldId } = useParams();

  const cultureRotationList = useStore($cultureRotationStore);
  const {viewAsUserMode} = useStore($userStore);
  const culturesList = useStore($culturesList);
  const field = useStoreMap($fieldsList, (fields) => fields.find(item => item.id == fieldId));
  const navigate = useNavigate();

  const canEdit = usePermission("culture-rotation", "edit");
  const canDelete = usePermission("culture-rotation", "delete");
  const canView = usePermission("culture-rotation", "view");
  const canCreate = usePermission("culture-rotation", "create");

  const [createModalFlag, setCreateModalFlag] = useState();
  const [closeModalFlag, setCloseModalFlag] = useState();
  const [editId, setEditId] = useState();
  const [closeId, setCloseId] = useState();
  const [notificationsApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    getCultureRotationList(fieldId);
    getCulturesList();
    fetchingFields(1);
  }, [fieldId, viewAsUserMode]);

  const deleteCultureRotation = async (cultureRotationId) => {
    const { data } = await deleteCultureRotationFx(cultureRotationId);

    if(data.error_message) {
      notificationsApi.error({
        message: data.error_message,
        duration: 5
      });
    } else {
      notificationsApi.success({
        message: 'Севооборот успешно удален',
        duration: 5
      });
    }
  }

  const dropdownItems = []

  canEdit && dropdownItems.push({
    label: "Редактировать",
    onClick: (item) => {
      setEditId(item.id);
      setCreateModalFlag(true);
    }
  })


  canEdit && dropdownItems.push({
    label: "Закрыть",
    onClick: (item) => {
      setCloseId(item.id);
      setCloseModalFlag(true);
    },
  });

  canDelete && dropdownItems.push({
    label: "Удалить",
    onClick: (item) => deleteCultureRotation(item.id)
  })

  if(!canView){
    return navigate('/fields');
  }

  return (
    <div>

      <div className={styles.headerWrapper}>
        <h1 className={styles.pageHeading}>
          Севооборот, {field ? `Поле ${field.name} ${field.description}` : ''}
        </h1>
        <div>
          {canCreate && (
            <Button
              onClick={() => {
                setCreateModalFlag(true);
                setEditId(false);
              }}
            >
              Создать
            </Button>
          )}
        </div>
      </div>

      <Divider/>

      <Table
        columns={[
          {
            title: '',
            width: 30,
            render: (row, field) => (
              field.date_close ? <ImLock /> : <ImUnlocked />
            )
          },
          {
            title: "Культура",
            dataIndex: "culture",
          },
          {
            title: 'Цвет',
            dataIndex: 'color',
            render: (value) => (
              <div style={{
                width: 20,
                height: 20,
                borderRadius: 5,
                border: "1px solid black",
                backgroundColor: value,
              }}/>
            )
          },
          {
            title: 'Картинка',
            dataIndex: 'image',
            render: (image, srcItem) =>
              <img
                style={{ width: 20, height: 20, }}
                src={URL.createObjectURL(new Blob([image], {type: 'image/svg+xml'}))}
                alt={srcItem.name}
              />
          },
          {
            title: "Дата посева",
            dataIndex: "date_start",
          },
          {
            title: "Планируемая дата уборки",
            dataIndex: "date_plan",
          },
          {
            title: "Дата уборки",
            dataIndex: "date_close",
          },
          {
            title: '',
            render: (row, field) => {
              return {
                props: {
                  style: { textAlign: 'right' }
                },
                children: (
                  <TableDropdown
                    items={dropdownItems}
                    row={row}
                  />
                )
              }
            }
          }
        ]}
        dataSource={cultureRotationList.map(item => ({
          ...item,
          color: culturesList.find(culture => culture.name == item.culture)?.color,
          image: culturesList.find(culture => culture.name == item.culture)?.image,
        }))}
      />

      {createModalFlag && (
        <CreateModal
          onClose={() => setCreateModalFlag(false)}
          item={editId && cultureRotationList.find(item => item.id == editId)}
          notificationsApi={notificationsApi}
        />
      )}
      {closeModalFlag && (
        <CloseModal
          onClose={() => setCloseModalFlag(false)}
          item={closeId && cultureRotationList.find(item => item.id == closeId)}
          notificationsApi={notificationsApi}
        />
      )}

      {contextHolder}
    </div>
  )
};

export default CropRotation;