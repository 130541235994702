import {Button, Divider, Dropdown, notification} from 'antd';
import { useStore } from 'effector-react';
import { PageHeader } from './PageHeader';
import StorageTable from './StorageTable';
import { $storageStore, deleteStorageFx, editStorageFx, getStorageListFx } from 'store/storage';
import { $userStore } from 'store/user';
import { useCallback, useEffect, useState } from 'react';
import CreateStorageModal from './CreateStorageModal';
import EditStorageModal from './EditStorageModal';

const Storage = () => {
    const {list: storageList} = useStore($storageStore);
    const {viewAsUserMode} = useStore($userStore);
   

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editableItem, setEditableItem] = useState({});


    const deleteItem = useCallback((storage) => {
        deleteStorageFx(storage)
            .then(res => {
                if (res.data?.status === 'ok') {
                    notification.success({message: 'Склад удален'})
                } else {
                    notification.error({message: 'Не удалось удалить склад'})
                }
            }).finally(() => getStorageListFx()) 
    }, []);

    const createStorage = useCallback((data) => {
        editStorageFx(data).then(res => {
            if (res.data?.status === 'ok') {
                notification.success({message: 'Склад успешно создан'})
            } else {
                notification.error({message: res.data?.error_message || 'Не удалось создать склад'})
            }
        }).finally(() => getStorageListFx())
    }, []);

    const editStorage = useCallback((data) => {
        editStorageFx(data).then(res => {
            if (res.data?.status === 'ok') {
                notification.success({message: 'Cклад успешно изменен'})
            } else {
                notification.error({message: res.data?.error_message || 'Не удалось изменить склад'})
            }
        }).finally(() => getStorageListFx())
    }, [])

    useEffect(() => {
        getStorageListFx()
    }, [viewAsUserMode])

    return <>
        <PageHeader onClick={() => setShowCreateModal(true)} />
        <Divider />
        <StorageTable items={storageList} onEdit={item => {
            setShowEditModal(true);
            setEditableItem(item);
        }} onDelete={deleteItem} />
        <CreateStorageModal onCancel={() => setShowCreateModal(false)} onSubmit={createStorage} open={showCreateModal} />
        <EditStorageModal onCancel={() => setShowEditModal(false)} activeItem={editableItem} onSubmit={editStorage} open={showEditModal} />
    </>
}

export default Storage;