import { Divider, notification } from "antd"
import { PageHeader } from "./PageHeader"

import WeighingTable from "./WeighingTable";
import { useStore } from "effector-react";
import { $weighingStore, deleteWeighingFx, editWeighingFx, getWeighingListFx, getWeighingYearsFx } from "store/weighing";
import { $userStore } from "store/user";
import CreateWeighingModal from "./CreateWeighingModal";
import { useCallback, useEffect, useState } from "react";
import EditWeighingModal from "./EditWeighingModal";

const Weighing = () => {
    const {list: weighingList, year} = useStore($weighingStore);
    const {viewAsUserMode} = useStore($userStore);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editableItem, setEditableItem] = useState({});

    const createItem = useCallback((data) => {
        editWeighingFx(data).then(res => {
            if (res.data?.status === 'ok') {
                notification.success({message: 'Взвешивание успешно добавлено'})
            } else {
                notification.error({message: res.data?.error_message || 'Не удалось добавить взвешивание'})
            }
        }).finally(() => {
            getWeighingListFx();
            getWeighingYearsFx();
        })
    }, []);

    const deleteItem = useCallback((data) => {
        deleteWeighingFx(data)
            .then(res => {
                if (res.data?.status === 'ok') {
                    notification.success({message: 'Взвешивание удалено'})
                } else {
                    notification.error({message: res.data?.error_message || 'Не удалось удалить взвешивание'})
                }
            }).finally(() => getWeighingListFx()) 
    }, []);

    const editItem = useCallback((data) => {
        editWeighingFx(data)
            .then(res => {
                if (res.data?.status === 'ok') {
                    notification.success({message: 'Взвешивание изменено'})
                } else {
                    notification.error({message: res.data?.error_message || 'Не удалось изменить взвешивание'})
                }
            }).finally(() => getWeighingListFx()) 
    }, []);

    useEffect(() => {
        getWeighingListFx(year);
    }, [year, viewAsUserMode]);

    return <>
        <PageHeader onClick={() => setShowCreateModal(true)} />
        <Divider />
        <WeighingTable
            items={weighingList} 
            onDelete={deleteItem} 
            onEdit={item => {
                setEditableItem(item);
                setShowEditModal(true);
            }} 
        />
        <CreateWeighingModal
            open={showCreateModal}
            onSubmit={createItem}
            onCancel={() => setShowCreateModal(false)}
        />
        <EditWeighingModal
            editableItem={editableItem}
            open={showEditModal}
            onSubmit={editItem}
            onCancel={() => setShowEditModal(false)}
        />
    </>
}

export default Weighing