import React from 'react'
import {QuestionCircleOutlined} from "@ant-design/icons";
import { openModal } from 'store/helpModal';
import styles from "./index.module.scss";

export const ModalTitleForHelp = ({title = "", helpFile}) => { // helpFile type: {module: string, file: string}
  return (
    <div className={styles.title}>
      {title}
      <QuestionCircleOutlined
        className={styles.icon}
        onClick={() => openModal(helpFile)}
      />
    </div>
  )
}
