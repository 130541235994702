import { Table, Dropdown, Button } from "antd";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { useMemo } from "react";
import { usePermission } from "hooks/usePermission";

const columns = [
    {
        title: "Предприятие",
        dataIndex: "company",
        key: "company",
        render: ({name}) => name
    },
    {
        title: "Подразделение",
        dataIndex: "section",
        key: "section",
        render: ({title}) => title
    },
    {
        title: "Ставка",
        dataIndex: "percentage",
        key: "percentage"
    },
    {
        title: "Оклад",
        dataIndex: "salary",
        key: "salary"
    },
    {
        width: "10%",
        render: (item) =>
            (item.onEdit || item.onDelete) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Редактировать", key: "editItem"},
                        {label: "Удалить", key: "deleteItem"},
                    ].filter(menuItem => menuItem.key === "editItem" ? item.onEdit : item.onDelete),
                    onClick: (dropDownItem) =>
                        dropDownItem.key === "deleteItem"
                            ? item.onDelete()
                            : item.onEdit()
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
];

const UserWorkersTable = ({items, onDelete, onEdit}) => {
    const canEdit = usePermission("worker", "edit");
    const canDelete = usePermission("worker", "delete");

    const data = useMemo(() =>
        items.map(item => ({
            ...item,
            onEdit: canEdit && (() => onEdit(item)),
            onDelete: canDelete && (() => onDelete(item)),
        })),
        [items, onDelete, onEdit, canEdit, canDelete]
    );

    return (
        <Table
            dataSource={data}
            columns={columns}
        />
    );
}

export default UserWorkersTable;