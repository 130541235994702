import React, { useEffect } from 'react';
import { useStore } from "effector-react";
import { Button, Modal } from "antd";
import { Form } from 'components/Form';
import { useForm, Controller } from "react-hook-form";

import { closeCultureRotationFx } from "store/cultureRotation";
import { getCulturesList } from "store/cultures";
import helpMessageRoutes from 'helpMessageRoutes';

import { ModalTitleForHelp } from 'components/ModalTitleForHelp';

import st from "../style.module.scss";
import { useParams } from "react-router-dom";

const items = [
  {
    label: 'Дата уборки',
    name: 'date_close',
    type: 'date',
    rules: { required: 'Не указана предполагаемая дата уборки' }
  },
]

export const CloseModal = ({
  item,
  notificationsApi,
  onClose
}) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      date_close: ''
    }
  });
  const { id: fieldId } = useParams();

  useEffect(() => {
    getCulturesList();
  }, [])

  const onSubmit = async (props) => {
    const { culture, date_close } = props;

    const { data } = await closeCultureRotationFx({
      culture,
      date_close,
      plantation: fieldId,
      cultureRotation: item
    });

    if(data.error_message) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    } else {
      notificationsApi.success({
        message: 'Севооборот успешно закрыт',
        duration: 5
      });

      onClose();
    }
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={"Закрытие записи о севообороте"}
          helpFile={helpMessageRoutes.culturesRotation.close}
        />
      }
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <form
        className={st.modalForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        {items.map((props) => (
          <Form.Input
            input={props}
            form={{ control, formState }}
          />
        ))}
        <div className={st.modalFooter}>
          <Button onClick={onClose}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  );
};