// React
import { useState } from "react";
import { useEffect } from "react";

// UI
import { Divider, notification } from "antd";

// Store
import { useStore } from "effector-react";
import { $rolesStore, fetchRolesFx, removeRoleFx } from "store/role";
import { $userStore } from "store/user";

// Components
import { PageHeader } from "./components/PageHeader/PageHeader";
import { RolesTable } from "./components/RolesTable/RolesTable";
import { BaseModal } from "./components/Modals/BaseModal";

const Roles = () => {
  const {isError, rolesList} = useStore($rolesStore);
  const {viewAsUserMode} = useStore($userStore);
  const [modalState, setModalState] = useState({
    mode: "create", // "create" or "edit"
    isOpen: false,
    role: null // choosed role
  });

  // Callbacks
  const onEditRole = (role) => setModalState({...modalState, mode: "edit", isOpen: true, role});
  const onRemoveRole = (id) => {
    try {
      removeRoleFx(id)
        .then(res => res.status === "ok" && notification.success({message: "Роль успешно удалена"}))
        .catch(e => notification.error({message: "Не удалось удалить роль"}));
    } catch(e) {
      notification.error({message: "Не удалось удалить роль"});
    }
  }

  useEffect(() => {
    fetchRolesFx();
  }, [viewAsUserMode]);

  return (
    <section>
        <PageHeader onClick={() => setModalState({...modalState, mode: "create", isOpen: true})}/>
        <Divider />
        <RolesTable
          onEditRole={onEditRole}
          onRemoveRole={onRemoveRole}
          roles={isError ? [] : rolesList}
        />
        <BaseModal
          mode={modalState.mode}
          role={modalState.role}
          open={modalState.isOpen}
          destroyOnClose
          onCancel={() => setModalState({...modalState, isOpen: false})}
        />
     </section>
  )
}

export default Roles;