import { Dropdown, Table, Button } from "antd";
import { usePermission } from "hooks/usePermission";
import { useMemo } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

const tableColumns = [
    {
        title: "Название",
        dataIndex: "title",
        key: "name"
    },
    {
        title: "Цвет",
        dataIndex: "color",
        key: "color",
        width: "10%",
        render: (value) =>
            <div style={{
                width: 20, height: 20,
                borderRadius: 5,
                border: "1px solid black",
                backgroundColor: value,
            }}>
            </div>
    },
    {
        title: "Картинка",
        dataIndex: "image",
        width: "10%",
        key: "name",
        render: (image, srcItem) => 
            <img
                style={{
                    width: 20,
                    height: 20,
                }}
                src={
                    (() => URL.createObjectURL(new Blob([image], {type: 'image/svg+xml'})))()
                }
                alt={srcItem.name}
            />
    },
    {
        key: "actions",
        width: "10%",
        render: (item) => 
            (item.onEdit || item.onDelete) &&
            <Dropdown
                menu={{
                    items: [
                        {label: "Редактировать", key: "editItem"},
                        {label: "Удалить", key: "deleteItem"},
                    ].filter(menuItem => menuItem.key === "editItem" ? item.onEdit : item.onDelete),
                    onClick: (dropDownItem) =>
                        dropDownItem.key === "deleteItem"
                            ? item.onDelete()
                            : item.onEdit()
                }}
                trigger="click"
            >
                <Button>
                    <IoEllipsisHorizontal height={30} width={30} />
                </Button>
            </Dropdown>
    }
]

const CulturesTable = ({items, onDelete, onEdit}) => {
    // Bind callback with each item of list
    const canEdit = usePermission("culture", "edit");
    const canDelete = usePermission("culture", "delete");
    const itemsInner = useMemo(() => 
        items.map(item => ({
            ...item,
            onEdit: canEdit && (() => onEdit(item)),
            onDelete: canDelete && (() => onDelete(item.name)),
        }))
    , [items, canEdit, canDelete, onDelete, onEdit]);
    return (
        <Table
            columns={tableColumns.filter(column => column.key === "actions" ? (canEdit || canDelete) : true)}
            dataSource={itemsInner}
        />
    );
}

export default CulturesTable;