import { createStore, createEvent, createEffect, forward } from "effector";
import { urls, get, post } from '../api';
import { format } from 'date-fns';

export const $userList = createStore([]);

export const setUserList = createEvent();
export const addToUserList = createEvent();
export const sendForgotPassword = createEvent();
export const changeUser = createEvent();

export const getUserListFx = createEffect(async () => {
  const res = await get(urls.adminUsersList);
  return res;
});

export const createNewUserFx = createEffect(async (props) => {
  const res = await post({
    module: 'admin',
    action: 'user_add',
    ...props
  });
  return res;
})

export const sendForgotPasswordFx = createEffect(async ({ mail }) => {
  const res = await get(urls.adminForgotPassword, { mail });
  return res;
});

export const changeUserFx = createEffect(async ({ props, id }) => {
  const res = await post({ module: 'admin', action: 'user_edit', uid: id, data: JSON.stringify(props) })
  return res;
})

export const blockUserFx = createEffect(async (uid) => {
  const res = await get(urls.blockUserById, { uid });
  return res;
});

export const unblockUserFx = createEffect(async (uid) => {
  const res = await get(urls.unblockUserById, {uid})
  return res;
});


$userList.on(setUserList, (store, { result }) => {
  return result.data.list;
})

$userList.on(addToUserList, (store, { params, result }) => {
  const { data } = result;
  const { mail } = params;

  const login = mail.slice(0, mail.search('@'))

  if(!data.error_msg){
    return [...store, { ...params, login, created: format(Date.now(), 'yyyy-LL-mm kk-mm-ss') }];
  }
})

$userList.on(changeUser, (store, { params, result }) => {
  const { id, props } = params;

  return store.map(item => item.id == id ? {...item, ...props} : item)
})


forward({
  from: getUserListFx.done,
  to: setUserList
})

forward({
  from: createNewUserFx.done,
  to: addToUserList
})

forward({
  from: changeUserFx.done,
  to: changeUser
})

forward({
  from: blockUserFx.done,
  to: getUserListFx
})

forward({
  from: unblockUserFx.done,
  to: getUserListFx
});