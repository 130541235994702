import { useStore } from 'effector-react';
import {Divider} from 'antd'

import PageHeader from './PageHeader';
import { useEffect } from 'react';
import Statistics from 'components/Statistics';
import { $weighingStore, getWeighingListFx } from 'store/weighing';
import { $userStore } from 'store/user';

const StorageCopacity = () => {
    const {list, year} = useStore($weighingStore);
    const {viewAsUserMode} = useStore($userStore);

    useEffect(() => {
        getWeighingListFx(year);
    }, [year, viewAsUserMode])

    return <>
        <PageHeader />
        <Divider />
        <Statistics
            calcType={'percent'}
            columnNames={['Склад', 'Процент заполненности']}
            data={list}
            divisionBy={'weigh'}
            groupBy={'weighing_storage'}
            sumBy={'weigh_brutto'}
            type={'percent-chart'}
        />
        <Statistics
            calcType={'percent'}
            columnNames={['Название склада', 'Загружено', 'Максимальная загруженность',  'Процент заполненности',]}
            data={list}
            divisionBy={'weigh'}
            groupBy={'weighing_storage'}
            sumBy={'weigh_brutto'}
            type={'table'}
        />
    </>
}

export default StorageCopacity;