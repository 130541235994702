import { useCallback, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { useNavigate } from "react-router-dom";
import { $sectionsStore, getSectionsFx, editSectionFx, deleteSectionFx } from "store/sections";
import { $userStore } from "store/user";
import { routes } from "routes";
import { Divider, notification } from "antd";

import { PageHeader } from "./PageHeader";
import CreateSectionModal from "./CreateSectionModal";
import EditSectionModal from "./EditSectionModal";
import SectionsTable from "./SectionsTable";

const Sections = () => {
    const {list: sectionList} = useStore($sectionsStore);
    const {viewAsUserMode} = useStore($userStore);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editableItem, setEditableItem] = useState({});
    const navigate = useNavigate();

    // Fetch sections
    useEffect(() => {
        getSectionsFx();
    }, [viewAsUserMode]);

    // Callbacks
    const editSection = useCallback(({section, company, ...data}) => {
        editSectionFx({company, section, data: JSON.stringify(data)})
            .then(res => {
                res.data?.status === "ok"
                    ? notification.success({message: "Подразделение изменено"})
                    : notification.error({message: "Не удалось изменить подразделениe"});
                setEditableItem({});
                setShowEditModal(false);
            })
            .catch(() => notification.error({message: "Не удалось изменить подразделение"}));
    }, []);

    const createSection = useCallback(({company, ...data}) => {
        editSectionFx({company, data: JSON.stringify(data)})
            .then(res => {
                res.data?.status === "ok"
                    ? notification.success({message: "Подразделение создано"})
                    : notification.error({message: "Не удалось создать подразделениe"});
                setShowCreateModal(false);
            })
            .catch(() => notification.error({message: "Не удалось создать подразделениe"}));
    }, []);

    const deleteSection = useCallback((item) => {
        deleteSectionFx(item)
            .then(res => {
                res.data.status === "ok"
                    ? notification.success({message: "Подразделение удалено"})
                    : notification.error({
                        message: res.data.code === 1451
                            ? res.data.error_message.includes("worker") ? "Подразделение содержит сотрудников" : "Подразделение содержит технику"
                            : "Не удалось удалить подразделениe"
                    })
            })
            .catch(() => notification.error({message: "Не удалось удалить подразделениe"}));
    }, []);

    const gotoStaffPage = useCallback((item) => navigate(routes.staff.replace(":id", item.id)), [navigate])

    return (
        <>
        <PageHeader
            onClick={() => setShowCreateModal(true)}
        />
        <Divider />
        <SectionsTable
            items={sectionList}
            onStaffView={gotoStaffPage}
            onEdit={(item) => {
                setShowEditModal(true);
                setEditableItem(item);
            }}
            onDelete={deleteSection}
        />
        <EditSectionModal
            open={showEditModal}
            onSubmit={editSection}
            onCancel={() => setShowEditModal(false)}
            activeItem={editableItem}
        />
        <CreateSectionModal
            open={showCreateModal}
            onSubmit={createSection}
            onCancel={() => setShowCreateModal(false)}
        />
        </>
    );
}

export default Sections;