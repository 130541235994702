import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Dropdown } from "antd";
import { CgProfile } from 'react-icons/cg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { UnorderedListOutlined, QuestionCircleOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import HelpModal from 'components/HelpModal';
import ModalDocForAdmin from 'components/PermissionModal';

import { useStore } from "effector-react";

import { $userStore, $viewAsAdminUser, clearUser, disableViewFromUser } from "store/user";
import { openSidebar } from 'store/ui';
import { openModal, $manualModule } from 'store/helpModal';

import { getHelpByPath } from './helpers';

import { routes } from 'routes';

import st from './index.module.scss';

const Header = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { authData, viewAsUserMode, user } = useStore($userStore);
  const { user: adminUser } = useStore($viewAsAdminUser);
  const manualModule = useStore($manualModule);

  const navigate = useNavigate();
  const location = useLocation();

  const userLogout = () => {
    clearUser();
    localStorage.removeItem('user');
    navigate(routes.login);
  }

  const dropdowItems = [
    {
      key: '1',
      label: (
        <Link to={routes.profile}>
          <div className={st.dropdownItem}>
            Профиль
          </div>
        </Link>
      ),
    },
  ]

  !viewAsUserMode && dropdowItems.push({
    key: '2',
    label: (
      <div
        onClick={userLogout}
        className={st.dropdownItem}
      >
        Выйти
      </div>
    ),
  })

  const userName = () => {
    const values = (viewAsUserMode ? adminUser : user);
    return values.name ? `${values.name} ${values.surname}` : values.login
  }

  return (
    <div className={st.header}>
      <GiHamburgerMenu onClick={openSidebar} style={{marginInline: '11px 10px', cursor: "pointer"}} />
      <Link to="/" className={st.logo}>
        <div>Agro</div>
      </Link>
      <div className={st.headerRight}>
        {(user.group === 1 && (location.pathname != '/fields/all/map')) &&
          <UnorderedListOutlined
            onClick={() => setIsModalOpened(true)}
          />
        }
        <QuestionCircleOutlined
          className={st.helpIcon}
          onClick={() => openModal(manualModule || getHelpByPath(location.pathname))}
        />
        {(authData && authData.id) ?
          <div className={st.profile}>
            <Dropdown
              menu={{ items: dropdowItems }}
            >
              <div className={st.profileAvatar}>
                {userName()}
                <CgProfile height={30} width={30} />
              </div>
            </Dropdown>
          </div> :
          <>
            <Link to={routes.login}>
              <Button>Вход</Button>
            </Link>
            <Link to={routes.registration}>
              <Button type="primary">Регистрация</Button>
            </Link>
          </>
        }
        {viewAsUserMode &&
          <Button
            className={st.login}
            onClick={() => disableViewFromUser()}
          >
            Вернуться
          </Button>
        }
      </div>
      <HelpModal />
      {user.group === 1 && <ModalDocForAdmin open={isModalOpened} onCancel={() => setIsModalOpened(false)} />}
    </div>
  );
};

export default Header;