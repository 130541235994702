import { Button } from "antd";
import { usePermission } from "hooks/usePermission";
import styles from "./page-header.module.scss";

export const PageHeader = ({group, onClick}) => {
  const canEdit = usePermission("usersGroups", "edit"); // usePermission("user", "edit");

  return (
    <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>Роли группы {group?.title}</h2>
        { canEdit &&
          <Button
            onClick={onClick}
          >
            Сохранить изменения
          </Button>
        }
    </div>
  )
}
