import Modal from "antd/es/modal/Modal";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import CreateWorkerForm from "./CreateWorkerForm";
import helpMessageRoutes from "helpMessageRoutes";

const EditWorkerModal = ({opened, onCancel, onSubmit, activeWorker}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Редактировать место работы" helpFile={helpMessageRoutes.worker.edit}/>}
            open={opened}
            onCancel={onCancel}
            footer={null}
            destroyOnClose
        >
            <CreateWorkerForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitTitle={"Сохранить"}
                {...activeWorker}
            />
        </Modal>
    );
}

export default EditWorkerModal;