import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, forward } from "effector";

// Store
export const $sectionsStore = createStore({
    list: []
}); 

// Events
const setSections = createEvent();

// Effects
export const getSectionsFx = createEffect(async () => {
    const res = await get(urls.sectionList);
    return res;
});

export const getSectionsByCompanyFx = createEffect(async (company) => {
    const res = await get(urls.sectionListByCompany, {company: company.id});
    return res;
});

export const editSectionFx = createEffect(async (payload) => {
    const res = await post({
        module: "section",
        action: "edit",
        ...payload
    });
    return res;
});

export const deleteSectionFx = createEffect(async (section) => {
    const res = await get(urls.sectionDelete, {section: section.id, company: section.company.id});
    return res;
});

// Forwards
forward({
    from: getSectionsFx.done,
    to: setSections
});

forward({
    from: editSectionFx.done,
    to: getSectionsFx
});

forward({
    from: deleteSectionFx.done,
    to: getSectionsFx
});

// Event handlers
$sectionsStore
    .on(setSections, (_, {result}) => ({list: result.data.list || []}));
