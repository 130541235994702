import React from 'react';
import { useParams } from "react-router-dom";

import { useStore } from "effector-react";
import { $usersGroupsStore } from "store/userGroups";
import { $groupRolesStore, saveGroupPermissionsFx } from "store/groupRoles";

import { Divider } from 'antd';
import { notification } from "antd";

import { PageHeader } from "./PageHeader/PageHeader";
import { RolesTable } from "./RolesTable";

const GroupRoles = () => {
    const {id} = useParams();
    const store = useStore($usersGroupsStore);
    const {roles, editedRoles} = useStore($groupRolesStore);

    const handleSave = () => {
        roles
          .filter(role => editedRoles.has(role.id))
          .forEach(role => {
              saveGroupPermissionsFx({gid: id, role})
                .then(res => {
                  if (res === false)
                    notification.error({key: "groupPermissions", message: "Ошибка сохранения прав"})
                  else
                    notification.success({key: "groupPermissions", message: "Права доступа сохранены"});
                })
                .catch(e => notification.error({key: "groupPermissions", message: "Ошибка сохранения прав"}))
          })
    }

    return (
        <>
            <PageHeader
                group={store.find(group => group.id === parseInt(id))}
                onClick={handleSave}
            />
            <Divider />
            <RolesTable groupId={id}/>
        </>
    );
}

export default GroupRoles;