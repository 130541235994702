import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import { Button } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { Badges } from "../components/Badges";
import { PopoverMenu } from "../components/Popover";

import {
  $techniqueMonitoringStore,
  clearTechMonitoring,
  getTechMonitoringSessionFx,
} from "store/techniqueMonitoring";
import { updateWialonSession } from "api/wialonApi";
import getNearestItem from "../helpers/getNearestItem";
import { TrackLines } from "./TrackLines";
import PolylineDecorator from "../components/ArrowDecorator";

import styles from "../index.module.scss";
import "leaflet/dist/leaflet.css";
import { Leftbar } from "../components/Leftbar";
import { Table } from "../components/Table";
import { AuthModal } from "../components/AuthModal";
import { FieldsGeoJson } from "../components/FieldsGeoJson";

const marker = new L.Icon({
  iconUrl: "https://local.tn-group.net/static/images/marker.svg",
  iconSize: [20, 20],
  iconAnchor: [10, 20],
});


const TechniqueMonitoring = () => {
  const techMonitoring = useStore($techniqueMonitoringStore);
  const [map, setMap] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);
  const [popoverItem, setPopoverItem] = useState({});
  const [isShowLeftbar, setIsShowLeftbar] = useState(true);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [authModalOpen, setAuthModalOpen] = useState(true);

  const handleTrackClick = useCallback((itemPos, list, clickPos, technique) => {
    setMousePosition(clickPos);
    const nearestItem = getNearestItem(itemPos, list);
    setPopoverItem({
      technique,
      nearestItem,
    });
  }, []);

  useEffect(() => {
    getTechMonitoringSessionFx();

    const intervalId = setInterval(() => {
      updateWialonSession();
    }, 5000);
    return () => {
      clearInterval(intervalId);
      clearTechMonitoring();
    };
  }, []);


  useEffect(() => {
    if (!map) return;
    if (techMonitoring.report.length > 0 && techMonitoring.report[0].track.length > 0) {

      const pos = techMonitoring.report[0]?.track[0]?.[0]?.pos;
      if (pos) {
        map.setView([pos.y, pos.x], 12);
      }
    }
  }, [techMonitoring.report, map]);

  useEffect(() => {
    let timerId;
    if (map && markerPosition) {
      map.setView(markerPosition, 16);
      timerId = setTimeout(() => setMarkerPosition(null), 3000);
    }
    return () => {
      clearTimeout(timerId);
    }
  }, [markerPosition, map]);

  return (
    <div className={styles.monitoring}>
      <MapContainer
        style={{ width: "100%", height: "100%", zIndex: 10, left: 0, right: 0, top: 0, bottom: 0 }}
        zoomControl={true}
        center={[50.82567414095759, 43.98719787597657]}
        zoom={13}
        ref={setMap}
        scrollWheelZoom={true}
      >
        <TileLayer url="http://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
        {techMonitoring.report.map(({ badges, technique, track }, index) => {
          return <Fragment key={index}>
            <TrackLines track={track} technique={technique} handleTrackClick={handleTrackClick} />
            <Badges badges={badges} />
            <PolylineDecorator polyline={track} />
          </Fragment>
        })}
        <FieldsGeoJson />
        {markerPosition && <Marker zIndexOffset={50} icon={marker} position={markerPosition} />}
      </MapContainer>
      <PopoverMenu
        mousePosition={mousePosition}
        clickedItem={popoverItem}
        closePopover={() => {
          setMousePosition(null);
          setPopoverItem({});
        }}
      />
      <div className={styles.mapWrapper}>
        <Leftbar
          showLeftbar={isShowLeftbar}
          setShowLeftbar={setIsShowLeftbar}
          isMultiply={false}
        />
        <Button
          style={{ zIndex: 101, left: 50, top: 25 }}
          onClick={() => setIsShowLeftbar(true)}
        >
          Показать
        </Button>
        <Table isShowLeftbar={isShowLeftbar} setMarkerPosition={setMarkerPosition} />
      </div>
      <PopoverMenu
        mousePosition={mousePosition}
        clickedItem={popoverItem}
        closePopover={() => {
          setMousePosition(null);
          setPopoverItem({});
        }}
      />
      <AuthModal isOpen={authModalOpen} close={() => setAuthModalOpen(false)} />
    </div>
  );
};

export default TechniqueMonitoring;
