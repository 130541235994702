import { Modal, Form, Input, Button, notification } from "antd";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import helpMessageRoutes from "helpMessageRoutes";
import { editRoleFx } from "store/role";

import styles from "./index.module.scss";

export const EditRoleModal = ({role, onCancel, ...props}) => {

  const handleEdit = async (data) => {
    try {
      const res = await editRoleFx({id: role.id, title: data.roleName, url: data.roleURL});
      if (res.error_message) {
        notification.error({message: res.error_message})
      } else {
        notification.success({message: "Роль успешно отредактирована"})
        onCancel();
      }
    } catch(e) {
      notification.error({message: "Не удалось отредактировать роль"})
    }
  }

  return (
    <Modal
        title={ <ModalTitleForHelp title="Изменение роли" helpFile={helpMessageRoutes.role.edit} /> }
        footer={null}
        onCancel={onCancel}
        {...props}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        onFinish={handleEdit}
        initialValues={{
          roleName: role.title,
          roleURL: role.url
        }}
      >
        <Form.Item
          label="Название роли"
          name="roleName"
          required={false}
          rules={[{ required: true, message: 'Введите название роли' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="URL роли"
          name="roleURL"
          required={false}
          rules={[{ required: true, message: 'Введите URL роли' }]}
        >
          <Input />
        </Form.Item>



        <Form.Item>
          <div className={styles.buttonsWrapper}>
            <Button onClick={onCancel} style={{marginRight: 10, marginLeft: "auto"}}>
              Отмена
            </Button>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

