import { Modal, Form, Input, Button, notification } from "antd";

import { ModalTitleForHelp } from "components/ModalTitleForHelp";

import { addRoleFx } from "store/role";

import helpMessageRoutes from "helpMessageRoutes";

import styles from "./index.module.scss";

export const CreateRoleModal = ({onCancel, ...props}) => {
  const handleCreate = async (data) => {
    try {
      const res = await addRoleFx({title: data.roleName, url: data.roleURL});
      res.error_message
        ? notification.error({message: res.error_message})
        : notification.success({message: "Роль успешно добавлена"})
      onCancel();
    } catch(e) {
        notification.error({message: "Не удалось создать роль"})
    }
  }

  return (
    <Modal
        title={ <ModalTitleForHelp title="Создание роли" helpFile={helpMessageRoutes.role.create} /> }
        footer={null}
        onCancel={onCancel}
        {...props}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        onFinish={handleCreate}
      >
        <Form.Item
          label="Название роли"
          name="roleName"
          required={false}
          rules={[{ required: true, message: 'Введите название роли' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="URL роли"
          name="roleURL"
          required={false}
          rules={[{ required: true, message: 'Введите URL роли' }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item>
          <div className={styles.buttonsWrapper}>
            <Button onClick={onCancel}>
              Отмена
            </Button>
            <Button type="primary" htmlType="submit">
              Создать
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}
