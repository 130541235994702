import Modal from "antd/es/modal/Modal";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import UpdateEmploeeForm from "./UpdateEmploeeForm";
import helpMessageRoutes from "helpMessageRoutes";

const EditEmploeeModal = ({onSubmit, activeItem, section, company, id, ...props}) => {
    
    return (
        <Modal
            title={<ModalTitleForHelp title="Редактирование сотрудника" helpFile={helpMessageRoutes.sections.staffEdit}/>}
            footer={null}
            destroyOnClose
            {...props}
        >
            <UpdateEmploeeForm
                onSubmit={onSubmit}
                onCancel={props.onCancel}
                submitTitle="Изменить"
                section={section}
                company={company}
                id={activeItem.workerId}
                {...activeItem}
            />
        </Modal>
    )
}

export default EditEmploeeModal;
