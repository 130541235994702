import {
  createStore,
  createEvent,
  createEffect,
  forward
} from "effector";
import { urls, get, post } from '../api';

export const $userStore = createStore({
  mail: null,
  authData: {
    uid: null,
    expires: null,
    status: null,
    id: null
  },
  user: {
    patronymic: null,
    deleted: null,
    image: null,
    blocked: null,
    id: null,
    title: null,
    login: null,
    name: null,
    theme: null,
    surname: null,
    hello_page: null,
    permissions: {}
  },
  isFetchedOk: false,
  viewAsUserMode: false
});

export const $viewAsAdminUser = createStore({
  authData: {
    uid: null,
    expires: null,
    status: null,
    id: null
  },
  user: {
    patronymic: null,
    deleted: null,
    image: null,
    blocked: null,
    id: null,
    title: null,
    login: null,
    name: null,
    theme: null,
    surname: null,
    hello_page: null
  },
})

export const setSession = createEvent();

export const setUserEmail = createEvent();
export const setPermissions = createEvent();
export const regLogout = createEvent();
export const setUser = createEvent();
export const clearUser = createEvent();
export const saveAuthToLocalStorage = createEvent();
export const changeUser = createEvent();
export const changeUserEmail = createEvent();

export const selectedViewFromUser = createEvent();
export const disableViewFromUser = createEvent();
export const getInfoViewFromUser = createEvent();

const setUserPermissions = createEvent();
const setAdminUserPermissions = createEvent();

$userStore.reset(clearUser);
$viewAsAdminUser.reset(disableViewFromUser)

export const $userMail = $userStore.map(store => store.user.mail)

export const login = createEffect(async (params) => {
  const { account, psw } = params;

  const res = await get(urls.login, { account, psw });
  return res;
});

const getPermissionsFx = createEffect(async (params) => {
  const res = await get(urls.userPermissionsList);
  return res.data?.list || [];
});

const getAdminUserPermissionsFx = createEffect(async (params) => {
  const res = await get(urls.userPermissionsList);
  return res.data?.list || [];
});


export const sendRegistrationFx = createEffect(async (params) => {
  const { mail, psw, psw1 } = params;

  const res = await get(urls.registration, { mail, psw, psw1 });
  return res;
})

export const sendRestorePassFx = createEffect(async (params) => {
  const { mail } = params;

  const res = await get(urls.sendRestoreRequest, { mail });
  return res;
})

export const sendActivationFx = createEffect(async (params) => {
  const { mail, code } = params;

  const res = await get(urls.activation, { mail, code });
  return res;
})

export const getUserFx = createEffect(async () => {
  const user = localStorage.getItem('user');

  if(user){
    const { id, uid, adminUid } = JSON.parse(user);
    const res = await get(urls.userData, { id, uid });

    if (adminUid) {
      viewAdminAsUserFx(adminUid);
    }

    return res;
  }
});

export const getAdminUserFx = createEffect(async ({ result }) => {
  const { data: { id, uid } } = result;

  const res = await get(urls.userData, { id, uid }, true);
  return {
    ...res.data,
    authData: {
      id,
      uid,
    }
  };
})

export const changeUserFx = createEffect(async (props) => {
  const res = await post({ module: 'user', action: 'edit', data: JSON.stringify(props) })

  return res;
})

export const changeUserEmailFx = createEffect(async (props) => {
  const res = await get(urls.changeUserMail, props)

  return res;
})

export const changeUserPswFx = createEffect(async (props) => {
  const res = await get(urls.editPassword, props)

  return res;
})

export const viewAdminAsUserFx = createEffect(async (uid) => {
  const res = await get(urls.viewAsUser, { uid }, true);

  const localStorageUser = JSON.parse(localStorage.getItem('user'));
  saveAuthToLocalStorage({
    ...localStorageUser,
    adminUid: uid,
  })

  return res;
})

getUserFx.done.watch(({ params, result }) => {
  const { data } = result;

  if(data.error_msg === "required_auth"){
    clearUser();
    localStorage.removeItem('user');
    window.history.forward('/login');
  }else{
    setUser(data);
  }
});

changeUserFx.done.watch(({ params, result }) => {
  const { viewAsUserMode } = $userStore.getState();

  if (!viewAsUserMode) {
    changeUser(params);
  }
})

saveAuthToLocalStorage.watch((authData) => {
  localStorage.setItem('user', JSON.stringify(authData));
})


$userStore.on(setSession, (state, authData) => ({
  ...state,
  authData
}))

$userStore.on(setUserEmail, (state, mail) => ({
  ...state,
  mail,
}))

$userStore.on(changeUser, (state, props) => {
  return {
    ...state,
    user: { ...state.user, ...props }
  }
})

$userStore.on(setUser, (state, data) => ({
  ...state,
  user: data.user,
  isFetchedOk: true
}));

$userStore.on(regLogout, (state, data) => ({
  ...state,
  isFetchedOk: false
}));

$userStore.on(selectedViewFromUser, (state, { params, result }) => {

  return {
    ...state,
    viewAsUserMode: true
  }
});

$userStore.on(disableViewFromUser, (state) => {
  const user = JSON.parse(localStorage.getItem('user'));
  delete user.adminUid;
  localStorage.setItem('user', JSON.stringify(user));

  return {
    ...state,
    authData: {
      ...state.authData,
      id: user.id
    },
    viewAsUserMode: false
  }
});

$userStore.on(setUserPermissions, (state, {result}) => {
  return {
    ...state,
    user: {
      ...state.user,
      permissions: result.reduce((acc, perm) => ({...acc, [perm.url]: {...perm}}), {}) // Normalized state
    }
  }
})

$viewAsAdminUser.on(getInfoViewFromUser, (state, { result }) => {
  const { authData, user } = result;

  return {
    user,
    authData,
    isFetchedOk: true
  }
})

$viewAsAdminUser.on(setAdminUserPermissions, (state, {result}) => {
  return {
    ...state,
    user: {
      ...state.user,
      permissions: result.reduce((acc, perm) => ({...acc, [perm.url]: {...perm}}), {}) // Normalized state
    }
  }
})

forward({
  from: viewAdminAsUserFx.done,
  to: getAdminUserFx
})
forward({
  from: getAdminUserFx.done,
  to: getInfoViewFromUser
})
forward({
  from: getAdminUserFx.done,
  to: getAdminUserPermissionsFx,
})
forward({
  from: getAdminUserFx.done,
  to: selectedViewFromUser
})
forward({
  from: getAdminUserPermissionsFx.done,
  to: setAdminUserPermissions,
})
forward({
  from: getUserFx.done,
  to: getPermissionsFx
})
forward({
  from: getPermissionsFx.done,
  to: setUserPermissions
})