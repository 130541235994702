import axios from 'axios';
import { $techniqueMonitoringStore } from 'store/techniqueMonitoring';

export const baseURL = 'https://wialon.xn----7sbegmlg5bets.xn--p1ai';


const instance = axios.create({
    baseURL,
})

export const wialonUrls = {
    techniqueMonitoring: 'svc=report/exec_report',
    cleanMonitoring: 'svc=report/cleanup_result',
    generateSession: 'svc=token/login',
    updateSession: 'avl_evts',
    monitoringStatus: 'svc=report/get_report_status',
    getTrack: 'svc=render/get_messages',
    getBadges: 'svc=report/select_result_rows'
}

export const wialonPost = async (query, params = {}) => {
    const sid = $techniqueMonitoringStore
        .map(store => store.sid)
        .getState();
    let requestUrl = `/wialon/ajax.html?`;

    if (sid)
        requestUrl += `sid=${sid}&${query}&params=${JSON.stringify(params)}`;

    return await instance.post(requestUrl);
}

export const generateSid = async (token) => {
    const requestUrl = `/wialon/ajax.html?&svc=token/login&params={"token": "${token}"}`
    return await instance.post(requestUrl);
}

export const clearReport = async () => {
    await wialonPost(wialonUrls.cleanMonitoring);
    return;
}

export const createReport = async (from, to, techId) => {
    const params = {
        reportResourceId: 162,
        reportTemplateId: 1,
        reportTemplate: null,
        reportObjectId: techId,
        reportObjectSecId: 0,
        interval: { flags: 0, from: from, to: to },
    };
    return await wialonPost(wialonUrls.techniqueMonitoring, params);
}

export const getGeneralTable = async (from, to, techList) => {
    const params = {
        reportResourceId:162,
        reportTemplateId:2,
        reportTemplate:null,
        reportObjectId:techList[0],
        reportObjectSecId:0,
        interval: {flags:16777216,from:from,to:to},
        reportObjectIdList: techList.slice(1),
    }
    return await wialonPost(wialonUrls.techniqueMonitoring, params)
}

export const getTrack = async (techId) => {
    const trackList = await wialonPost(wialonUrls.getTrack, {
        layerName: "report unit_msgs",
        indexFrom: 0,
        indexTo: 1000000,
        unitId: techId,
    });
    if (!Array.isArray(trackList.data)) {
        throw new Error('Ошибка');
    }

    return trackList.data.filter(el => el.pos && el.pos.x && el.pos.y).reduce((acc, el, index) => {
        if (index % 100 === 0) {
            acc.push([]);
            if (acc.at(-2)?.at(-1)) {
                acc.at(-1).push(acc.at(-2).at(-1));
            }
        }

        acc.at(-1).push(el);
        return acc;
    }, []);
}


export const getBadgesFromTable = async (table, tableNames) => {
    const requestList = tableNames.map(tableName => {
      const itemIndex = table.data.reportResult.tables.findIndex(el => el.name === tableName);
      if (itemIndex === -1) {
        return false
      }
      return wialonPost('svc=report/select_result_rows', {
        "tableIndex": itemIndex,
        "config": { "type": "range", "data": { "from": 0, "to": 1000, "level": 1 } }
      })
    });
    const res = await Promise.all(requestList);
    return {
      antifillingList: res[0].data ?? [],
      fillingList: res[1].data ?? [],
      parkingList: res[2].data ?? [],
      stopList: res[3].data ?? [],
    };
}

export const getTable = async (tableIndex) => {
    return await wialonPost('svc=report/select_result_rows', {
        "tableIndex": tableIndex,
        "config": { "type": "range", "data": { "from": 0, "to": 1000, "level": 3} }
    }).then(res => res.data);
}

export const updateWialonSession = () => {
    const sid = $techniqueMonitoringStore
        .map(store => store.sid)
        .getState();
    if (!sid) {
        return null;
    }
    return instance.post(`${wialonUrls.updateSession}?sid=${sid}`);
}