import { Modal } from "antd";
import { baseURL } from "api/wialonApi";
import { useStore } from "effector-react";
import { useEffect } from "react";
import {
  $techniqueMonitoringStore,
  getTechMonitoringSessionFx,
  setTechMonitoringToken,
} from "store/techniqueMonitoring";

export function AuthModal() {
  const { token, sid } = useStore($techniqueMonitoringStore);

  useEffect(() => {
    if (token && !sid) {
      getTechMonitoringSessionFx({token});
    }

    const handleMessage = (event) => {
      const eventURL = new URL(event.origin);
      const wialonURL = new URL(baseURL);

      if (eventURL.host === wialonURL.host) {
        const dataParams = new URLSearchParams(event.data);
        const dataToken = dataParams.get("access_token");
        if (dataToken) {
          localStorage.setItem("wwt", dataToken);
          setTechMonitoringToken(dataToken);
        }
      }
    };
    if (!token) {
      window.addEventListener("message", handleMessage);
    } else {
      window.removeEventListener("message", handleMessage);
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [token, sid]);


  return (
    <Modal
      open={!token}
      footer={false}
      style={{ textAlign: "center" }}
      width={500}
      closeIcon={null}
    >
      <iframe
        title="auth"
        style={{ border: "none" }}
        src={`${baseURL}/login_simple.html?lang=ru`}
        height="300px"
      />
    </Modal>
  );
}
