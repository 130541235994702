const helpMessageRoutes = {
    user: {
        create: {
            module: "user",
            file: "createNew"
        },
        changePassword: {
            module: "user",
            file: "changePassword"
        },
        changeMail: {
            module: "user",
            file: "changeMail"
        },
        edit: {
            module: "user",
            file: "edit"
        },
        activationCode: {
            module: "user",
            file: "activationCode"
        },
        list: {
            module: "user",
            file: "userListHelp"
        },
        profile: {
            module: "user",
            file: "profile"
        },
        editRole: {
            module: "user",
            file: "editRole"
        }
    },
    auth: {
        login: {
            module: "auth",
            file: "login"
        },
        reg: {
            module: "auth",
            file: "reg"
        },
        forgot: {
            module: "auth",
            file: "forgot"
        },
        activate: {
            module: "auth",
            file: "activate"
        },
    },
    company: {
        list: {
            module: "company",
            file: "help"
        },
        edit: {
            module: "company",
            file: "edit"
        },
        create: {
            module: "company",
            file: "createNew"
        },
    },
    companiesStats: {
        list: {
            module: "companies-stats",
            file: "companiesStatsListHelp"
        },
        edit: {
            module: "companies-stats",
            file: "edit"
        },
        create: {
            module: "companies-stats",
            file: "createNew"
        },
    },
    role: {
        list: {
            module: "role",
            file: "roleListHelp"
        },
        create: {
            module: "role",
            file: "createNew"
        },
        edit: {
            module: "role",
            file: "edit"
        }
    },
    fields: {
        list: {
            module: "fields",
            file: "fieldsListHelp"
        },
        import: {
            module: "fields",
            file: "fieldsImport"
        },
        showOnMap: {
            module: "fields",
            file: "showOnMap"
        },
        editMap: {
            module: "fields",
            file: "editMap"
        },
        allMaps: {
            module: "fields",
            file: "allMaps"
        },
        create: {
            module: 'fields',
            file: 'fieldsCreateHelp'
        },
        edit: {
            module: 'fields',
            file: 'fieldsEditHelp'
        }
    },
    cultures: {
        list: {
            module: "cultures",
            file: "culturesListHelp"
        },
        create: {
            module: "cultures",
            file: "culturesCreateHelp"
        },
        edit: {
            module: "cultures",
            file: "culturesEditHelp"
        },
        delete: {
            module: "cultures",
            file: "culturesDeleteHelp"
        }
    },
    culturesRotation: {
        list: {
            module: "culturesRotation",
            file: "culturesRotationListHelp"
        },
        create: {
            module: "culturesRotation",
            file: "culturesRotationCreateHelp"
        },
        edit: {
            module: "culturesRotation",
            file: "culturesRotationEditHelp"
        },
        delete: {
            module: "culturesRotation",
            file: "culturesRotationDeleteHelp"
        },
        close: {
            module: "culturesRotation",
            file: "culturesRotationCloseHelp"
        }
    },
    culturesRotationStats: {
        list: {
            module: "culturesRotationStats",
            file: "culturesRotationStatsHelp"
        },
    },
    sections: {
        list: {
            module: "sections",
            file: "sectionsListHelp"
        },
        create: {
            module: "sections",
            file: "sectionsCreateHelp"
        },
        edit: {
            module: "sections",
            file: "sectionsEditHelp"
        },
        delete: {
            module: "sections",
            file: "sectionsDeleteHelp"
        },
        staffList: {
            module: "worker",
            file: "sectionWorkersListHelp"
        },
        staffQuit: {
            module: "worker",
            file: "sectionWorkerQuitHelp"
        },
        staffCreate: {
            module: "worker",
            file: "sectionWorkersCreateHelp"
        },
        staffEdit: {
            module: "worker",
            file: "sectionWorkersEditHelp"
        },
    },
    storage: {
        list: {
            module: 'storage',
            file: 'storageListHelp'
        },
        create: {
            module: 'storage',
            file: 'storageCreateHelp'
        },
        edit: {
            module: 'storage',
            file: 'storageEditHelp'
        }
    },
    userGroups: {
        list: {
            module: "user_group",
            file: "userGroupListHelp"
        },
        create: {
            module: "user_group",
            file: "userGroupCreateHelp"
        },
        edit: {
            module: "user_group",
            file: "userGroupEditHelp"
        },
        delete: {
            module: "user_group",
            file: "userGroupDeleteHelp"
        },
        editRole: {
            module: "user_group",
            file: "userGroupRoleEditHelp"
        },
    },
    technique: {
        list: {
            module: "technic",
            file: "technicListHelp"
        },
        create: {
            module: "technic",
            file: "technicCreateHelp"
        },
        edit: {
            module: "technic",
            file: "technicEditHelp"
        },
        delete: {
            module: "technic",
            file: "technicDeleteHelp"
        },
    },
    techniqueMonitoring: {
        list: {
            module: 'technique-monitoring',
            file: 'techniqueMonitoringStatsHelp'
        }
    },
    techniqueMonitoringGroup: {
        list: {
            module: 'technique-monitoring-group',
            file: 'techniqueMonitoringGroupStatsHelp'
        }
    },
    worker: {
        list: {
            module: "worker",
            file: "workerListHelp"
        },
        create: {
            module: "worker",
            file: "workerCreateHelp"

        },
        edit: {
            module: "worker",
            file: "workerEditHelp"

        },
        delete: {
            module: "worker",
            file: "workerDeleteHelp"
        },
    },
    techOperations: {
        list: {
            module: "techOperations",
            file: "techOperationsListHelp"
        },
        create: {
            module: "techOperations",
            file: "techOperationsCreateHelp"
        },
        edit: {
            module: "techOperations",
            file: "techOperationsEditHelp"
        },
        delete: {
            module: "techOperations",
            file: "techOperationsDeleteHelp"
        }
    },
    techOperationsGroups: {
        list: {
            module: "techOperationsGroups",
            file: "techOperationsGroupsListHelp"
        },
        create: {
            module: "techOperationsGroups",
            file: "techOperationsGroupsCreateHelp"
        },
        edit: {
            module: "techOperationsGroups",
            file: "techOperationsGroupsEditHelp"
        },
        delete: {
            module: "techOperationsGroups",
            file: "techOperationsGroupsDeleteHelp"
        }
    },
    weighing: {
        list: {
            module: 'weighing',
            file: 'weighingListHelp',
        },
        create: {
            module: 'weighing',
            file: 'weighingCreateHelp',
        },
        edit: {
            module: 'weighing',
            file: 'weighingEditHelp',
        },
        delete: {
            module: 'weighing',
            file: 'weighingDeleteHelp'
        }
    },
    fieldProductivity: {
        stats: {
            module: 'field-productivity',
            file: 'fieldProductivityStats',
        }
    },
    storageCopacity: {
        stats: {
            module: 'storage-copacity',
            file: 'storageCopacityStats',
        }
    },
    material: {
        list: {
            module: "material",
            file: "materialListHelp"
        },
        create: {
            module: "material",
            file: "materialCreateHelp"
        },
        edit: {
            module: "material",
            file: "materialEditHelp"
        },
        delete: {
            module: "material",
            file: "materialDeleteHelp"
        }
    },
    materialGroups: {
        list: {
            module: "materialGroups",
            file: "materialGroupsListHelp"
        },
        create: {
            module: "materialGroups",
            file: "materialGroupsCreateHelp"
        },
        edit: {
            module: "materialGroups",
            file: "materialGroupsEditHelp"
        },
        delete: {
            module: "materialGroups",
            file: "materialGroupsDeleteHelp"
        }
    },
}
export default helpMessageRoutes;