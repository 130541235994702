import { Modal } from "antd";
import { ModalTitleForHelp } from 'components/ModalTitleForHelp';
import helpMessageRoutes from "../../helpMessageRoutes";
import ChangeUsersForm from './ChangeUsersForm';

import { changeUserFx } from "../../store/adminUsers";
import dayjs from "dayjs";

const NewUserModal = ({
  changeUserIsOpen,
  setChangeUserIsOpen,
  selectedUser,
  notificationApi
}) => {
  const {name, surname, patronymic, login, mail} = selectedUser;

  // contains name, surname and patronymic if exists otherwise contains login or email
  const userSign =
    (name && surname && patronymic)
      ? [name, surname, patronymic].join(" ")
      : (login || mail);

  const onSubmit = async (props) => {
    try {
      const { data } = await changeUserFx({ props: {...props, birthday: props.birthday ? dayjs(props.birthday, 'DD.MM.YYYY').format("YYYY.MM.DD") : null }, id: selectedUser.id,});
      if(data.error_message) {
        notificationApi.error({ message: data.error_message, duration: 5 });
      } else {
        notificationApi.success({ message: 'Данные пользователя успешно обновлены', duration: 5 });
      }
      setChangeUserIsOpen(false)
    } catch(e) {
      notificationApi.error({ message: "Не удалось обновить данные пользователя", duration: 5 });
    }
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={`Редактирование пользователя ${userSign}`}
          helpFile={helpMessageRoutes.user.edit}
        />
      }
      destroyOnClose
      open={changeUserIsOpen}
      onCancel={() => setChangeUserIsOpen(false)}
      footer={null}
    >
      <ChangeUsersForm
        onSubmit={onSubmit}
        submitTitle={"Сохранить данные"}
        onCancel={() => setChangeUserIsOpen(false)}
        user={selectedUser}
      />
    </Modal>
  );
};

export default NewUserModal;