import { useLocation, useNavigate } from "react-router-dom";
import { useRef, useEffect, useState } from 'react';
import { Button } from 'antd';
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import {
  toggleMap,
  deleteEditRow
} from "store/fields";

import {
  dataToLeafletFormat,
  getCenter
} from 'shared/utils/leaflet';

import st from "./index.module.scss";
import { setHelpModule } from "../../store/helpModal";
import helpMessageRoutes from "../../helpMessageRoutes";

const mods = (mode) => {
  return {[mode]: mode}
}

const getSample = (coords) => ({
  type: "FeatureCollection",
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coords],
      },
      properties: {
        prop0: 'value0',
        prop1: { this: 'that' },
      },
    },
  ],
})

const Map = ({
  strPolygons,
  mode,
  onCreateLayer
}) => {
  const polygons = dataToLeafletFormat(strPolygons);
  const mapMode = mods(mode);

  const initialCenter = [49.343, 43.647];
  const mapRef = useRef();
  const [latLng, setLatLng] = useState(polygons);

  useEffect(() => {
    const map = L.map('map', { pmIgnore: false }).setView(initialCenter, mapMode.edit ? 17 : 9);
    L.tileLayer('http://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}').addTo(map);

    if(mapMode.edit || mapMode.create){
      map.pm.addControls({
        drawMarker: false,
        drawPolyline: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawText: false,
        drawRectangle: false,
        rotateMode: false
      });

      map.pm.setLang("ru");

      map.on("pm:create", (shape) => {
        setLatLng(shape.layer._latlngs);
      });
    }

    if(mapMode.edit){
      const polygon = L.polygon(polygons).addTo(map);
      map.pm.enableGlobalEditMode();

      map.fitBounds(polygon.getBounds())

      polygon.on('pm:edit', (shape) => {
        setLatLng(shape.layer._latlngs);
      })
    }

    return () => {
      map.remove();
      map.off();
    }
  }, []);

  useEffect(() => {
    setHelpModule(helpMessageRoutes.fields.editMap);
    return () => {
      setHelpModule(null)
    }
  }, [])

  const onSave = () => {
    onCreateLayer(latLng);
    toggleMap();
  }

  return (
    <div className={st.mapWrapper}>
      <div
        style={{ height: "calc(100vh - 50px)", width: "100%" }}
        ref={mapRef}
        id="map"
      />
      <Button
        onClick={onSave}
        className={st.saveBtn}
      >
        Сохранить координаты
      </Button>
      <Button
        onClick={() => toggleMap()}
        className={st.deleteBtn}
      >
        Выйти из режима карты
      </Button>
    </div>
  )
}

export default Map;