import {Form, Input, Upload, ColorPicker, Button} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const UpdateCultureForm = ({
    onSubmit,
    submitTitle = "OK",
    title = "",
    description = "",
    color = "",
    imgRequired,
    onCancel,
}) => {
    return (
        <Form
            autoComplete={false}
            layout="vertical"
            onFinish={onSubmit}
        >
            <Form.Item
                label="Название культуры"
                name="title"
                required={false}
                rules={[{ required: true, message: 'Введите название культуры' }]}
                initialValue={title}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Описание культуры"
                name="description"
                required={false}
                rules={[{ required: true, message: 'Введите описание культуры' }]}
                initialValue={description}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Картинка"
                name={"image"}
                required={false}
                rules={[{ required: imgRequired, message: 'Выберете картинку' }]}
            >
                <Upload
                    name="picture"
                    accept=".svg"
                    multiple={false}
                    maxCount={1}
                    beforeUpload={() => false}
                >
                    <Button icon={<UploadOutlined/>}>Загрузите картинку</Button>
                </Upload>
            </Form.Item>

            <Form.Item
                label="Цвет"
                name={"color"}
                required={false}
                rules={[{ required: true, message: 'Выберете цвет' }]}
                initialValue={color}
            >
                <ColorPicker format="hex" disabledAlpha/>
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default UpdateCultureForm;