import { UploadOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Button, ColorPicker, Upload} from "antd";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import helpMessageRoutes from "helpMessageRoutes";
import UpdateCultureForm from "./UpdateCultureForm";

const CreateCultureModal = ({onSubmit, ...props}) => 
    <Modal
        title={<ModalTitleForHelp title="Создание культуры" helpFile={helpMessageRoutes.cultures.create}/>}
        footer={null}
        destroyOnClose
        {...props}
    >
        <UpdateCultureForm
            onSubmit={onSubmit}
            submitTitle="Создать"
            onCancel={props.onCancel}
            imgRequred
        />
    </Modal>

export default CreateCultureModal;