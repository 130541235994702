import { usePermission } from 'hooks/usePermission';
import styles from './index.module.scss';
import { Button, Select } from 'antd';
import { useStore } from 'effector-react';
import { $weighingStore, $weighingYearsStore, getWeighingListFx, getWeighingYearsFx } from 'store/weighing';
import { useEffect } from 'react';

export const PageHeader = ({ onClick }) => {
  const {years} = useStore($weighingYearsStore);
  const {year} = useStore($weighingStore);
  const canCreate = usePermission('weighing', 'create');

  useEffect(() => {
    getWeighingYearsFx();
  }, [])

  function changeYear(newYear) {
    getWeighingListFx(newYear);
  }

  return <div className={styles.pageHeader}>
    <div className={styles.left}>
      <h2 className={styles.pageTitle}>Приход урожая на весовую</h2>
      <Select 
        defaultValue={year} 
        options={years.map(year => ({value: year, label: year}))} 
        onChange={changeYear}
      />
    </div>
    {canCreate &&
      <Button
        onClick={onClick}
      >
        Добавить взвешивание
      </Button>
    }
  </div>
}