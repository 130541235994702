import React from 'react';
import st from "../../pages/Fields/index.module.scss";
import { Controller } from "react-hook-form";
import { Input } from "antd";

const FormInput = ({
  input,
  form
}) => {
  const { name, label, rules, type } = input;
  const { control, formState: { errors } } = form;

  return(
    <div
      className={st.modalItem}
      key={name}
    >
      <div>{label}</div>
      <div className={st.inputError}>
        {errors[name] && errors[name].message}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          <Input
            status={errors[name] ? 'error' : ''}
            type={type}
            {...field}
          />
        }
      />
    </div>
  )
}

export default FormInput;