import Modal from "antd/es/modal/Modal";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import UpdateSectionForm from "./UpdateSectionForm";
import helpMessageRoutes from "helpMessageRoutes";

const EditSectionModal = ({onSubmit, activeItem, ...props}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Редактирование подразделения" helpFile={helpMessageRoutes.sections.edit}/>}
            footer={null}
            destroyOnClose
            {...props}
        >
            <UpdateSectionForm
                onSubmit={onSubmit}
                onCancel={props.onCancel}
                submitTitle="Изменить"
                {...activeItem}
            />
        </Modal>
    )
}

export default EditSectionModal;