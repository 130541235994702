import { Modal } from "antd";
import { ModalTitleForHelp } from "components/ModalTitleForHelp";
import EditTechniqueForm from "./EditTechniqueForm";
import helpMessageRoutes from "helpMessageRoutes";

const EditTechModal = ({activeItem, ...props}) => {
    return (
        <Modal
            title={<ModalTitleForHelp title="Изменить технику" helpFile={helpMessageRoutes.technique.edit}/>}
            footer={null}
            destroyOnClose
            {...props}
        >
            <EditTechniqueForm
                onSubmit={props.onSubmit}
                submitTitle={"Сохранить"}
                onCancel={props.onCancel}
                {...activeItem}
            />
        </Modal>
    );
}

export default EditTechModal;