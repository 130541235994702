import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, sample } from "effector";

export const $operationsGroup = createStore([]);

export const getTechOperationGroupsFx = createEffect();
export const deleteTechOperationGroupsFx = createEffect();
export const editOrCreateTechOperationGroupsFx = createEffect();

export const getTechOperationsGroups = createEvent();

getTechOperationGroupsFx.use(async () => {
  const res = await get(urls.techGroupsOperationsList);
  return res;
});

editOrCreateTechOperationGroupsFx.use(async (props) => {
  const { title, description, techOperation } = props;

  const postData = {
    module: 'tech_operation',
    action: 'edit_group',
    data: JSON.stringify({ title, description }),
  }

  if(techOperation?.id){
    postData['id'] = techOperation.id;
  }

  const res = await post(postData);

  return res;
})

deleteTechOperationGroupsFx.use(async (operationId) => {
  const res = await get(urls.techGroupsOperationsDelete, { id: operationId });
  return res;
})

sample({
  source: sample({
    clock: getTechOperationsGroups,
    target: getTechOperationGroupsFx
  }),
  clock: getTechOperationGroupsFx.done,
  fn: (_, { result }) => result.data?.list || [],
  target: $operationsGroup
})

sample({
  clock: [deleteTechOperationGroupsFx.done, editOrCreateTechOperationGroupsFx.done],
  target: getTechOperationsGroups
})