import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, sample } from "effector";

export const $materialGroup = createStore([]);

export const getMaterialGroupsFx = createEffect();
export const deleteMaterialGroupsFx = createEffect();
export const editOrCreateMaterialGroupsFx = createEffect();

export const getMaterialGroups = createEvent();

getMaterialGroupsFx.use(async () => {
  const res = await get(urls.materialGroupsList);
  return res;
});

editOrCreateMaterialGroupsFx.use(async (props) => {
  const { title, description, material } = props;

  const postData = {
    module: 'material',
    action: 'edit_group',
    data: JSON.stringify({ title, description }),
  }

  if(material?.id){
    postData['id'] = material.id;
  }

  const res = await post(postData);

  return res;
})

deleteMaterialGroupsFx.use(async (materialId) => {
  const res = await get(urls.materialGroupsDelete, { id: materialId });
  return res;
})

sample({
  source: sample({
    clock: getMaterialGroups,
    target: getMaterialGroupsFx,
  }),
  clock: getMaterialGroupsFx.done,
  fn: (_, { result }) => result.data?.list || [],
  target: $materialGroup
})

sample({
  clock: [deleteMaterialGroupsFx.done, editOrCreateMaterialGroupsFx.done],
  target: getMaterialGroups
})