import { Form, Input, Select, Button } from "antd";
import { useEffect, useMemo } from "react";
import { useStore } from "effector-react";
import { $companiesStore, getCompanies } from "store/companies";
import { $sectionsStore, getSectionsFx } from "store/sections";
import { $usersGroupsStore, getUsersGroupsFx } from "store/userGroups";

const CreateUserForm = ({onSubmit, submitTitle, onCancel}) => {
    const [form] = Form.useForm();

    const groupList = useStore($usersGroupsStore);

    useEffect(() => {
        getUsersGroupsFx();
    }, []);

    return (
        <Form
            layout="vertical"
            onFinish={onSubmit}
            form={form}
        >
            <Form.Item
                label="Почта"
                name="mail"
                required={false}
                rules={[
                    { required: true, message: "Введите почту" },
                    { type: "email", message: "Неверный формат почты"}
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Пароль"
                name="psw"
                required={false}
                rules={[{ required: true, message: 'Введите пароль' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Повторите пароль"
                name="psw1"
                required={false}
                rules={[
                    { required: true, message: 'Введите подтверждение пароля' },
                    ({getFieldValue}) => ({
                        validator: (_, value) => !value || getFieldValue("psw") === value
                            ? Promise.resolve()
                            : Promise.reject(new Error("Введенные пароли не совппадают"))
                    })
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Группа пользователей"
                name="group"
                required={false}
                rules={[{required: true, message: 'Выберете группу пользователей'}]}
            >
                <Select
                    options={groupList?.map(({title, id}) => ({label: title, value: id})) || []}
                />
            </Form.Item>

            <Form.Item>
                <div style={{display: "flex", justifyContent: "flex-end", gap: 20}}>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" htmlType="submit">{submitTitle}</Button>
                </div>
            </Form.Item>
        </Form>
    );
}

export default CreateUserForm;