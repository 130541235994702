import { createEvent, createStore, sample } from "effector";
import { $mapMode } from "./fields";

export const $sidebarState = createStore(false);
export const closeSidebar = createEvent();
export const openSidebar = createEvent();
export const toggleSidebar = createEvent();

$sidebarState.on(closeSidebar, () => false)
$sidebarState.on(openSidebar, () => true)
$sidebarState.on(toggleSidebar, (state) => !state)

export const $changeUserModal = createStore(null);
export const showedChangeUserModal = createEvent();

$changeUserModal.on(showedChangeUserModal, (state, data) => data)


export const $globalOverflow = createStore(false);
export const toggleGlobalOverflow = createEvent();

sample({
  clock: $mapMode,
  target: $globalOverflow
})