import Statistics from "components/Statistics";
import { useStore } from "effector-react";
import {  useEffect, useState } from "react";
import { $staffStore, getStaffFx } from "store/staff";
import { $userStore } from "store/user";
import PageHeader from "./PageHeader";
import { Divider } from "antd";

import styles from './index.module.scss';


const CompaniesStats = () => {
    const { list: staffList } = useStore($staffStore);
    const { viewAsUserMode } = useStore($userStore);
    const [list, setList] = useState([]);

    useEffect(() => {
        getStaffFx();
    }, [viewAsUserMode]);

    useEffect(() => {
        getStats(staffList);
    }, [staffList, viewAsUserMode]);


    async function getStats(staffList) {
        const companies = [];
        for (const worker of staffList) {
            const companyId = companies.findIndex(company => company.id === worker.company.id);

            if (companyId === -1) {
                companies.push({
                    id: worker.company.id,
                    name: worker.company.name,
                    sections: [
                        {
                            id: worker.section.id,
                            name: worker.section.title,
                            list: [worker]
                        }
                    ]
                });
            } else {
                const sectionId = companies[companyId].sections.findIndex(section => worker.section.id === section.id);
                if (sectionId === -1) {
                    companies[companyId].sections.push({
                        id: worker.section.id,
                        name: worker.section.title,
                        list: [worker]
                    });
                } else {
                    companies[companyId].sections[sectionId].list.push(worker);
                }
            }
        }

        setList(companies);
    }


    return <>
        <PageHeader />
        <Divider />
        <div className={styles.list}>
            {list.map(company => {
                return <div key={company.id} className={styles.company}>
                    <div className={styles.companyTitle}>{company.name}</div>
                    {company.sections?.map(section => {
                        return <div key={section.id} className={styles.section}>
                            <div>{section.name}</div>
                            <Statistics
                                calcType={'none'}
                                columnNames={['ФИО/login', 'Зарплата', 'Процентная ставка']}
                                data={section.list}
                                divisionBy={'salary'}
                                groupBy={'worker'}
                                sumBy={'percentage'}
                                type={'table'}
                                showPagination={false}
                            />
                        </div>;
                    })}
                </div>;
            })}
        </div>
    </>
}

export default CompaniesStats;