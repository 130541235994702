import { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";
import { $workersStore, getWorkersFx, editWorkerFx, deleteWorkerFx } from "store/workers";
import {getUserListFx} from "store/adminUsers";
import { $userStore } from "store/user";
import { useParams } from "react-router-dom";
import UserWorkersTable from "./UserWorkersTable";
import { Divider, notification } from "antd";
import { PageHeader } from "./PageHeader";
import PickCompanyModal from "./PickCompanyModal";
import EditWorkerModal from "./EditWorkerModal";

const UserWorkers = () => {
    const [pickCompanyOpen, setPickCompanyOpen] = useState(false);
    const [editWorkerOpen, setEditWorkerOpen] = useState(false);
    const [activeWorker, setActiveWorker] = useState(null);

    const {id} = useParams();
    const workersList = useStore($workersStore);
    const {viewAsUserMode} = useStore($userStore);

    // Callbacks
    const submitCompany = useCallback((data) => editWorkerFx({...data, user: id}), [id]);

    const handleAddWorker = (data) => {
        submitCompany(data)
            .then(res => {
                if (res?.data?.status === "ok") {
                    getWorkersFx(id);
                    notification.success({message: "Место работы добавлено"});
                    setPickCompanyOpen(false);
                } else {
                    notification.error({message: "Ошибка при добавлении места работы"});
                }
            })
            .catch(() => notification.error({message: "Ошибка при добавлении места работы"}));
    };

    const handleEditWorker = (data) => {
        submitCompany({...data, id: activeWorker.id})
            .then(res => {
                if (res?.data?.status === "ok") {
                    getWorkersFx(id);
                    notification.success({message: "Место работы изменено"});
                    setEditWorkerOpen(false);
                } else {
                    notification.error({message: "Ошибка при изменении места работы"});
                }
            })
            .catch(() => notification.error({message: "Ошибка при изменении места работы"}));
    };

    const onEditWorker = useCallback((worker) => {
        setEditWorkerOpen(true);
        setActiveWorker(worker);
    }, [setEditWorkerOpen, setActiveWorker]);

    const deleteWorker = useCallback((worker) => {
        deleteWorkerFx(worker)
            .then(res => {
                if (res?.data?.status === "ok") {
                    getWorkersFx(id);
                    notification.success({message: "Место работы удалено"});
                } else {
                    notification.error({message: "Ошибка при удалении места работы"});
                }
            })
            .catch(() => notification.error({message: "Ошибка при удалении места работы"}));
    }, [id])

    // Effects
    useEffect(() => {
        getWorkersFx(id);
    }, [id, viewAsUserMode]);

    useEffect(() => {
        getUserListFx();
    }, [viewAsUserMode])

    return (
        <>
            <PageHeader
                id={id}
                onClick={() => setPickCompanyOpen(true)}
            />
            <Divider />
            <UserWorkersTable
                items={workersList}
                onEdit={onEditWorker}
                onDelete={deleteWorker}
            />
            <PickCompanyModal
                opened={pickCompanyOpen}
                onSubmit={handleAddWorker}
                onCancel={() => setPickCompanyOpen(false)}
            />
            <EditWorkerModal
                activeWorker={activeWorker}
                opened={editWorkerOpen}
                onSubmit={handleEditWorker}
                onCancel={() => setEditWorkerOpen(false)}
            />
        </>
    );
}

export default UserWorkers;