import { useStore } from "effector-react";
import { memo, useEffect, useRef } from "react";
import { GeoJSON } from "react-leaflet";
import { $geoJsonFeatures, fetchingFields } from "store/fields";

export const FieldsGeoJson = memo(() => {
    const geoJson = useStore($geoJsonFeatures);
    const GeoJSONRef = useRef();
  
    const style = (feature) => {
      return {

        fillColor: feature.properties.color || '#3388ff',
        color: feature.properties.color || '#3388ff'
      }
    }

    useEffect(() => {
        fetchingFields(1);
    }, [])
    
    return (
      <GeoJSON        
        data={{
            type: "FeatureCollection",
            features: geoJson,
        }}
        key={Math.floor(Math.random() * 100)}
        style={style}
        ref={GeoJSONRef}
      />
    )
  })