import { createDomain } from "effector";
import { get, post, urls } from "api";
import { forward } from "effector";

const initialState = {
    rolesList: [],
    isError: false
}

const rolesDomain = createDomain();

// Store
export const $rolesStore = rolesDomain.createStore(initialState);

// Events
const setRolesList = rolesDomain.createEvent();

// Effects
export const fetchRolesFx = rolesDomain.createEffect(async () => {
    const res = await get(urls.rolesList);
    return res.data.status === "ok"
        ? res.data.list
        : {isError: true};
});

export const addRoleFx = rolesDomain.createEffect(async ({title, url}) => {
    const res = await post({
        module: "role",
        action: "add",
        title,
        url
    });
    return res.data;
});

export const editRoleFx = rolesDomain.createEffect(async ({id, title, url}) => {
    const res = await post({
        module: "role",
        action: "edit",
        id,
        title,
        url
    });
    return res.data;
});

export const removeRoleFx = rolesDomain.createEffect(async (id) => {
    const res = await get(urls.roleRemoveById, {id});
    return res.data;
});

// Forwards
forward({
    from: fetchRolesFx.done,
    to: setRolesList
})

forward({
    from: addRoleFx.done,
    to: fetchRolesFx
})

forward({
    from: editRoleFx.done,
    to: fetchRolesFx
})

forward({
    from: removeRoleFx.done,
    to: fetchRolesFx
})

// Reducers
$rolesStore
    .on(setRolesList, (state, {_, result}) => {
        return result?.isError
            ? {...state, isError: true}
            : {...state, isError: false, rolesList: result};
    });