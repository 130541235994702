import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, sample } from "effector";

export const $operations = createStore([]);

export const getTechOperationsFx = createEffect();
export const deleteTechOperationFx = createEffect();
export const editOrCreateTechOperationFx = createEffect();

export const getTechOperations = createEvent();

getTechOperationsFx.use(async () => {
  const res = await get(urls.techOperationsList);
  return res;
});

editOrCreateTechOperationFx.use(async (props) => {
  const { title, description, tech_operation_group, techOperation } = props;

  const postData = {
    module: 'tech_operation',
    action: 'edit',
    data: JSON.stringify({ title, description, tech_operation_group }),
  }

  if(techOperation?.id){
    postData['id'] = techOperation.id;
  }

  const res = await post(postData);

  return res;
})

deleteTechOperationFx.use(async (operationId) => {
  const res = await get(urls.techOperationsDelete, { id: operationId });
  return res;
})

sample({
  source: sample({
    clock: getTechOperations,
    target: getTechOperationsFx
  }),
  clock: getTechOperationsFx.done,
  fn: (_, { result }) => result.data?.list || [],
  target: $operations
})

sample({
  clock: [deleteTechOperationFx.done, editOrCreateTechOperationFx.done],
  target: getTechOperations
})