import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Divider,
  Modal,
  notification,
  Form
} from 'antd';
import { useStore } from "effector-react";

import ProfileMailModal from './Modals/ProfileMailModal'
import ProfileMailCodeModal from './Modals/ProfileMailCodeModal'
import ProfilePswModal from './Modals/ProfilePswModal'

import helpMessageRoutes from 'helpMessageRoutes';

import {
  $userStore,
  $userMail,
  changeUserFx,
  $viewAsAdminUser
} from "store/user";

import st from './index.module.scss';
import { ModalTitleForHelp } from 'components/ModalTitleForHelp';
import { DatePicker } from 'antd/es';
import dayjs from 'dayjs';

const Profile = () => {
  const [mailModal, setMailModal] = useState(false);
  const [codeModal, setCodeModal] = useState(false);
  const [pswModal, setPswModal] = useState(false);

  const usermail = useStore($userMail);
  const [newMail, setNewMail] = useState()

  const [notificationsApi, contextHolder] = notification.useNotification();
  const { user, viewAsUserMode } = useStore($userStore);
  const { user: adminUser } = useStore($viewAsAdminUser);
  const { name, surname, patronymic, phone, group, passport_series, passport_number, snils, inn, birthday } 
    = viewAsUserMode ? adminUser : user;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
        name, surname, patronymic, phone, group, passport_series, passport_number, snils, inn, 
        birthday: birthday ? dayjs(birthday, "YYYY-MM-DD"): null,
    });
}, [form, name, surname, patronymic, phone, group, passport_series, passport_number, snils, inn, birthday ]);

  const onSubmit = async () => {
    const updateUser = form.getFieldsValue();
    try {
      const { data } = await changeUserFx({...updateUser, birthday: updateUser.birthday ? dayjs(updateUser.birthday, 'DD.MM.YYYY').format("YYYY.MM.DD") : null });
      if(data.error_message) {
        notificationsApi.error({ message: data.error_message, duration: 5 });
      } else {
        notificationsApi.success({ message: 'Данные пользователя успешно обновлены', duration: 5 });
      }
    } catch(e) {
      notificationsApi.error({ message: "Не удалось обновить данные пользователя", duration: 5 });
    }
  }

  return (
    <div className={st.profile}>
      <div className={st.pageTitle}>Настройки профиля</div>
        <Divider />
      <div className={st.profileBody}>
      <div className={st.left}></div>
      <div className={st.rigth}>
        <div>Контактная информация для профиля {usermail}</div>
        <Divider />
        <Form
          autoComplete={false}
          layout="vertical"
          form={form}
          requiredMark={false}
          className={st.userItems}
        >
        <div className={st.formChangeElements}>
        <Form.Item
          label="Фамилия"
          name="surname"
          initialValue={surname}
          rules={[
            {
              required: true,
              message: 'Не введена фамилия',
            }
          ]}
        >
          <Input className={st.regInputMail} />
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
        <Form.Item
          name="passport_series"
          label="Серия паспорта"
          initialValue={passport_series}
          style={{display: "inline-block", width: "calc(30% - 8px)", marginRight: 8}}
        >
          <Input placeholder="Серия" className={st.regInputMail} />
        </Form.Item>
        <Form.Item
          name="passport_number"
          label="Номер паспорта"
          initialValue={passport_number}
          style={{display: "inline-block", width: "70%"}}
        >
          <Input placeholder="Номер" className={st.regInputMail} />
        </Form.Item>
        </Form.Item>
        <Form.Item
          label={<span className={st.userItemTitle}>Имя</span>}
          name="name"
          initialValue={name}
          rules={[
            {
              required: true,
              message: 'Не введено имя',
            }
          ]}
        >
          <Input className={st.regInputMail} />
        </Form.Item>
        <Form.Item
          label="Номер телефона"
          name="phone"
          initialValue={phone}
          required={false}
        >
          <Input className={st.regInputMail} />
        </Form.Item>
        <Form.Item
          label="Отчество"
          name="patronymic"
          initialValue={patronymic}
        >
          <Input className={st.regInputMail} />
        </Form.Item>
        <Form.Item
          label="ИНН"
          name="inn"
          initialValue={inn}
          style={{display: "inline-block", width: "48%", marginRight: "4%"}}
        >
          <Input className={st.regInputMail} />
        </Form.Item>
        <Form.Item
          label="Дата рождения"
          name="birthday"
          initialValue={birthday ? dayjs(birthday, "YYYY-MM-DD") : null}
        >
          <DatePicker format={"DD.MM.YYYY"} />
        </Form.Item>
        
        <Form.Item
          label="СНИЛС"
          name="snils"
          initialValue={snils}
          style={{display: "inline-block", width: "48%"}}
        >
          <Input className={st.regInputMail} />
        </Form.Item>
        </div>
        <div className={st.rightButtons}>
          <Button
            onClick={() => setMailModal(true)}
          >
            Изменить почту
          </Button>
          <Button
            onClick={() => setPswModal(true)}
          >
            Изменить пароль
          </Button>
        </div>
        <Divider />
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          style={{ width: 100, marginLeft: 'auto' }}
        >
          Сохранить
        </Button>
      </Form>
      </div>
      </div>
      <Modal
        title={
          <ModalTitleForHelp
            title="Изменение почты"
            helpFile={helpMessageRoutes.user.changeMail}
          />
        }
        open={mailModal}
        onCancel={() => setMailModal(false)}
        footer={null}
      >
        <ProfileMailModal
          setMailModal={setMailModal}
          setCodeModal={setCodeModal}
          setNewMail={setNewMail}
          notificationsApi={notificationsApi}
        />
      </Modal>
      <Modal
        title={
          <ModalTitleForHelp
            title="Код активации"
            helpFile={helpMessageRoutes.user.activationCode}
          />
        }
        open={codeModal}
        onCancel={() => setCodeModal(false)}
        footer={null}
        width={315}
      >
        <ProfileMailCodeModal
          setCodeModal={setCodeModal}
          codeModal={codeModal}
          newMail={newMail}
          notificationsApi={notificationsApi}
        />
      </Modal>
      <Modal
        title={
          <ModalTitleForHelp 
            title="Изменение пароля"
            helpFile={helpMessageRoutes.user.changePassword}
          />
        }
        open={pswModal}
        onCancel={() => setPswModal(false)}
        footer={null}
      >
        <ProfilePswModal
          setPswModal={setPswModal}
          notificationsApi={notificationsApi}
        />
      </Modal>
      {contextHolder}
    </div>
  );
};

export default Profile;