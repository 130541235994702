import React, { useEffect } from 'react';
import { useStore } from "effector-react";
import { Button, Modal } from "antd";
import { Form } from 'components/Form';
import { useForm } from "react-hook-form";

import { editOrCreateCultureRotationFx } from "store/cultureRotation";
import { getCulturesList, $culturesList } from "store/cultures";
import helpMessageRoutes from 'helpMessageRoutes';

import { ModalTitleForHelp } from 'components/ModalTitleForHelp';
import { useParams } from "react-router-dom";

import st from "../style.module.scss";

const items = [
  {
    label: 'Культура',
    name: 'culture',
    type: 'select',
    rules: { required: 'Не указана культура' }
  },
  {
    label: 'Дата посева',
    name: 'date_start',
    type: 'date',
    rules: { required: 'Не указана дата посева' }
  },
  {
    label: 'Планируемая дата уборки',
    name: 'date_plan',
    type: 'date',
    rules: { required: 'Не указана предполагаемая дата посева' }
  },
]

export const CreateModal = ({
  item,
  notificationsApi,
  onClose
}) => {
  const { id: fieldId } = useParams();
  const culturesList = useStore($culturesList);
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      culture: item ? item.culture : "",
      date: item ? item.date : "",
      date_start: item ? item.date_start : "",
      date_plan: item ? item.date_plan : "",
    }
  });

  useEffect(() => {
    getCulturesList();
  }, [])

  const onSubmit = async (props) => {
    const { culture, date_start, date_plan } = props;

    const { data } = await editOrCreateCultureRotationFx({
      culture,
      date_start,
      date_plan,
      plantation: fieldId,
      cultureRotation: item
    });

    if(data.error_message) {
      notificationsApi.error({ message: data.error_message, duration: 5 });
    } else {
      notificationsApi.success({
        message: item ? 'Севооборот успешно изменен' : 'Севооборот успешно создан',
        duration: 5
      });

      onClose();
    }
  }

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title={item ? "Изменение записи о севообороте" : "Создание записи о севообороте"}
          helpFile={helpMessageRoutes.culturesRotation[item ? "edit" : "create"]}
        />
      }
      open={true}
      onCancel={onClose}
      footer={null}
    >
      <form
        className={st.modalForm}
        onSubmit={handleSubmit(onSubmit)}
      >
        {items.map((props) => (
          props.type == 'select' ? (
            <Form.Select
              input={props}
              options={culturesList.map(item => ({ value: item.name, label: item.title }))}
              form={{ control, formState }}
              name={props.name}
              placeholder="Выбрать культуру"
            />
          ): (
            <Form.Input
              input={props}
              form={{ control, formState }}
            />
          )
        ))}
        <div className={st.modalFooter}>
          <Button onClick={onClose}>
            Отменить
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
        </div>
      </form>
    </Modal>
  );
};