import { Button, Modal } from "antd";
import { useStore } from "effector-react";
import { usePermission } from "hooks/usePermission";
import { useNavigate } from "react-router-dom";
import { $selectFieldRow, deleteSelectRow, setEditField } from "store/fields";


const FieldSelectModal = () => {
    const canEdit = usePermission("culture-rotation", "edit");
    const selectField = useStore($selectFieldRow);
    const navigate = useNavigate();

    return <Modal 
        title={selectField.name}
        open={true} 
        onCancel={deleteSelectRow}
        destroyOnClose={true} 
        footer={null} 
    >
        <div style={{display: 'flex', gap: '10px', flexDirection: 'column'}}>
            {canEdit && <Button onClick={() => {
                setEditField(selectField.id)
                deleteSelectRow();
            }}>Редактировать</Button>}
            <Button onClick={() => {
                navigate(`/fields/${selectField.id}/culture-rotation`);
                deleteSelectRow();
            }}>Севооборот</Button>
        </div>
    </Modal>
}

export default FieldSelectModal;