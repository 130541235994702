import { Button } from "antd";
import { usePermission } from "hooks/usePermission";
import { useStore } from "effector-react";
import { $userList } from "store/adminUsers";
import styles from "./index.module.scss";

export const PageHeader = ({id, onClick}) => {
  const canCreate = usePermission("worker", "create");
  const user = useStore($userList).find(item => item.id === parseInt(id));

  return (
    <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>
          Место работы {(user?.surname && user?.name && user?.patronymic)
            ? [user.surname, user.name, user.patronymic].join(" ")
            : (user?.mail || user?.login)}
        </h2>
        { canCreate &&
          <Button
            onClick={onClick}
          >
            Выбрать предприятие
          </Button>
        }
    </div>
  )
}

