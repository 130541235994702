import { useState, useEffect } from "react";
import { useStore, useUnit } from "effector-react";
import {
  fetchFieldsFx,
  $fieldsList,
  $editFieldRow,
  $mapMode,
  setEditField,
} from "store/fields";
import { $userStore } from "store/user";
import { Button, Divider, notification } from "antd";
import { useNavigate } from "react-router-dom";

import FieldsTable from "./FieldsTable";
import ImportFieldsModal from "./ImportFieldsModal";
import ChangeFieldModal from "./ChangeFieldModal";
import { usePermission } from "hooks/usePermission";

import styles from "./index.module.scss";

const Fields = () => {
  const [editFieldRow, mapMode] = useUnit([
    $editFieldRow,
    $mapMode,
    $fieldsList,
  ])
  const canCreate = usePermission("plantation", "create");
  const {viewAsUserMode} = useStore($userStore);
  const [importModal, setImportModal] = useState(false);
  const navigate = useNavigate();
  const [notificationsApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    fetchFieldsFx(1);
  }, [viewAsUserMode]);

  const showAllFields = (field) => {
    navigate("/fields/all/map", { state: { id: 'all' } });
  }

  return (
    <div>
      <div className={styles.headerWrapper}>
        <h1 className={styles.pageHeading}>
          Поля
          {canCreate &&
            <Button
              onClick={showAllFields}
              style={{ marginLeft: 16 }}
            >
              Все поля
            </Button>
          }
        </h1>
        <div>
          {canCreate &&
            <Button
              onClick={() => setImportModal(true)}
              style={{ marginRight: 8 }}
            >
              Загрузить поля
            </Button>
          }
          {canCreate &&
            <Button
              onClick={() => setEditField()}
            >
              Создать поле
            </Button>
          }
        </div>
      </div>

      <Divider/>

      <FieldsTable />
      {(editFieldRow || mapMode) && <ChangeFieldModal notificationsApi={notificationsApi} />}
      {importModal && <ImportFieldsModal onCancel={() => setImportModal(false)} />}

      {/*{mapMode ?*/}
      {/*  <Map*/}
      {/*    mode={editFieldRow.poligon.length ? 'edit' : 'create'}*/}
      {/*    strPolygons={editFieldRow.poligon}*/}
      {/*    onCreateLayer={setLatLng}*/}
      {/*  /> :*/}
      {/*  <>*/}
      {/*    <FieldsTable />*/}
      {/*    {editFieldRow && <ChangeFieldModal />}*/}
      {/*    {importModal && <ImportFieldsModal onCancel={() => setImportModal(false)} />}*/}
      {/*  </>*/}
      {/*}*/}
      {contextHolder}
    </div>
  )
}

export default Fields;