import Input from './input';
import Select from './select';
import TextArea from './textarea';

export const Form = (props) => (
  <div {...props} className={'ibox app-page'} />
);

Form.Input = Input;
Form.Select = Select;
Form.TextArea = TextArea;