import { useStore } from "effector-react";
import { $userStore, $viewAsAdminUser } from "store/user";

const roleMatcher = (pattern, target) => {
    return pattern.url.endsWith("*")
        ? target.startsWith(pattern.url.replace("/*", ""))
        : pattern.url.replace('/*', '/:id').localeCompare(target) === 0;
}

const compareRoles = (a, b) => {
    if (a.weight === b.weight) {
        const notTemplate = a.url.includes("*") ? b : a;
        notTemplate.weight *= 20;
    }
    return a.weight > b.weight ? a : b;
}


export const usePermission = (endpoint, modifier = "view") => {
    const {user: adminUser} = useStore($viewAsAdminUser);
    const {user, viewAsUserMode} = useStore($userStore);
    const {permissions, group} = viewAsUserMode ? adminUser : user;
    if (group === 1)
	    return true;

    if (!permissions)
        return true;
    
    if (!permissions)
        return false;

    const matchedRoles = Object.values(permissions).filter(role => roleMatcher(role, endpoint));

    if (matchedRoles.length === 0)
        return false;

    if (matchedRoles.length === 2)
        return Boolean(compareRoles(...matchedRoles)[modifier]);

    return Boolean(matchedRoles[0][modifier]);
}
