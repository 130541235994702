import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";
import { Button } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { Badges } from "../components/Badges";
import { PopoverMenu } from "../components/Popover";

import {
  $techniqueMonitoringStore,
  clearTechMonitoring,
} from "store/techniqueMonitoring";
import { updateWialonSession } from "api/wialonApi";
import { Table } from "../components/Table";
import { Leftbar } from "../components/Leftbar";
import getNearestItem from "../helpers/getNearestItem";
import { TrackLines } from "./TrackLines";
import PolylineDecorator from "../components/ArrowDecorator";

import styles from "../index.module.scss";
import "leaflet/dist/leaflet.css";
import { AuthModal } from "../components/AuthModal";
import { FieldsGeoJson } from "../components/FieldsGeoJson";

const trackColors = [
  "#FF5733", "#33FF57", "#3357FF", "#FF33A6", "#A633FF", "#FFBD33", "#33FFBD", "#FF3333", "#33FF33", "#3333FF",
  "#FF33FF", "#33FFFF", "#FFFF33", "#A6FF33", "#33FF99", "#9933FF", "#FF9933", "#3399FF", "#FF3399", "#99FF33",
  "#FF6633", "#6633FF", "#33FF66", "#66FF33", "#FF3366", "#3366FF", "#FF66FF", "#66FFFF", "#FFFF66", "#A6FF66",
  "#66FF99", "#9966FF", "#FF9966", "#6699FF", "#FF6699", "#99FF66", "#FF9966", "#6666FF", "#66FF66", "#6666FF",
  "#FF6666", "#66FF66", "#6666FF", "#FF66A6", "#A666FF", "#66FFA6", "#A6FF66", "#FF33CC", "#33FFCC", "#CC33FF",
  "#33FFCC", "#FFFF99", "#A6FF99", "#99FF66", "#6699FF", "#FF6699", "#99FF33", "#CC33FF", "#33FF66", "#FFCC33",
  "#33CCFF", "#FF33FF", "#33FF99", "#FF99CC", "#99FF99", "#33FFFF", "#FF99FF", "#99FFFF", "#FFFF99", "#FF33B2",
  "#B233FF", "#33FFB2", "#B2FF33", "#FFB233", "#33B2FF", "#FF33D9", "#33FFD9", "#D933FF", "#33D9FF", "#FFD933",
  "#B2FF99", "#99FFB2", "#FF99B2", "#99B2FF", "#FFB299", "#B299FF", "#FF99D9", "#D999FF", "#FFD999", "#FF6633",
  "#3366FF", "#33FF66", "#66FF33", "#FF3366", "#3366FF", "#FF66FF", "#66FFFF", "#FFFF66", "#A6FF66", "#66FF99"
];

const marker = new L.Icon({
  iconUrl: "https://local.tn-group.net/static/images/marker.svg",
  iconSize: [20, 20],
  iconAnchor: [10, 20],
});

const TechniqueMonitoring = () => {
  const techMonitoring = useStore($techniqueMonitoringStore);
  const [map, setMap] = useState(null);
  const [mousePosition, setMousePosition] = useState(null);
  const [popoverItem, setPopoverItem] = useState({});
  const [isShowLeftbar, setIsShowLeftbar] = useState(true);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [authModalOpen, setAuthModalOpen] = useState(true);

  const handleTrackClick = useCallback((itemPos, list, clickPos, technique) => {
    setMousePosition(clickPos);
    const nearestItem = getNearestItem(itemPos, list);
    setPopoverItem({
      technique,
      nearestItem,
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateWialonSession();
    }, 5000);
    return () => {
      clearInterval(intervalId);
      clearTechMonitoring();
    };
  }, []);


  useEffect(() => {
    if (!map) return;

    const isHaveTrack = techMonitoring.report.length > 0 && techMonitoring.report[0].track.length > 0;
    if (isHaveTrack) {
      const pos = techMonitoring.report[0]?.track[0]?.[0]?.pos;
      if (pos) {
        map.setView([pos.y, pos.x], 12);
      }
    }
  }, [techMonitoring.report, map]);

  useEffect(() => {
    let timerId;
    if (map && markerPosition) {
      map.setView(markerPosition, 16);
      timerId = setTimeout(() => setMarkerPosition(null), 3000);
    }
    return () => {
      clearTimeout(timerId);
    }
  }, [markerPosition, map]);

  return (
    <div className={styles.monitoring}>
      <MapContainer
        style={{ width: "100%", height: "100%", zIndex: 10, left: 0, right: 0, top: 0, bottom: 0 }}
        zoomControl={true}
        center={[50.82567414095759, 43.98719787597657]}
        zoom={13}
        ref={setMap}
        scrollWheelZoom={true}
      >
        <TileLayer url="http://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" />
        <FieldsGeoJson />

        {techMonitoring.report.map(({ badges, technique, track }, index) => {
          return <Fragment key={index}>
            <TrackLines 
              color={trackColors[index]} 
              track={track} 
              technique={technique} 
              handleTrackClick={handleTrackClick} 
            />
            <Badges badges={badges} />
            <PolylineDecorator polyline={track} />
          </Fragment>
        })}
        {markerPosition && <Marker zIndexOffset={50} icon={marker} position={markerPosition} />}
      </MapContainer>

      <div className={styles.mapWrapper}>
        <Leftbar 
          showLeftbar={isShowLeftbar} 
          setShowLeftbar={setIsShowLeftbar} 
          isMultiply={true} 
          trackColors={trackColors}
        />
        <Button
          style={{ zIndex: 101, left: 50, top: 25 }}
          onClick={() => setIsShowLeftbar(true)}
        >
          Показать
        </Button>
        <Table isShowLeftbar={isShowLeftbar} setMarkerPosition={setMarkerPosition} />
      </div>
      
      <PopoverMenu
        mousePosition={mousePosition}
        clickedItem={popoverItem}
        closePopover={() => {
          setMousePosition(null);
          setPopoverItem({});
        }}
      />
      <AuthModal isOpen={authModalOpen} close={() => setAuthModalOpen(false)} />
    </div>
  );
};

export default TechniqueMonitoring;