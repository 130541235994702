import { Table  } from "antd";
import createTableData from "./helpers";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";

const Statistics = ({
    data,
    groupBy,
    sumBy,
    divisionBy,
    calcType,
    columnNames,
    type,
    showPagination = true
}) => {
    const [stats, setStats] = useState([]);

    useEffect(() => {
        const newStats = createTableData(data, groupBy, sumBy, divisionBy, calcType, type);
        setStats(newStats);
    }, [data, groupBy, sumBy, divisionBy, calcType, columnNames])

    if (type === 'table') {
        return <Table
            dataSource={stats}
            columns={[
                {
                    title: columnNames[0],
                    dataIndex: groupBy,
                },
                {
                    title: columnNames[1],
                    dataIndex: 'division',
                },
                {
                    title: columnNames[2],
                    dataIndex: 'sum',
                },
                {
                    title: columnNames[3],
                    dataIndex: "result",
                },
            ]}
            options={{
                width: '99%', 
            }}
            pagination={showPagination}
        />
    }

    if (type === 'column-chart') {
        return <Chart
            chartType="Bar"
            data={[columnNames, ...stats]}
            options={{
                bars: 'horizontal',
                height: Math.max(stats.length * 20, 300),
                width: '99%', 
                hAxis: {
                    title: 'hello my guest'
                },
            }}
        />
    }

    if (type === 'percent-chart') {
        return <Chart
            chartType="ComboChart"
            data={[[...columnNames, '', ''], ...stats]}
            options={{
                    seriesType: "line",
                    colors: ['green', '#fbff00', 'red'],
                    legend: 'none',
                    height: 500,
                    width: '99%',   
                    hAxis: {
                          viewWindow: {
                            min: 0.5,
                            max: stats.length - 0.5,
                          },
                          viewWindowMode: 'explicit'
                    
                    },
                    areaOpacity: 0.1,
                    enableInteractivity: false,
                    lineDashStyle: [10],
                    lineWidth: 4,
                    series: {
                        0: {
                            type: 'bars',
                            pointsVisible: true,
                            backgroundColor: 'green',
                            enableInteractivity: true,
                            focusTarget: 'datum',
                        },
                    },
            }}

        />
    }
}



export default Statistics;