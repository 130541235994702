import React from 'react';
import { Button, Dropdown } from "antd";
import st from "../../pages/Users/index.module.scss";
import { IoEllipsisHorizontal } from "react-icons/io5";

const createDropdownItems = (items, row) =>
  items.map((item, ix) => {

    if(item.permission === false || (row.date_close && item.label == 'Закрыть')){
      return null;
    }

    return {
      key: ix,
      label: (
        <div onClick={() => item.onClick(row)}>
          {item.label}
        </div>
      ),
    }
  }
)

const TableDrodown = ({
  items,
  row,
}) => (
  <Dropdown
    menu={{ items: createDropdownItems(items, row) }}
    trigger="click"
  >
    <Button>
      <div className={st.profileAvatar}>
        <IoEllipsisHorizontal height={30} width={30} />
      </div>
    </Button>
  </Dropdown>
);

export default TableDrodown;