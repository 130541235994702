import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "effector-react";
import { Divider, notification } from "antd";
import { PageHeader } from "./PageHeader";
import StaffTable from "./StaffTable"
import CreateEmployeeModal from "./CreateEmploeeModal";
import EditEmploeeModal from "./EditEmploeeModal";
import QuitEmployeeModal from "./QuitEmployeeModal";

import { $staffStore, getStaffFx, editStaffFx, deleteStaffFx, quitStaffFx } from "store/staff";
import { $sectionsStore, getSectionsFx } from "store/sections";
import { $userStore } from "store/user";

import dayjs from "dayjs";


const Staff = () => {
    const {id} = useParams();
    const section = useStore($sectionsStore).list.find(item => item.id === parseInt(id));
    const {list: staffList} = useStore($staffStore);
    const {viewAsUserMode} = useStore($userStore)

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showQuitModal, setShowQuitModal] = useState(false);
    const [editableItem, setEditableItem] = useState({});

    useEffect(() => {
        if (!section)
            getSectionsFx()
        else
            getStaffFx({section: section.id})
    }, [section, viewAsUserMode])

    const createEmployee = useCallback((args) => {
        editStaffFx({
            ...args, 
            section: String(section.id), 
            company: String(section.company.id), 
            birthday: args.birthday ? dayjs(args.birthday).format("YYYY-MM-DD"): null,
            date_entry: args.date_entry ? dayjs(args.date_entry).format("YYYY-MM-DD"): null,
        })
            .then(res => {
                if (res.data?.status === "ok") {
                    notification.success({message: "Сотрудник добавлен"})
                    setShowCreateModal(false);
                } else {
                    notification.error({message: res.data?.error_message});
                }
            })
            .finally(() => getStaffFx({company: section?.company.id, section: section?.id}));
    }, [section]);

    const editEmployee = useCallback((args) => {
        editStaffFx({
            ...args,
            id: editableItem.workerId,
            section: section.id,
            company: section.company.id,
            birthday: args.birthday ? dayjs(args.birthday).format("YYYY-MM-DD"): null,
            date_entry: args.date_entry ? dayjs(args.date_entry).format("YYYY-MM-DD"): null,
        }).then(res => {
                res.data?.status === "ok"
                    ? notification.success({message: "Сотрудник изменен"})
                    : notification.error({message: res.data?.error_message});
                setShowEditModal(false);
            })
            .finally(() => getStaffFx({company: section?.company.id, section: section?.id}));
    }, [section, editableItem]);

    const deleteEmployee = useCallback((user) => {
        deleteStaffFx({section, user})
            .then(res => {
                res.data?.status === "ok"
                    ? notification.success({message: "Сотрудник удален"})
                    : notification.error({message: "Не удалось удалить сотрудника"});
            })
            .finally(() => getStaffFx({company: section?.company.id, section: section?.id}));
    }, [section]);

    const quitEmployee = useCallback((user) => {
        quitStaffFx(user).then(res => {
            res.data?.status === "ok"
                    ? notification.success({message: "Сотрудник уволен"})
                    : notification.error({message: res.data?.error_message});
        })
        .finally(() => getStaffFx({company: section?.company.id, section: section?.id}))
    }, [section]);

    return (
        <>
            <PageHeader sectionName={section?.title || ""} onClick={() => setShowCreateModal(true)}/>
            <Divider />
            <StaffTable
                items={staffList.map(item => 
                    ({
                        ...item.user,
                        is_quit: item.is_quit, 
                        salary: item.salary, 
                        percentage: item.percentage, 
                        workerId: item.id, 
                        date_entry: item.date_entry,
                        date_quit: item.date_quit,
                        salary_premium: item.salary_premium,
                        salary_additional: item.salary_additional,
                    }))}
                onEdit={(item) => {
                    setShowEditModal(true);
                    setEditableItem(item);
                }}
                onDelete={deleteEmployee}
                onQuit={(item) => {
                    setShowQuitModal(true);
                    setEditableItem(item);
                }}
            />
            <CreateEmployeeModal
                onSubmit={createEmployee}
                open={showCreateModal}
                section={section?.id}
                company={section?.company.id}
                onCancel={() => setShowCreateModal(false)}
            />
            <EditEmploeeModal
                open={showEditModal}
                onSubmit={editEmployee}
                onCancel={() => setShowEditModal(false)}
                activeItem={editableItem}
                section={section?.id}
                company={section?.company.id}
            />
            <QuitEmployeeModal
                open={showQuitModal}
                onSubmit={quitEmployee}
                onCancel={() => setShowQuitModal(false)}
                activeItem={editableItem}
            />
        </>
    );
};

export default Staff;