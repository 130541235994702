import React, { useCallback } from 'react';
import { Modal } from "antd";
import CreateUserForm from './CreateUserForm';

import helpMessageRoutes from 'helpMessageRoutes';


import { createNewUserFx, changeUserFx } from "../../store/adminUsers";
import { ModalTitleForHelp } from 'components/ModalTitleForHelp';


const NewUserModal = ({
  newUserIsOpen,
  setNewUserIsOpen,
  notificationApi
}) => {
  const onSubmit = async (values) => {
    try {
      const { data } = await createNewUserFx(values);

      if(data.error_msg) {
        notificationApi.error({ message: data.error_message, duration: 5 });
      } else {
        const { data: res } = await changeUserFx({
          props: {
            phone: "",
            name: "",
            surname: "",
            patronymic: "",
            company: values.company,
            section: values.section
          },
          id: data.id
        });

        if (res.error_msg) {
          notificationApi.error({ message: data.error_message, duration: 5 });
        } else {
          notificationApi.success({ message: 'Пользователь успешно создан', duration: 5 });
          setNewUserIsOpen(false)
        }
      }
    } catch(e) {
      console.log(e);
      notificationApi.error({ message: "Не удалось зарегистрировать пользователя", duration: 5 });
    }
  }

  const onCancel = useCallback(() => {
    setNewUserIsOpen(false);
  }, [setNewUserIsOpen]);

  return (
    <Modal
      title={
        <ModalTitleForHelp
          title="Регистрация нового пользователя"
          helpFile={helpMessageRoutes.user.create}
        />
      }
      open={newUserIsOpen}
      onCancel={onCancel}
      destroyOnClose
      footer={null}
    >
      <CreateUserForm
        onSubmit={onSubmit}
        submitTitle={"Зарегистрировать"}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default NewUserModal;