import { Select } from 'antd';
import styles from './index.module.scss';
import { useStore } from 'effector-react';
import { $weighingStore, $weighingYearsStore, getWeighingListFx, getWeighingYearsFx } from 'store/weighing';
import { useEffect } from 'react';

const PageHeader = () => {
    const {year} = useStore($weighingStore);
    const {years} = useStore($weighingYearsStore); 

    useEffect(() => {
        getWeighingYearsFx();
    }, []); 

    function changeYear(newYear) {
        getWeighingListFx(newYear);
    }
    return <div className={styles.pageHeader}>
        <h2 className={styles.pageTitle}>
            Урожайность полей
        </h2>
        <Select 
        defaultValue={year} 
        options={years.map(year => ({value: year, label: year}))} 
        onChange={changeYear}
      />
    </div>
}

export default PageHeader;