import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, forward } from "effector";

// Store
export const $weighingStore = createStore({
    list: [],
    year: new Date().getFullYear(),
});

export const $weighingYearsStore = createStore({
    years: [],
});

// Events
const setWeighingList = createEvent();
const setWeighingYears = createEvent();

// Effects
export const getWeighingListFx = createEffect(async (year) => {
    const res = await post({
        module: 'weighing',
        action: 'list',
        year: year ?? new Date().getFullYear(),
    })
    return res;
});

export const getWeighingFx = createEffect(async (params) => {
    const res = await get(urls.weighingGet, params);
    return res;
});

export const getWeighingYearsFx = createEffect(async (params) => {
    const res = await get(urls.weighingYears, params);
    return res;
});

export const editWeighingFx = createEffect(async (data) => {
    const res = await post({
        module: "weighing",
        action: "edit",
        data: JSON.stringify(data)
    });
    return res;
});

export const deleteWeighingFx = createEffect(async (data) => {
    const res = await get(urls.weighingDelete, {id: data.id});
    return res;
});

// Forwards
forward({
    from: getWeighingListFx.done,
    to: setWeighingList
});

forward({
    from: getWeighingYearsFx.done,
    to: setWeighingYears
});

// Event handlers
$weighingStore
    .on(setWeighingList, (_, {result}) => {
        return {list: result.data.list || [], year: _.year}
    });
$weighingYearsStore
    .on(setWeighingYears, (_, {result}) => {
        return {
            years: result.data.list || []
        }
    })