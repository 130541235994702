import { Modal, Button, Typography, Space } from "antd";
import { useStore } from "effector-react";
import { $modalStore, closeModal } from "store/helpModal";
import { LoadingOutlined } from "@ant-design/icons";

export default function HelpModal() {
  const {modalContent, isLoading, isOpen} = useStore($modalStore);
  return (
    <Modal
      zIndex={1000}
      title="Помощь"
      open={isOpen}
      onOk={closeModal}
      onCancel={closeModal}
      destroyOnClose
      footer={[
        <Button
          key="back"
          onClick={closeModal}
        >
          Закрыть
        </Button>
      ]}
    >
      { isLoading
          ?
          <LoadingOutlined style={{fontSize: "1.5rem", textAlign: "center"}} spin/>
          :
          <Space direction="vertical">
            {modalContent.split("\r\n")
              .map((line, idx) => <Typography.Text strong={idx === 0} key={idx}>{line}</Typography.Text>)}
          </Space>
      }
    </Modal>
  )
}
