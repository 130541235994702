import { useStore } from "effector-react";
import { Table, Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";
import TableDropdown from "../../components/TableDropdown";
import { setEditField, $fieldsList } from 'store/fields';
import { usePermission } from "../../hooks/usePermission";

const FieldsTable = () => {
  const navigate = useNavigate();
  const fieldsList = useStore($fieldsList);
  const canView = usePermission("culture-rotation", "view");

  const showMap = (field) => {
    if(field.poligon[0] !== 'undefined'){
      navigate(routes.fieldMap.replace(":id", field.id), { state: field })
    }
  }

  return (
    <Table
      columns={[
        {
          title: "Название поля",
          dataIndex: "name",
          key: "fieldName",
        },
        {
          title: "Описание поля",
          dataIndex: "description",
          key: "fieldDescription",
        },
        {
          title: "",
          dataIndex: "fieldShow",
          key: "fieldShow",
          width: "10%",
          render: (_, field) => (
            <Button
              onClick={field.showOnMap}
            >
              Карта
            </Button>
          )
        },
        {
          title: '',
          render: (row, field) => (
            <TableDropdown
              items={[
                {
                  label: "Редактировать",
                  onClick: field.showChangeModal
                },
                {
                  label: "Севооборот",
                  onClick: () => navigate(`/fields/${field.id}/culture-rotation`),
                  permission: canView
                },
              ]}
              row={row}
            />
          )
        }
      ]}
      dataSource={fieldsList.map(field =>
        ({
          ...field,
          showOnMap: () => showMap(field),
          showChangeModal: () => setEditField(field.id)
        }),
      )}
    />
  );
}

export default FieldsTable;