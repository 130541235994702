import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, sample } from "effector";

export const $cultureRotationStore = createStore([]);
export const $fieldId = createStore(0);
export const $modals = createStore({})

export const getCultureRotationFx = createEffect();
export const editOrCreateCultureRotationFx = createEffect();
export const closeCultureRotationFx = createEffect();
export const deleteCultureRotationFx = createEffect();

export const getCultureRotationList = createEvent();
export const deleteCultureRotation = createEvent();
export const showModal = createEvent();
export const closeModal = createEvent()

getCultureRotationFx.use(async (plantation) => {
  const res = await get(urls.cultureRotationList, { plantation });
  return res;
});

editOrCreateCultureRotationFx.use(async (props) => {
  const {
    plantation,
    culture,
    date_start,
    date_plan,
    date_close,
    cultureRotation
  } = props;

  const postData = {
    module: 'culture_rotation',
    action: 'edit',
    data: JSON.stringify({ plantation, culture, date_start, date_plan }),
  }

  if(cultureRotation){
    postData['id'] = cultureRotation?.id;
  }

  const res = await post(postData);

  return res;
})

closeCultureRotationFx.use(async (props) => {
  const {
    plantation,
    culture,
    date_close,
    cultureRotation
  } = props;

  const postData = {
    module: 'culture_rotation',
    action: 'edit',
    data: JSON.stringify({ plantation, culture, date_close }),
  }

  if(cultureRotation){
    postData['id'] = cultureRotation?.id;
  }

  console.log(postData)

  const res = await post(postData);

  return res;
})



deleteCultureRotationFx.use(async (cultureRotationId) => {
  const res = await get(urls.cultureRotationDelete, { id: cultureRotationId });
  return res;
})

sample({
  source: sample({
    clock: getCultureRotationList,
    target: getCultureRotationFx
  }),
  clock: getCultureRotationFx.done,
  fn: (_, { result }) => result.data?.list || [],
  target: $cultureRotationStore
})

sample({
  clock: getCultureRotationList,
  target: $fieldId
})

sample({
  source: $fieldId,
  clock: [
    deleteCultureRotationFx.done,
    editOrCreateCultureRotationFx.done,
    closeCultureRotationFx.done
  ],
  target: getCultureRotationList
})