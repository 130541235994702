import { get, post, urls } from "api";
import { createStore, createEffect, createEvent, forward } from "effector";

// Store
export const $staffStore = createStore({
    list: []
});

// Events
const setStaffList = createEvent();

// Effects
export const getStaffFx = createEffect(async (params) => {
    const res = await get(urls.sectionListStaff, params);
    return res;
});

export const editStaffFx = createEffect(async (data) => {
    const res = await post({
        module: "worker",
        action: "edit_as_user",
        data: JSON.stringify(data)
    });
    return res;
});

export const validateStaffDataFx = createEffect(async (data) => {
    const res = await post({
        module: "worker",
        action: "edit_as_user",
        data_check: 1,
        data: JSON.stringify(data)
    });
    return res;
})

export const searchStaffMemberFx = createEffect(async (data) => {
    const res = await post({
        module: "worker",
        action: "edit_as_user",
        user_search: 1,
        data: JSON.stringify(data)
    });
    return res;
});

export const deleteStaffFx = createEffect(async (data) => {
    const res = await get(urls.sectionDeleteEmployee, {section: data.section.id, company: data.section.company.id, user: data.user.id});
    return res;
});

export const quitStaffFx = createEffect(async (user) => {
    const res = await get(urls.sectionQuitEmployee, user);
    return res;
});

// Forwards
forward({
    from: getStaffFx.done,
    to: setStaffList
});

// Event handlers
$staffStore
    .on(setStaffList, (_, {result}) => ({list: result.data.list || []}));
