import Login from './pages/Auth/Login';
import Forgot from './pages/Auth/Forgot';
import Activation from './pages/Auth/Activation'
import Registration from './pages/Auth/Registration';
import Profile from './pages/Profile';
import Companies from './pages/Companies';
import Users from './pages/Users';
import Roles from './pages/Roles';
import Fields from './pages/Fields';
import Sections from 'pages/Sections';
import Staff from 'pages/Staff';
import UserRoles from 'pages/UserRoles';
import Cultures from 'pages/Cultures';
import UsersGroups from 'pages/UsersGroups'
import GroupRoles from 'pages/GroupRoles';
import Technique from 'pages/Technique';
import UserWorkers from 'pages/UserWorkers';
import CultureRotation from 'pages/CultureRotation';
import CultureRotationStats from 'pages/CultureRotationStats';
import TechOperations from 'pages/Operations';
import TechOperationsGroups from 'pages/OperationsGroup';
import Storage from 'pages/Storage';
import Weighing from 'pages/Weighing';
import FieldMap from 'features/FieldMap';
import Material from 'pages/Material';
import MaterialGroup from 'pages/MaterialGroup';
import StorageCopacity from 'pages/StorageCopacity';
import FieldProductivity from 'pages/FieldProductivity';
import CompaniesStats from 'pages/CompaniesStats';
import TechniqueMonitoring from 'pages/MonitroingPages/TechMonitoring';
import TechniqueMonitoringGroup from 'pages/MonitroingPages/TechMonitoringGroup';

import { Navigate } from 'react-router-dom';

export const routes = {
  // Public routes
  registration: '/registration',
  login: '/login',
  forgot: '/forgot',
  activation: '/activation',

  // Private routes
  profile: '/profile',
  users: '/users',
  companies: '/companies',
  companiesStats: '/companies-stats',
  roles: '/roles',
  userRoles: '/users/:id/roles',
  fields: '/fields',
  fieldMap: '/fields/:id/map',
  cultureRotation: '/fields/:id/culture-rotation',
  cultureRotationStats: '/culture-rotation-stats',
  cultures: '/cultures',
  weighing: '/weighing',
  usersGroups: '/usersGroups',
  groupRoles: '/usersGroups/:id/roles',
  section: '/sections',
  staff: '/sections/:id/staff',
  storage: '/storage',
  storageCopacity: '/storage-copacity',
  fieldProductivity: '/field-productivity',
  technique: '/technique',
  techniqueMonitoring: '/technique-monitoring',
  techniqueMonitoringGroup: '/technique-monitoring-group',
  techniqueMap: '/technique/:id/map',
  workPlace: '/user/:id/worker',
  techOperations: '/tech-operations',
  techOperationsGroups: '/tech-operations-groups',
  material: '/material',
  materialGroups: '/material-groups',
}

export const routesPerm = {
  [routes.users]: 'user',
  [routes.companies]: 'company',
  [routes.companiesStats]: 'companies-stats',
  [routes.roles]: 'role',
  [routes.fields]: 'plantation',
  [routes.cultures]: 'culture',
  [routes.weighing]: 'weighing',
  [routes.section]: 'section',
  [routes.storage]: 'storage',
  [routes.technique]: 'technique',
  [routes.techniqueMonitoring]: 'technique-monitoring',
  [routes.techniqueMonitoringGroup]: 'technique-monitoring-group',
  [routes.workPlace]: 'worker',
  [routes.usersGroups]: 'usersGroups',
  [routes.groupRoles]: 'usersGroups/:id/roles',
  [routes.staff]: 'section/:id/staff',
  [routes.techOperations]: 'tech-operation',
  [routes.techOperationsGroups]: 'tech-operations-groups',
  [routes.storageCopacity]: 'storage-copacity',
  [routes.fieldProductivity]: 'field-productivity',
  [routes.material]: 'material',
  [routes.materialGroups]: 'material-groups',
}

export const getArrayOfRoutes = () => Object.values(routes);

export const publicRoutes = [
  {
    path: routes.login,
    element: <Login />,
  },
  {
    path: routes.registration,
    element: <Registration />,
  },
  {
    path: routes.forgot,
    element: <Forgot />,
  },
  {
    path: routes.activation,
    element: <Activation />,
  },
  // Deafult route
  {
    path: "*",
    element: <Navigate to={routes.login} replace />
  }
]

export const privateRoutes = [
  {
    index: true, // This is the default route
    path: routes.profile,
    element: <Profile />
  },
  {
    path: routes.users,
    element: <Users />,
  },
  {
    path: routes.companies,
    element: <Companies />
  },
  {
    path: routes.companiesStats,
    element: <CompaniesStats />
  },
  {
    path: routes.userRoles,
    element: <UserRoles />
  },
  {
    path: routes.roles,
    element: <Roles />
  },
  {
    path: routes.fields,
    element: <Fields />
  },
  {
    path: routes.fieldMap,
    element: <FieldMap />
  },
  {
    path: routes.usersGroups,
    element: <UsersGroups />
  },
  {
    path: routes.groupRoles,
    element: <GroupRoles />
  },
  {
    path: routes.cultures,
    element: <Cultures />
  },
  {
    path: routes.section,
    element: <Sections />
  },
  {
    path: routes.staff,
    element: <Staff />
  },
  {
    path: routes.storage,
    element: <Storage />
  },
  {
    path: routes.technique,
    element: <Technique />
  },
  {
    path: routes.techniqueMonitoring,
    element: <TechniqueMonitoring />
  },
  {
    path: routes.techniqueMonitoringGroup,
    element: <TechniqueMonitoringGroup />
  },
  {
    path: routes.techniqueMap,
    element: <FieldMap />
  },
  {
    path: routes.workPlace,
    element: <UserWorkers />
  },
  {
    path: routes.cultureRotation,
    element: <CultureRotation />
  },
  {
    path: routes.techOperations,
    element: <TechOperations />
  },
  {
    path: routes.techOperationsGroups,
    element: <TechOperationsGroups />
  },
  {
    path: routes.cultureRotationStats,
    element: <CultureRotationStats />
  },
  {
    path: routes.weighing,
    element: <Weighing />
  },
  {
    path: routes.storageCopacity,
    element: <StorageCopacity />
  },
  {
    path: routes.fieldProductivity,
    element: <FieldProductivity />
  },
  {
    path: routes.material,
    element: <Material />
  },
  {
    path: routes.materialGroups,
    element: <MaterialGroup />
  },
  {
    path: "*",
    element: <Navigate to={routes.profile} replace />
  }
]
